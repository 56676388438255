import React from 'react';
import HitIcon from '../../icons/hit';
import './hit.scss';
import { useTranslation } from 'react-i18next';

type Props = {
  className?: string;
};

const Hit = ({ className = '' }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <span className={`hit-badge ${className}`}>
        <HitIcon className="hit-badge__icon" />
        {t('hit')}
      </span>
    </>
  );
};

export default Hit;
