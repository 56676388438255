import { useAppSelector } from './redux';
import { useParams } from 'react-router-dom';

export const useFindProject = () => {
  const { id } = useParams<string>();
  const projects = useAppSelector((state) => state.projects.projects.items);
  const project = projects.find((project) => String(project.id) === id);

  return { project, id };
};
