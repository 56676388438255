import { api } from './api';
import { Data, dataSchema } from './models/data';

const BASE_URL = '/blogger/promocode-api';

export async function getProjects(user_id?: number, isPremium?: boolean) {
  const response = await api.get<Data>(`${BASE_URL}/bot-json`, {
    params: {
      chat_id: user_id,
      is_premium: isPremium
    },
    retry: 2,
    retryDelay: 1500,
    isRetry: false
  });
  return dataSchema.parse(response.data);
}
