import { api } from './api';
import {
  EventTypes,
  SendBotLogsData,
  sendBotLogsDataSchema,
  sendBotLogsParamsSchema
} from './models/log';
import * as Sentry from '@sentry/react';

const BASE_URL = '/blogger/promocode-api';

export const sendBotLogs = async (data: {
  chat_id?: number;
  project_id?: number | string | null;
  landing_id?: number | null;
  contest_id?: number | null;
  category_id?: number | null;
  promo_group_id?: string | null;
  event_type: EventTypes;
}) => {
  try {
    const { chat_id, ...dataPostParams } = sendBotLogsParamsSchema.parse(data);

    const response = await api.post<SendBotLogsData>(
      `${BASE_URL}/bot-log`,
      {
        ...dataPostParams
      },
      {
        params: {
          chat_id
        }
      }
    );

    return sendBotLogsDataSchema.parse(response.data);
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};
