import { useParams } from 'react-router';
import { useEffect } from 'react';
import { fetchContestParticipantInfo } from '../store/thunk/ContestThunk';
import { TG } from '../helpers/telegram';
import { apiKey, userId } from '../helpers/api';
import * as Sentry from '@sentry/react';
import { useAppDispatch, useAppSelector } from './redux';
import { useNavigate } from 'react-router-dom';
import { resolveLoggerInstance } from '../helpers/logger';
import { useYM } from './useYM';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { ErrorDataResponse } from '../api/models/data';

export const useLoadContestParticipantInfo = () => {
  const { chat_id, id } = useParams();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((s) => s.contests.loadingContests);
  const navigate = useNavigate();

  const logger = resolveLoggerInstance('useLoadContestParticipantInfo');
  const YM = useYM();
  const { t } = useTranslation();

  const referral = useAppSelector((s) =>
    s.contests.contestParticipants.find(
      (participant) =>
        participant.chat_id === Number(chat_id) &&
        participant.contest_id === Number(id)
    )
  );

  useEffect(() => {
    if (referral) return;
    dispatch(
      fetchContestParticipantInfo({
        contest_id: Number(id),
        participant_chat_id: Number(chat_id)
      })
    )
      .unwrap()
      .catch((e: AxiosError) => {
        logger.error(e);
        const xRequestId = e.response?.headers['x-request-id'];
        const responseDataError: ErrorDataResponse = e.response
          ?.data as ErrorDataResponse;
        if (e.response?.status === 500) {
          TG.WebApp?.showAlert(
            'Ой, что-то пошло не так\n\n' + t('error_id') + ': ' + xRequestId,
            () => {
              navigate(`/contest/${id}?key=${apiKey}`);
            }
          );
        } else {
          TG.WebApp?.showAlert(responseDataError.message, () => {
            navigate(`/contest/${id}?key=${apiKey}`);
          });
        }
        YM.errorLog(e.config?.url, userId, e.message, xRequestId);
        Sentry.captureException(e);
      });
  }, []);

  return { referral, loading, navigate, chat_id };
};
