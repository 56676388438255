import { api } from './api';
import {
  contestList,
  ContestList,
  ContestInfo,
  contestInfo,
  ContestSendMessageDataSchema,
  contestSendMessageDataSchema,
  getContestListParamsSchema,
  sendContestMessageToUserParams,
  sendContestResultParams,
  createContestParams,
  createContestDataSchema,
  CreateContestDataSchema,
  updateContestParams,
  banUnbanContestUserParams,
  BanUnbanContestUserDataSchema,
  banUnbanContestUserDataSchema,
  finishContestParams,
  FinishContestDataSchema,
  finishContestDataSchema,
  ContestParticipantInfo,
  getContestParticipantParamsSchema,
  contestParticipantInfo,
  UpdateContestDataSchema,
  updateContestDataSchema
} from './models/contests';

const BASE_URL = '/blogger/promocode-api';

export const getContestList = async (data: { chat_id?: number }) => {
  const { chat_id } = getContestListParamsSchema.parse(data);

  const response = await api.get<ContestList>(`${BASE_URL}/bot-contest-list`, {
    retry: 2,
    retryDelay: 1500,
    isRetry: false,
    params: {
      chat_id
    }
  });

  return contestList.parse(response.data);
};

export const getContestInfo = async (contest_id?: string, chat_id?: number) => {
  const response = await api.get<ContestInfo>(`${BASE_URL}/bot-contest-info`, {
    retry: 2,
    retryDelay: 1500,
    isRetry: false,
    params: {
      contest_id,
      chat_id
    }
  });

  return contestInfo.parse(response.data);
};

export const getContestParticipantInfo = async (data: {
  contest_id: number;
  participant_chat_id?: number;
}) => {
  const { contest_id, participant_chat_id } =
    getContestParticipantParamsSchema.parse(data);

  const response = await api.get<ContestParticipantInfo>(
    `${BASE_URL}/chat-contest-info`,
    {
      params: {
        contest_id,
        chat_id: participant_chat_id
      }
    }
  );

  return contestParticipantInfo.parse(response.data);
};

export const sendContestMessageToUser = async (data: {
  text: string;
  chat_id?: number;
  to_chat_id?: number;
}) => {
  const { text, chat_id, to_chat_id } =
    sendContestMessageToUserParams.parse(data);

  const response = await api.post<ContestSendMessageDataSchema>(
    `${BASE_URL}/bot-send-message`,
    {
      text
    },
    {
      params: { chat_id, to_chat_id }
    }
  );

  return contestSendMessageDataSchema.parse(response.data);
};

export const sendContestResults = async (data: {
  chat_id?: number;
  contest_id?: number;
  subscriber_chat_id?: number;
}) => {
  const { chat_id, subscriber_chat_id, contest_id } =
    sendContestResultParams.parse(data);

  const response = await api.post<ContestSendMessageDataSchema>(
    `${BASE_URL}/bot-send-results-to-admin`,
    {},
    {
      params: { chat_id, subscriber_chat_id, contest_id }
    }
  );

  return contestSendMessageDataSchema.parse(response.data);
};

export const createContest = async (data: {
  chat_id?: number;
  conditions: string;
  expiration_date: string;
}) => {
  const { chat_id, conditions, expiration_date } =
    createContestParams.parse(data);

  const response = await api.post<CreateContestDataSchema>(
    `${BASE_URL}/contest`,
    {
      conditions,
      expiration_date
    },
    {
      params: { chat_id }
    }
  );

  return createContestDataSchema.parse(response.data);
};

export const updateContest = async (data: {
  chat_id?: number;
  contest_id?: number;
  conditions?: string;
  expiration_date?: string;
}) => {
  const { chat_id, expiration_date, conditions, contest_id } =
    updateContestParams.parse(data);

  const response = await api.put<UpdateContestDataSchema>(
    `${BASE_URL}/contest/${contest_id}`,
    {
      conditions,
      expiration_date
    },
    {
      params: { chat_id }
    }
  );

  return updateContestDataSchema.parse(response.data);
};

export const banUnbanContestUser = async (data: {
  contest_id: number;
  user_chat_id?: number;
}) => {
  const { user_chat_id, contest_id } = banUnbanContestUserParams.parse(data);

  const response = await api.post<BanUnbanContestUserDataSchema>(
    `${BASE_URL}/bot-change-ban-on-contests`,
    {},
    {
      params: {
        chat_id: user_chat_id,
        contest_id
      }
    }
  );

  return banUnbanContestUserDataSchema.parse(response.data);
};

export const finishContest = async (data: {
  chat_id?: number;
  contest_id?: number;
}) => {
  const { chat_id, contest_id } = finishContestParams.parse(data);

  const response = await api.post<FinishContestDataSchema>(
    `${BASE_URL}/bot-finish-contest`,
    {},
    {
      params: {
        chat_id,
        contest_id
      }
    }
  );

  return finishContestDataSchema.parse(response.data);
};
