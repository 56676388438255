import { Container } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { TG } from '../../../helpers/telegram';
import { useTranslation } from 'react-i18next';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { sendMessage } from '../../../store/thunk/ContestThunk';
import { setIsContestSendMessagePage } from '../../../store/slices/appStateSlice';
import Loading from '../../loading/loading';
import { userId } from '../../../helpers/api';
import { useLoadContest } from '../../../hooks/useLoadContest';
import * as Sentry from '@sentry/react';

import './contest-send-message.scss';

const ContestSendMessage = () => {
  const { t } = useTranslation();
  const { chat_id } = useParams();
  const dispatch = useAppDispatch();
  const { contest, loading } = useLoadContest();
  const adminUserName = contest?.admin?.username;
  const [textValue, setTextValue] = useState<string>('');
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const bannedUserIdFromStore = useAppSelector((s) =>
    s.contests.bannedUsers.find((userId) => userId === chat_id)
  );

  const bannedUser =
    contest?.banned_users.find((user) => user.chat_id === Number(chat_id)) ||
    bannedUserIdFromStore;

  useEffect(() => {
    TG.BackButton?.show();
    TG.MainButton?.show();
    TG.MainButton?.setText(t('send'));
    dispatch(setIsContestSendMessagePage(true));
    return () => {
      dispatch(setIsContestSendMessagePage(false));
      TG.MainButton?.hide();
    };
  }, []);

  useEffect(() => {
    if (adminUserName) {
      if (bannedUser) {
        setTextValue(
          `Ваше участие в конкурсе приостановлено в связи с нарушением условий. Если у вас есть вопросы, пожалуйста, свяжитесь со мной @${adminUserName}`
        );
      } else {
        setTextValue(
          `Здравствуйте. Пожалуйста, напишите мне @${adminUserName} по поводу получения приза в конкурсе с telegram-ботом.`
        );
      }
    }
  }, [adminUserName, bannedUser]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length
      );
    }
  });

  useEffect(() => {
    TG.MainButton?.onClick(sendMessageHandler);
    return () => {
      TG.MainButton?.offClick(sendMessageHandler);
    };
  }, [textValue]);

  const sendMessageHandler = async () => {
    try {
      await dispatch(
        sendMessage({
          chat_id: userId,
          to_chat_id: Number(chat_id),
          text: textValue
        })
      ).unwrap();
      TG.WebApp?.showAlert('Сообщение успешно отправлено');
    } catch (e) {
      TG.WebApp?.showAlert('Произошла ошибка');
      Sentry.captureException(e);
    }
  };

  if (loading) return <Loading />;
  if (!contest) return <Container>Нет информации о данном конкурсе</Container>;

  return (
    <Container>
      <div className="send-message-warning">
        <FontAwesomeIcon
          className="send-message-warning__icon"
          icon={faExclamationCircle}
        />
        Сообщение будет отправлено через бот. Укажите свой ник в telegram, чтобы
        победитель мог вам ответить.
      </div>
      <div className="send-message-title">Сообщение</div>
      <textarea
        ref={textareaRef}
        value={textValue}
        onChange={(e) => setTextValue(e.target.value)}
        placeholder="Введите сообщение"
        className="send-message-field"></textarea>
    </Container>
  );
};

export default ContestSendMessage;
