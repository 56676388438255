import { useAppDispatch, useAppSelector } from './redux';
import { useEffect } from 'react';
import { fetchContestInfo } from '../store/thunk/ContestThunk';
import { apiKey, userId } from '../helpers/api';
import { useNavigate, useParams } from 'react-router-dom';
import { TG } from '../helpers/telegram';
import { PATHS } from '../const';
import * as Sentry from '@sentry/react';
import { ContestInfo } from '../api/models/contests';
import { AxiosError } from 'axios';
import { resolveLoggerInstance } from '../helpers/logger';
import { useYM } from './useYM';
import { useTranslation } from 'react-i18next';
import { ErrorDataResponse } from '../api/models/data';

export const useLoadContest = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let contest: ContestInfo | undefined;

  if (id) {
    contest = useAppSelector((s) =>
      s.contests.contestsInfo.find((c) => c.id === Number(id))
    );
  } else {
    contest = useAppSelector((c) => c.contests.contestsInfo[0]);
  }

  const loading = useAppSelector((s) => s.contests.loadingContests);

  const logger = resolveLoggerInstance('useLoadContest');
  const YM = useYM();
  const { t } = useTranslation();

  useEffect(() => {
    if (contest) return;
    dispatch(
      fetchContestInfo({
        contest_id: id,
        chat_id: userId
      })
    )
      .unwrap()
      .catch((e: AxiosError) => {
        logger.error(e);
        const xRequestId = e.response?.headers['x-request-id'];
        const responseDataError: ErrorDataResponse = e.response
          ?.data as ErrorDataResponse;
        if (e.response?.status === 500) {
          TG.WebApp?.showAlert(
            'Ой, что-то пошло не так\n\n' + t('error_id') + ': ' + xRequestId,
            () => {
              navigate(`${PATHS.projects}?key=${apiKey}`);
            }
          );
        } else {
          TG.WebApp?.showAlert(responseDataError.message, () => {
            navigate(`${PATHS.projects}?key=${apiKey}`);
          });
        }
        YM.errorLog(e.config?.url, userId, e.message, xRequestId);
        Sentry.captureException(e);
      });
  }, []);

  return { contest, loading, navigate };
};
