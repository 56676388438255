import React from 'react';
import './loading.scss';
import Loader from '../../components/loader/loader';

const Loading = (): JSX.Element => {
  return (
    <>
      <div className="loading">
        <Loader />
      </div>
    </>
  );
};

export default Loading;
