interface ContestReferralStatBlockInfoProps {
  infoTitle: string;
  infoTitleValue?: number;
  infoTitlePercentValue?: number;
  allUserReferrals?: number;
}

const ContestReferralStatBlockInfo = ({
  infoTitle,
  infoTitleValue,
  infoTitlePercentValue,
  allUserReferrals
}: ContestReferralStatBlockInfoProps) => {
  const scaleWidthIfPercent = infoTitlePercentValue;
  const scaleWidthIfNumber =
    infoTitleValue && (infoTitleValue / (allUserReferrals || 1)) * 100;
  const scaleWidth = scaleWidthIfPercent ?? scaleWidthIfNumber;

  return (
    <div className="stat-block-info-item">
      <div className="stat-block-info-item-title">
        <span>{infoTitleValue ?? `${infoTitlePercentValue}%`} </span>
        {infoTitle}
      </div>
      <div className="stat-block-info-item-scale">
        <div style={{ width: `${scaleWidth}%` }}></div>
      </div>
    </div>
  );
};

export default ContestReferralStatBlockInfo;
