import { createAsyncThunk } from '@reduxjs/toolkit';
import { Data } from '../../api/models/data';
import { getProjects } from '../../api/projects';
import { AxiosError } from 'axios';

export const fetchProjects = createAsyncThunk<
  Data,
  { user_id?: number; isPremium?: boolean },
  { rejectValue: AxiosError }
>(
  'projects/fetchProjects',
  async ({ user_id, isPremium }, { rejectWithValue }) => {
    try {
      return await getProjects(user_id, isPremium);
    } catch (e) {
      return rejectWithValue(e as AxiosError);
    }
  }
);
