import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import PageHeader from '../../../components/page-header/page-header';
import NotFound from '../../not-found/not-found';
import PageContent from '../../../components/page-content/page-content';
import { TG } from '../../../helpers/telegram';
import PresentIcon from '../../../components/icons/present';
import parseHtml from 'html-react-parser';
import './bonuses.scss';
import { useTranslation } from 'react-i18next';
import { useFindProject } from '../../../hooks/useFindProject';

const ProjectBonuses = (): JSX.Element => {
  const { t } = useTranslation();
  const { project } = useFindProject();

  if (!project) {
    return <NotFound />;
  }

  const { name, subscribers_bonus, promocode_platforms, allowed_regions } = project;
  const renderHeaderIcon = () => {
    return (
      <>
        <PresentIcon color="#ffffff" />
      </>
    );
  };
  const platformsList = promocode_platforms.join(', ');

  useEffect(() => {
    TG.BackButton?.show();
    TG.MainButton?.hide();
  }, []);

  return (
    <div className="project-bonuses">
      <PageHeader title={t('bonus_detail')} subtitle={name} icon={renderHeaderIcon()} />

      <PageContent>
        <Container>
          {subscribers_bonus ? (
            <div className="project-bonuses__bonuses-list mb-4">
              <div className="page__h2">{t('conditions')}</div>
              <div className="text-md">{parseHtml(subscribers_bonus)}</div>
            </div>
          ) : (
            ''
          )}

          {allowed_regions ? (
            <div className="mb-4">
              <div className="page__h2">{t('allowed_regions')}</div>
              <div className="text-md">{allowed_regions}</div>
            </div>
          ) : (
            ''
          )}

          {promocode_platforms && promocode_platforms.length ? (
            <div className="mb-4">
              <div className="page__h2">{t('bonus_application')}</div>
              <div className="text-md">{platformsList}</div>
            </div>
          ) : (
            ''
          )}
        </Container>
      </PageContent>
    </div>
  );
};

export default ProjectBonuses;
