import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import './about.scss';
import PageHeader from '../../../components/page-header/page-header';
import NotFound from '../../not-found/not-found';
import PageContent from '../../../components/page-content/page-content';
import { TG } from '../../../helpers/telegram';
import { useTranslation } from 'react-i18next';
import { useFindProject } from '../../../hooks/useFindProject';
import parseHtml from 'html-react-parser';

const ProjectAbout = (): JSX.Element => {
  const { t } = useTranslation();
  const { project } = useFindProject();

  if (!project) {
    return <NotFound />;
  }

  const { name, logo, product_info, adv_info } = project;
  const renderLogo = () => {
    return (
      <>
        <img src={logo} alt={name} />
      </>
    );
  };

  useEffect(() => {
    TG.BackButton?.show();
    TG.MainButton?.hide();
  }, []);

  return (
    <div className="project-about">
      <PageHeader
        title={t('about_product')}
        subtitle={name}
        icon={renderLogo()}
      />

      <PageContent>
        <Container>
          {product_info && (
            <div className="mb-4">
              <p className="text-md">{parseHtml(product_info)}</p>
            </div>
          )}

          {adv_info && (
            <div className="mb-4">
              <div className="page__h2">{t('advertiser')}</div>
              <p className="text-md">{adv_info}</p>
            </div>
          )}
        </Container>
      </PageContent>
    </div>
  );
};

export default ProjectAbout;
