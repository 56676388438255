import PageHeader from '../../../components/page-header/page-header';
import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { TG } from '../../../helpers/telegram';

const ContestPreview = () => {
  useEffect(() => {
    TG.BackButton?.show();
  }, []);

  return (
    <div className="contest-preview">
      <PageHeader title="Анонс" />
      <Container>Контент</Container>
    </div>
  );
};

export default ContestPreview;
