import { combineReducers, configureStore } from '@reduxjs/toolkit';
import appStateReducer from './slices/appStateSlice';
import projectsReducer from './slices/projectsSlice';
import promocodeReducer from './slices/promocodeSlice';
import contestsReducer from './slices/contestsSlice';
import analyticReducer from './slices/analyticSlice';

const rootReducer = combineReducers({
  appState: appStateReducer,
  projects: projectsReducer,
  promocode: promocodeReducer,
  contests: contestsReducer,
  analytic: analyticReducer
});

export const setupStore = () => {
  return configureStore({
    reducer: rootReducer
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
