import React, { ChangeEvent, KeyboardEvent, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  setSearchProjectValue,
  setShowProjectsNav
} from '../../store/slices/appStateSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useTranslation } from 'react-i18next';
import { isEnterKey } from '../../helpers/common';
import { debounce } from 'lodash';

const SearchPanel = () => {
  const { t } = useTranslation();
  const { searchProjectValue } = useAppSelector((state) => state.appState);
  const dispatch = useAppDispatch();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const searchProjectInputHandler = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch(setSearchProjectValue(event.target.value));
  };

  const searchInputFocusHandler = () => {
    dispatch(setShowProjectsNav(false));
    window.scroll(0, 0);
  };

  const searchInputBlurHandler = () => {
    dispatch(setShowProjectsNav(true));
  };

  const searchInputKeydownHandler = (evt: KeyboardEvent) => {
    const blurSearchInput = () => {
      if (isEnterKey(evt)) {
        dispatch(setShowProjectsNav(true));
        searchInputRef.current?.blur();
      }
    };

    const debouncedBlurSearchInput = debounce(blurSearchInput, 200);
    debouncedBlurSearchInput();
  };

  const searchInputReset = () => {
    dispatch(setSearchProjectValue(''));
  };

  return (
    <div className="projects-list__search">
      <div className="search">
        <FontAwesomeIcon
          icon={faSearch}
          style={{ color: 'gray', marginRight: '6px' }}
        />
        <input
          className="search__input"
          type="text"
          placeholder={t('search')}
          value={searchProjectValue}
          onChange={searchProjectInputHandler}
          onFocus={searchInputFocusHandler}
          onBlur={searchInputBlurHandler}
          onKeyDown={searchInputKeydownHandler}
          ref={searchInputRef}
        />
        {searchProjectValue.length > 0 && (
          <button
            type="button"
            className="search__reset"
            onClick={searchInputReset}>
            <FontAwesomeIcon
              icon={faTimes}
              size="1x"
              style={{ color: 'red' }}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default SearchPanel;
