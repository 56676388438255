import { HiUsers } from 'react-icons/hi2';
import { GiClick } from 'react-icons/gi';
import { useAppSelector } from './redux';
import { useParams } from 'react-router-dom';

export const useGetAntiFraudStat = (id?: string) => {
  const { id: contestId } = useParams();
  const contestAntiFraudStat = useAppSelector(
    (s) =>
      s.contests.contestsInfo.find((c) => c.id === Number(contestId))
        ?.contest_stat
  );

  const referral = useAppSelector((s) =>
    s.contests.contestParticipants.find(
      (participant) =>
        participant.chat_id === Number(id) &&
        participant.contest_id === Number(contestId)
    )
  );

  const contestParticipantAntiFraudStat = referral?.contest_stat;

  const antiFraudStat = id
    ? contestParticipantAntiFraudStat
    : contestAntiFraudStat;

  const antiFraudInfo = [
    {
      header: {
        headerIcon: HiUsers,
        headerTitle: 'Подписчики'
      },
      info: [
        {
          infoTitle: 'Подписались на бота',
          infoTitleValue: antiFraudStat?.count
        },
        {
          infoTitle: 'Из них Premium-пользователи Telegram',
          infoTitleValue: antiFraudStat?.premium_count
        },
        {
          infoTitle: 'Отписались (обновляется после рассылки)',
          infoTitleValue: antiFraudStat?.describer_count
        }
      ]
    },
    {
      header: {
        headerIcon: GiClick,
        headerTitle: 'Вовлечение'
      },
      info: [
        {
          infoTitle: 'Запустили виджет с промокодами',
          infoTitleValue: antiFraudStat?.count_of_launch_widget
        },
        {
          infoTitle: 'Посмотрели карточку проекта',
          infoTitleValue: antiFraudStat?.count_of_view_project
        },
        {
          infoTitle: 'Скопировали промокод',
          infoTitleValue: antiFraudStat?.count_of_copy_code
        }
      ]
    }
  ];

  return { antiFraudInfo, subsCount: antiFraudStat?.count };
};
