export function localizeDate(date: string) {
  const localeDate = new Date(date).toLocaleDateString();
  const localeTime = new Date(date).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit'
  });
  const year = new Date(date).getFullYear().toString().slice(-2);

  return [`${localeDate.replace(/(\d{4})/, year)}`, localeTime];
}

function getTimeDifference(
  startDate: Date,
  endDate: Date,
  unit: 'days' | 'hours' | 'minutes' | 'seconds'
) {
  const diffInMs = endDate.getTime() - startDate.getTime();

  if (unit === 'days') {
    return diffInMs / (1000 * 3600 * 24);
  } else if (unit === 'hours') {
    return diffInMs / (1000 * 3600);
  } else if (unit === 'minutes') {
    return diffInMs / (1000 * 60);
  }

  return diffInMs / 1000;
}

function getNoun(number: number, nouns: string[]) {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return nouns[2];
  }
  n %= 10;
  if (n === 1) {
    return nouns[0];
  }
  if (n >= 2 && n <= 4) {
    return nouns[1];
  }
  return nouns[2];
}

function getDayNoun(dayNumber: number) {
  const daysNouns = ['день', 'дня', 'дней'];

  return getNoun(dayNumber, daysNouns);
}

function getHourNoun(hourNumber: number) {
  const hourNouns = ['час', 'часа', 'часов'];

  return getNoun(hourNumber, hourNouns);
}

function getMinutesNoun(minuteNumber: number) {
  const minuteNouns = ['минута', 'минуты', 'минут'];

  return getNoun(minuteNumber, minuteNouns);
}

export const calculateContestProgress = (
  startContestDate: Date,
  endContestDate: Date
) => {
  const now = new Date();
  const remainingContestDurationInS = getTimeDifference(
    now,
    endContestDate,
    'seconds'
  );
  const contestDurationInS = getTimeDifference(
    startContestDate,
    endContestDate,
    'seconds'
  );

  const progressBarWidth =
    (1 - remainingContestDurationInS / contestDurationInS) * 100;

  const remainingContestDays = getTimeDifference(now, endContestDate, 'days');
  const remainingContestHours = getTimeDifference(now, endContestDate, 'hours');
  const remainingContestMinutes = getTimeDifference(
    now,
    endContestDate,
    'minutes'
  );

  let remainingContestDuration: number;
  let remainingContestNoun: string;

  if (remainingContestDays >= 1) {
    const intRemainingContestDays = Math.ceil(remainingContestDays);
    remainingContestDuration = intRemainingContestDays;
    remainingContestNoun = getDayNoun(intRemainingContestDays);
  } else if (remainingContestHours >= 1) {
    const intRemainingContestHours = Math.ceil(remainingContestHours);
    remainingContestDuration = intRemainingContestHours;
    remainingContestNoun = getHourNoun(intRemainingContestHours);
  } else {
    const intRemainingContestMinutes = Math.ceil(remainingContestMinutes);
    remainingContestDuration = intRemainingContestMinutes;
    remainingContestNoun = getMinutesNoun(intRemainingContestMinutes);
  }

  return {
    progressBarWidth,
    remainingContestDuration,
    remainingContestNoun
  };
};

export function transformDateLabelForGraph(date: string) {
  const [day, month] = date.slice(-8).split('.');

  return `${day}.${month}`;
}
