import { useParams } from 'react-router';
import { useAppDispatch, useAppSelector } from './redux';
import { useEffect } from 'react';
import { TG } from '../helpers/telegram';
import { TG_MAIN_BUTTON } from '../const';
import { setIsContestSendMessagePage } from '../store/slices/appStateSlice';
import { sendResults } from '../store/thunk/ContestThunk';
import { userId } from '../helpers/api';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { ErrorDataResponse } from '../api/models/data';
import { resolveLoggerInstance } from '../helpers/logger';
import { useYM } from './useYM';

export const useSendContestResultsInit = () => {
  const { id, chat_id } = useParams();
  const isAdmin = useAppSelector((s) => s.projects.additionalData.isAdmin);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const logger = resolveLoggerInstance('useSendContestResultsInit');
  const YM = useYM();

  useEffect(() => {
    if (isAdmin) {
      TG.MainButton?.show();
      TG.MainButton?.enable();
      TG.MainButton?.setText(t('send_results_to_tg'));
      TG.MainButton?.setParams({
        color: TG_MAIN_BUTTON.color
      });
      TG.MainButton?.onClick(sendResultsHandler);
      dispatch(setIsContestSendMessagePage(true));
    }
    return () => {
      TG.MainButton?.offClick(sendResultsHandler);
      dispatch(setIsContestSendMessagePage(false));
      TG.MainButton?.hide();
    };
  }, []);

  const sendResultsHandler = async () => {
    try {
      await dispatch(
        sendResults({
          chat_id: userId,
          contest_id: Number(id),
          subscriber_chat_id: Number(chat_id) ? Number(chat_id) : undefined
        })
      ).unwrap();
      TG.WebApp?.close();
    } catch (e) {
      if (e instanceof AxiosError) {
        logger.error(e);
        const xRequestId = e.response?.headers['x-request-id'];
        const responseDataError: ErrorDataResponse = e.response
          ?.data as ErrorDataResponse;
        if (e.response?.status === 500) {
          TG.WebApp?.showAlert(
            'Ой, что-то пошло не так\n\n' + t('error_id') + ': ' + xRequestId
          );
        } else {
          TG.WebApp?.showAlert(responseDataError.message);
        }
        YM.errorLog(e.config?.url, userId, e.message, xRequestId);
        Sentry.captureException(e);
      }
    }
  };
};
