import { z } from 'zod';

export const eventTypes = z.enum([
  'copy',
  'copy_and_redirect',
  'redirect',
  'request_promo',
  'request_user_promo',
  'view_project',
  'view_category',
  'view_contest',
  'web_app_launch'
]);

export const sendBotLogsParamsSchema = z.object({
  chat_id: z.number(),
  project_id: z.union([z.string(), z.number()]).nullable().optional(),
  landing_id: z.number().nullable().optional(),
  contest_id: z.number().nullable().optional(),
  category_id: z.number().nullable().optional(),
  promo_group_id: z.string().nullable().optional(),
  event_type: eventTypes
});

export const sendBotLogsDataSchema = z.object({
  success: z.boolean()
});

export type SendBotLogsParams = z.infer<typeof sendBotLogsParamsSchema>;
export type SendBotLogsData = z.infer<typeof sendBotLogsDataSchema>;
export type EventTypes = z.infer<typeof eventTypes>;
