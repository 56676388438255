const storage = import.meta.env;

export const config = {
    dev: storage.DEV,
    isTest: storage.VITE_TEST_ENV,
    prod: storage.PROD,
    apiHost: storage.VITE_API_HOST,
    logLevel: storage.VITE_LOG_LEVEL,
    telegramUser: storage.VITE_TELEGRAM_USER,
    sentryKey: storage.VITE_SENTRY_KEY
}
