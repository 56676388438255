import { Project } from '../models/project';
import { Promocode } from '../models/promocode';

export const getPromoById = (projects: Project[], id?: string) => {
  const promoCodeMap: Map<number | string, Promocode> = new Map();
  projects.forEach((project) => {
    project.groups
      .flatMap((group) => group.promocodes || [])
      .forEach((promo) => promoCodeMap.set(promo.id, promo));
  });

  return promoCodeMap.get(Number(id));
};
