import React from 'react';
import { useTranslation } from 'react-i18next';
import { Group } from '../../../models/group';
import { useRequestPromo } from '../../../hooks/useRequestPromo';

type Props = {
  group: Group;
  projectId: number | string;
  projectName: string;
  className?: string;
};

const RequestPromocodeButton = (params: Props) => {
  const { group, projectId } = params;
  const { t } = useTranslation();

  const { isRequestingPromo, alreadyRequested, handleRequestPromo } =
    useRequestPromo(group, projectId);

  return (
    <>
      <button
        className={`btn-main ${params.className}`}
        type="button"
        disabled={Boolean(alreadyRequested) || isRequestingPromo}
        onClick={handleRequestPromo}>
        {isRequestingPromo ? (
          <span className="animation-flash">{t('requesting')}</span>
        ) : alreadyRequested ? (
          t('already_requested_promo')
        ) : (
          t('request_promo')
        )}
      </button>
    </>
  );
};

export default RequestPromocodeButton;
