import { GraphData } from '../api/models/contests';
import { localizeDate } from './date';

export function transformGraphData(
  data: GraphData,
  startDate: string,
  endDate: string
) {
  const startDateTime = new Date(startDate);
  const currentDateTimeIsoString = new Date().toISOString();
  const currentDateTime = new Date(currentDateTimeIsoString);
  const endDateTime =
    new Date(endDate) < currentDateTime ? new Date(endDate) : currentDateTime;

  startDateTime.setMinutes(0);
  startDateTime.setSeconds(0);

  const graphData = [];
  const currentDateTimeTick = new Date(startDateTime);
  while (currentDateTimeTick <= endDateTime) {
    const hourStr = currentDateTimeTick.toISOString().split('.')[0] + 'Z';
    const existingItem = data?.find((item) => item.hour === hourStr);
    const [localeDate, localeTime] = localizeDate(hourStr);
    if (existingItem) {
      graphData.push({ ...existingItem, hour: `${localeTime} ${localeDate}` });
    } else {
      graphData.push({ hour: `${localeTime} ${localeDate}`, count: 0 });
    }
    currentDateTimeTick.setHours(currentDateTimeTick.getHours() + 1);
  }

  return graphData;
}
