import { Category } from '../models/state';

export const sortCategories = (a: Category, b: Category) => {
  if (a.projects_count < b.projects_count) {
    return 1;
  }

  if (a.projects_count > b.projects_count) {
    return -1;
  }

  return 0;
};
