import { Container } from 'react-bootstrap';
import styles from './contest-intro-info.module.scss';

const ContestIntroInfo = () => {
  return (
    <div className={styles.wrapper}>
      <Container className={styles.container}>
        <div className={styles.title}>
          Конкурс — это уникальная механика в боте, которая позволяет привлекать
          новых подписчиков через «сарафанное радио»
        </div>
        <div className={styles.description}>
          <p>
            После создания конкурса, у всех подписчиков в боте появится вкладка
            Конкурс, на которой он найдет свою уникальную ссылку для приглашения
            друзей.
          </p>
          <p>
            Если подписчик перешлёт ссылку другу, и тот воспользуется ботом, то
            по маркеру мы сможем понять, по чьей рекомендации это произошло и
            посчитать, какой подписчик сколько «сарафана» привел. Считаются
            только те пользователи, которые ранее не пользовались ботом, и,
            вполне вероятно, ещё не сделали свои первые заказы!
          </p>
          <p>
            Механику награждения победителя ты можешь придумать сам. Можно
            назначить денежные призы тем, кто вошел в Топ 3. Можно оплатить
            самым активным подписчикам по 10 рублей за приведенного реферала.
            Можно рандомом разыграть несколько призов среди участников, которые
            приведут не менее 10 «сарафанных» друзей. У каждого подхода есть
            свои плюсы и минусы.
          </p>
          <p>
            Конкурс имеет конечную дату проведения. При подведении итогов
            учитываются только те подписчики, которые пришли с момента начала
            конкурса до его завершения. В момент завершения конкурса бот
            отправит сообщение с результатами всем участникам, которые
            пригласили в бот хотя бы одного нового участника.
          </p>
          <p>
            Чтобы конкурс прошел максимально эффективно, почаще напоминай о нем
            подписчикам, публикуй скриншоты рейтинга. Мотивируй их делиться
            ссылками с друзьями и знакомыми.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default ContestIntroInfo;
