import { z } from 'zod';

const bannedUsersSchema = z.array(
  z.object({
    chat_id: z.number(),
    username: z.string().nullable().optional(),
    full_name: z.string().nullable().optional(),
    contest_stat: z
      .object({
        count: z.number(),
        premium_count: z.number(),
        describer_count: z.number(),
        count_of_launch_widget: z.number(),
        count_of_view_project: z.number(),
        count_of_copy_code: z.number()
      })
      .optional()
  })
);

const contestResultsSchema = z.array(
  z.object({
    i: z.number(),
    referrals_count: z.number(),
    chat_id: z.number(),
    username: z.string().nullable().optional(),
    full_name: z.string().nullable().optional(),
    contest_stat: z
      .object({
        count: z.number(),
        premium_count: z.number(),
        describer_count: z.number(),
        count_of_launch_widget: z.number(),
        count_of_view_project: z.number(),
        count_of_copy_code: z.number()
      })
      .optional()
  })
);

export const contestInfo = z.object({
  id: z.number(),
  results: contestResultsSchema,
  referrals: z.array(
    z.object({
      username: z.string().nullable().optional(),
      full_name: z.string().nullable(),
      created_at: z.string()
    })
  ),
  started_at: z.string(),
  finished_at: z.string(),
  referral_link: z.string().nullable(),
  conditions: z.string().nullable(),
  banned_users: bannedUsersSchema,
  contest_referrals_count: z.number().optional(),
  admin: z
    .object({
      chat_id: z.number().nullable(),
      username: z.string().nullable().optional(),
      full_name: z.string().nullable().optional()
    })
    .optional(),
  is_finished: z.boolean().optional(),
  contest_stat: z
    .object({
      count: z.number(),
      premium_count: z.number(),
      describer_count: z.number(),
      count_of_launch_widget: z.number(),
      count_of_view_project: z.number(),
      count_of_copy_code: z.number()
    })
    .optional()
});

export const graphData = z
  .array(
    z.object({
      hour: z.string(),
      count: z.number()
    })
  )
  .optional();

export const contestParticipantInfo = z.object({
  chat_id: z.number(),
  username: z.string().nullable().optional(),
  full_name: z.string().nullable().optional(),
  is_banned: z.boolean(),
  contest_started_at: z.string(),
  contest_finished_at: z.string(),
  contest_stat: z.object({
    count: z.number(),
    premium_count: z.number(),
    describer_count: z.number(),
    count_of_launch_widget: z.number(),
    count_of_view_project: z.number(),
    count_of_copy_code: z.number(),
    subscribe_points: graphData
  })
});

const contestListItem = z.object({
  id: z.number(),
  started_at: z.string(),
  finished_at: z.string(),
  is_finished: z.boolean()
});

export const contestList = z.array(contestListItem);

export const contestSendMessageDataSchema = z.object({
  success: z.boolean()
});

export const getContestListParamsSchema = z.object({
  chat_id: z.number()
});

export const sendContestMessageToUserParams = z.object({
  text: z.string(),
  chat_id: z.number(),
  to_chat_id: z.number()
});

export const sendContestResultParams = z.object({
  chat_id: z.number(),
  contest_id: z.number(),
  subscriber_chat_id: z.number().optional()
});

export const createContestParams = z.object({
  chat_id: z.number(),
  conditions: z.string(),
  expiration_date: z.string()
});

export const createContestDataSchema = z.object({
  success: z.boolean(),
  id: z.number(),
  started_at: z.string(),
  finished_at: z.string(),
  is_finished: z.boolean(),
  conditions: z.string(),
  status: z.number().optional(),
  name: z.string().optional(),
  message: z.string().optional()
});

export const updateContestDataSchema = z.object({
  success: z.boolean(),
  id: z.number(),
  conditions: z.string(),
  started_at: z.string(),
  finished_at: z.string(),
  is_finished: z.boolean()
});

export const updateContestParams = z.object({
  chat_id: z.number(),
  contest_id: z.number(),
  conditions: z.string().optional(),
  expiration_date: z.string().optional()
});

export const banUnbanContestUserParams = z.object({
  contest_id: z.number(),
  user_chat_id: z.number()
});

export const banUnbanContestUserDataSchema = z.object({
  success: z.boolean(),
  contest_id: z.number(),
  is_banned_on_contests: z.boolean(),
  results: contestResultsSchema,
  banned_users: bannedUsersSchema
});

export const finishContestParams = z.object({
  chat_id: z.number(),
  contest_id: z.number()
});

export const finishContestDataSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  id: z.number(),
  finished_at: z.string()
});

export const getContestParticipantParamsSchema = z.object({
  participant_chat_id: z.number(),
  contest_id: z.number()
});

export type ContestListItem = z.infer<typeof contestListItem>;
export type ContestList = z.infer<typeof contestList>;
export type ContestInfo = z.infer<typeof contestInfo>;
export type GraphData = z.infer<typeof graphData>;
export type ContestParticipantInfo = z.infer<typeof contestParticipantInfo>;
export type ContestSendMessageDataSchema = z.infer<
  typeof contestSendMessageDataSchema
>;
export type CreateContestDataSchema = z.infer<typeof createContestDataSchema>;
export type UpdateContestDataSchema = z.infer<typeof updateContestDataSchema>;
export type BanUnbanContestUserDataSchema = z.infer<
  typeof banUnbanContestUserDataSchema
>;
export type FinishContestDataSchema = z.infer<typeof finishContestDataSchema>;
