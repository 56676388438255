import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/redux';
import { setCurrentProject } from '../../store/slices/projectsSlice';
import NotFound from '../not-found/not-found';
import { useFindProject } from '../../hooks/useFindProject';

const Project = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { project } = useFindProject();

  if (!project) {
    return <NotFound />;
  }

  useEffect(() => {
    dispatch(setCurrentProject(project));
  }, [project]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default Project;
