import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import {
  Filter,
  MockData,
  transformObjectKeysLabel
} from '../../pages/analytics/analytics';
import { AnalyticDataItem } from '../../api/models/analytic';

const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const COLORS = [
  'rgb(229, 53, 19)',
  'rgb(12, 229, 200)',
  'rgb(31, 199, 249)',
  'rgb(254, 103, 62)',
  'rgb(90, 216, 44)'
];

interface LineGraphProps {
  data: AnalyticDataItem[];
  filters: Filter[];
}

const LineGraph = ({ data, filters }: LineGraphProps) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart width={500} height={100} data={data}>
        <XAxis
          dataKey="date"
          interval={data.length - 2}
          padding={{ left: 12, right: 12 }}
          tickFormatter={(date) => date.slice(0, 5)}
        />
        {/*<YAxis />*/}
        <Tooltip
          formatter={(value, name) => [
            value,
            transformObjectKeysLabel(name as keyof AnalyticDataItem)
          ]}
        />
        <Legend formatter={(value) => transformObjectKeysLabel(value)} />
        {Object.keys(data[0])
          .filter((key) => key !== 'date' && key !== 'isDistribution')
          .map(
            (key, index) =>
              filters.find((filter) => filter.key === key)?.active && (
                <Line
                  key={key}
                  type="monotone"
                  dataKey={key}
                  stroke={COLORS[index]}
                />
              )
          )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineGraph;
