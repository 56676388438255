import { Container } from 'react-bootstrap';
import ContestEndDatePicker from '../../../components/contest-end-date-picker/contest-end-date-picker';
import { useUpdateContest } from '../../../hooks/useUpdateContest';
import styles from '../../../components/contest-end-date-picker/contest-end-date-picker.module.scss';
import { finishContestRequest } from '../../../store/thunk/ContestThunk';
import { apiKey, userId } from '../../../helpers/api';
import { TG } from '../../../helpers/telegram';
import { useAppDispatch } from '../../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import Flags from '../../../assets/flags.svg?react';
import { PATHS } from '../../../const';
import { useLoadContest } from '../../../hooks/useLoadContest';
import Loading from '../../loading/loading';

const ContestChangeEndDate = () => {
  const { contest, loading } = useLoadContest();
  const { dateValue, setDateValue, errors } = useUpdateContest();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const participantCount = contest?.contest_referrals_count || 0;

  const finishContestHandler = () => {
    const doRequest = async (confirmed: boolean) => {
      if (!confirmed) {
        return;
      }

      try {
        await dispatch(
          finishContestRequest({
            chat_id: userId,
            contest_id: Number(id),
            participant_count: participantCount
          })
        ).unwrap();
        if (participantCount > 0) {
          navigate(`/contest/${Number(id)}?key=${apiKey}`);
        } else {
          navigate(`${PATHS.contests}?key=${apiKey}`);
        }
      } catch (e) {
        TG.WebApp?.showAlert('Произошла ошибка');
      }
    };

    TG.WebApp?.showConfirm(
      participantCount > 0 ? t('end_the_contest_q') : t('delete_the_contest_q'),
      doRequest
    );
  };

  if (loading) return <Loading />;
  if (!contest) return <Container>Нет информации о данном конкурсе</Container>;

  return (
    <Container>
      <ContestEndDatePicker
        dateValue={dateValue}
        setDateValue={setDateValue}
        error={errors.expirationDate}
      />
      <button className={styles.button} onClick={finishContestHandler}>
        <Flags />
        {participantCount > 0
          ? t('complete_the_contest')
          : t('delete_the_contest')}
      </button>
    </Container>
  );
};

export default ContestChangeEndDate;
