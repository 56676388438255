import React from 'react';
import PromocodeItem from './promocode/promocode-item';
import { nanoid } from 'nanoid';
import LinkForSubs from './link-for-subs/link-for-subs';
import ScreenIcon from '../icons/screen';
import './group.scss';
import CustomCard from '../cards/custom-card';
import RequestPromocodeButton from './request-promocode/request-promocode';
import HitBadge from '../badges/hit/hit';
import { useTranslation } from 'react-i18next';
import { Group } from '../../models/group';
// import { useGetOrdMarkers } from '../../hooks/useGetOrdMarkers';
import { useRequestSubscriberPromo } from '../../hooks/useRequestSubscriberPromo';
import { userId } from '../../helpers/api';
import { groupHasOffers } from '../../helpers/promo';

interface Props {
  projectId: number | string;
  projectName: string;
  group: Group;
}

const GroupItem = ({ projectId, projectName, group }: Props) => {
  const {
    landing,
    links_for_subscribers,
    has_tg_subscribers_promo,
    subscribers_codes_count,
    promocodes,
    tg_subscriber_codes,
    can_request
  } = group;

  const { t } = useTranslation();
  // const ordMarkers = useGetOrdMarkers(promocodes);
  const { isRequestingPromo, handleRequestUserPromo } =
    useRequestSubscriberPromo(projectId, landing);

  const link = links_for_subscribers?.[0]?.link ?? landing?.link ?? '';

  const showGroup =
    (can_request && !landing) || (!userId && !groupHasOffers(group));

  const showLandingName =
    landing?.name &&
    ((has_tg_subscribers_promo && !tg_subscriber_codes?.length) ||
      !has_tg_subscribers_promo);

  const canRequestPromoCode = !!(can_request && userId);
  const canRequestSubscriberPromoCode = !!(
    userId &&
    has_tg_subscribers_promo &&
    !tg_subscriber_codes?.length
  );

  const hasLinksForSubs = !!(
    links_for_subscribers &&
    links_for_subscribers.length &&
    promocodes &&
    promocodes.length === 0
  );
  return (
    <>
      {showGroup ? (
        ''
      ) : (
        <li className="group group--card">
          <CustomCard>
            {showLandingName ? (
              <>
                <div className="group__title">
                  {has_tg_subscribers_promo && !tg_subscriber_codes?.length ? (
                    <>
                      <span className="group__name">
                        <HitBadge className="me-2" />
                        {landing.name}
                      </span>
                    </>
                  ) : (
                    <>
                      <ScreenIcon
                        color={'var(--tg-theme-text-color)'}
                        className="group__img"
                      />
                      <span className="group__name">{landing.name}</span>
                    </>
                  )}
                </div>
              </>
            ) : (
              ''
            )}
            {tg_subscriber_codes?.length ? (
              <div className="group__promocodes">
                {tg_subscriber_codes.map((promo) => (
                  <PromocodeItem
                    projectName={projectName}
                    promo={{ ...promo, is_hit: true }}
                    link={link}
                    key={promo.id}
                  />
                ))}
              </div>
            ) : (
              ''
            )}
            {canRequestSubscriberPromoCode && (
              <button
                className="btn-main"
                type="button"
                disabled={isRequestingPromo || !subscribers_codes_count}
                onClick={handleRequestUserPromo}>
                {isRequestingPromo ? (
                  <span className="animation-flash">
                    {t('request_user_promo')}
                  </span>
                ) : !subscribers_codes_count ? (
                  t('promos_soon')
                ) : (
                  t('request_user_promo')
                )}
              </button>
            )}

            {promocodes && promocodes.length ? (
              <div className="group__promocodes">
                {promocodes.map((promo) => (
                  <PromocodeItem
                    projectName={projectName}
                    promo={promo}
                    link={link}
                    key={promo.id}
                  />
                ))}
                {/*{ordMarkers.length*/}
                {/*  ? ordMarkers.map((marker) => (*/}
                {/*      <div key={nanoid()} className="promo__ord">*/}
                {/*        {marker}*/}
                {/*      </div>*/}
                {/*    ))*/}
                {/*  : ''}*/}
              </div>
            ) : (
              ''
            )}
            {hasLinksForSubs ? (
              <div className="group__links">
                {links_for_subscribers.map((link) => (
                  <LinkForSubs
                    landing={landing}
                    projectId={projectId}
                    projectName={projectName}
                    linkItem={link}
                    key={nanoid()}
                  />
                ))}
              </div>
            ) : (
              ''
            )}
            {canRequestPromoCode && (
              <RequestPromocodeButton
                group={group}
                projectId={projectId}
                projectName={projectName}
              />
            )}
          </CustomCard>
        </li>
      )}
    </>
  );
};

export default GroupItem;
