import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import './categories-list.scss';
import { useYM } from '../../hooks/useYM';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { sortCategories } from '../../helpers/sort';
import { setSearchProjectValue } from '../../store/slices/appStateSlice';
import { useTranslation } from 'react-i18next';
import CategoriesListItem from '../categories-list-item/categories-list-item';
import { setSelectedCategory } from '../../store/slices/appStateSlice';
import { userId } from '../../helpers/api';
import { Category } from '../../models/state';
import { filterCategories } from '../../helpers/categories';
import { sendBotLogs } from '../../api/log';

const CategoriesList = (): JSX.Element => {
  const projects = useAppSelector((state) => state.projects.projects.items);
  const categories = useAppSelector((state) =>
    state.projects.projects.categories.slice().sort(sortCategories)
  );

  const getCategories = (categories: Category[]) => {
    if (!userId) {
      return filterCategories(categories, projects);
    }

    return categories;
  };
  const visibleCategories = getCategories(categories);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const YM = useYM();

  useEffect(() => {
    window.scroll(0, 0);
  });

  const onCategoryClick = async (name: string, id: number | null) => {
    dispatch(setSelectedCategory(name));
    dispatch(setSearchProjectValue(''));
    await sendBotLogs({
      chat_id: userId,
      category_id: id,
      event_type: 'view_category'
    });
    YM.viewCategory(id, name);
  };

  if (!visibleCategories.length) {
    return (
      <>
        <Container>{t('categories_empty')}</Container>
      </>
    );
  }

  return (
    <div
      style={{
        position: 'relative',
        width: `${window.innerWidth}px`,
        overflow: 'hidden'
      }}>
      <ul className="categories-list">
        {visibleCategories.map((category) => (
          <CategoriesListItem
            key={nanoid()}
            category={category}
            onCategoryClick={onCategoryClick}
          />
        ))}
      </ul>
    </div>
  );
};

export default CategoriesList;
