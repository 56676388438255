import React from 'react';
import './link-for-subs.scss';
import { TG } from '../../../helpers/telegram';
import { useYM } from '../../../hooks/useYM';
import { GrabEvents } from '../../../@types/enums';
import GiftIcon from '../../icons/gift';
import { useTranslation } from 'react-i18next';
import { Landing } from '../../../models/landing';
import { LinkForSubscribers } from '../../../models/link-for-subscribers';
import { sendBotLogs } from '../../../api/log';
import { userId } from '../../../helpers/api';

interface Props {
  landing: Landing | null;
  projectId: number | string;
  projectName: string;
  linkItem: LinkForSubscribers;
}

const LinkForSubs = ({ landing, projectId, projectName, linkItem }: Props) => {
  const { title, link } = linkItem;
  const { t } = useTranslation();
  const YM = useYM();

  const redirectLinkClickHandler = async () => {
    await sendBotLogs({
      chat_id: userId,
      project_id: projectId,
      landing_id: landing ? landing.id : null,
      promo_group_id: null,
      event_type: 'redirect'
    });

    YM.grabOffer({
      projectId: projectId,
      projectName: projectName,
      landingId: landing ? landing.id : null,
      promoGroup: null,
      eventType: GrabEvents.redirect
    });
    setTimeout(() => {
      TG.WebApp?.openLink(link);
    });
  };

  return (
    <>
      <div className="link-for-subs__wrapper">
        {!title || title.length <= 1 || title === t('link_for_subs') ? (
          ''
        ) : (
          <div className="link-for-subs__title">
            <GiftIcon
              color="var(--tg-theme-link-color)"
              style={{ marginBottom: '4px' }}
            />
            &nbsp;{title}
          </div>
        )}
        <button
          className="btn-main"
          type="button"
          onClick={redirectLinkClickHandler}>
          {t('redirect')}
        </button>
      </div>
    </>
  );
};

export default LinkForSubs;
