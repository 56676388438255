import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PATHS } from '../../const';
import { useTranslation } from 'react-i18next';

interface Props {
  alert?: string;
}

const NotFound = ({ alert }: Props): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <div className="not-found">
          <Alert variant="danger">{alert ?? t('page_not_found')}</Alert>
          <Link to={PATHS.projects} className="w-100 btn btn-primary">
            {t('to_index')}
          </Link>
        </div>
      </Container>
    </>
  );
};

export default NotFound;
