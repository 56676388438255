import React from 'react';
import { Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { PATHS } from '../../const';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setSelectedCategory } from '../../store/slices/appStateSlice';
import { useTranslation } from 'react-i18next';
import { saveScrollPosition } from '../../helpers/common';
import { apiKey } from '../../helpers/api';

const MainNav = (): JSX.Element => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const hasContests = useAppSelector(
    (s) => s.projects.additionalData.hasContests
  );
  const isAdmin = useAppSelector((s) => s.projects.additionalData.isAdmin);

  const contestsLink = isAdmin
    ? `${PATHS.contests}?key=${apiKey}`
    : `/contest?key=${apiKey}`;

  const contestsTab =
    pathname === PATHS.contests || pathname.includes('/contest') ? (
      <span
        className={`${
          hasContests ? 'contests' : ''
        } nav-buttons__item nav-buttons__item--active`}>
        {isAdmin ? t('contests') : t('contest')}
      </span>
    ) : (
      <Link
        to={contestsLink}
        className={
          `${hasContests ? 'contests' : ''} nav-buttons__item` +
          (pathname === PATHS.contests ? 'nav-buttons__item--active' : '')
        }
        onClick={() => saveScrollPosition()}>
        {isAdmin ? t('contests') : t('contest')}
      </Link>
    );

  const shouldRenderContestsTab = isAdmin || hasContests;

  return (
    <div className="nav-buttons__wrapper">
      <Container>
        <nav className="nav-buttons">
          {pathname === PATHS.projects ? (
            <span className="nav-buttons__item nav-buttons__item--active">
              {t('actions')}
            </span>
          ) : (
            <Link
              to={`${PATHS.projects}?key=${apiKey}`}
              className={'nav-buttons__item'}
              onClick={() => {
                dispatch(setSelectedCategory(null));
              }}>
              {t('actions')}
            </Link>
          )}
          {pathname === PATHS.categories ? (
            <span className="nav-buttons__item nav-buttons__item--active">
              {t('categories')}
            </span>
          ) : (
            <Link
              to={`${PATHS.categories}?key=${apiKey}`}
              className={
                'nav-buttons__item ' +
                (pathname === PATHS.categories
                  ? 'nav-buttons__item--active'
                  : '')
              }
              onClick={() => saveScrollPosition()}>
              {t('categories')}
            </Link>
          )}
          {shouldRenderContestsTab && contestsTab}
        </nav>
      </Container>
    </div>
  );
};

export default MainNav;
