import React from 'react';
import NotFound from '../../not-found/not-found';
import GroupItem from '../../../components/group/group';
import { Container } from 'react-bootstrap';
import { nanoid } from 'nanoid';
import { SubscriberBonuses } from '../../../components/bonuses/subscriber-bonuses';
import './offers.scss';
import {
  getFirstSentence,
  removeHtmlTags,
  truncate
} from '../../../helpers/common';
import { Link } from 'react-router-dom';
import PageHeader from '../../../components/page-header/page-header';
import PageContent from '../../../components/page-content/page-content';
import BtnBordered from '../../../components/buttons/btn-bordered';
import { useTranslation } from 'react-i18next';
import { Group } from '../../../models/group';
import { useFindProject } from '../../../hooks/useFindProject';
import { useViewProject } from '../../../hooks/useViewProject';
import { userId } from '../../../helpers/api';
import RequestPromocodeButton from '../../../components/group/request-promocode/request-promocode';

const ProjectOffers = (): JSX.Element => {
  const { t } = useTranslation();
  const { project, id } = useFindProject();

  if (!project) {
    return <NotFound />;
  }

  useViewProject(project);

  const {
    logo,
    name,
    subscribers_bonuses,
    subscribers_bonus,
    product_info,
    allowed_regions,
    promocode_platforms
  } = project;

  const renderGroup = (group: Group) => {
    if (!project) {
      return <></>;
    }

    return (
      <div key={nanoid()}>
        {subscribers_bonuses &&
        subscribers_bonuses.length &&
        !group.landing &&
        !group.is_custom ? (
          <>
            <SubscriberBonuses
              projectName={name}
              projectId={project.id}
              group={group}
              bonuses={subscribers_bonuses}
            />
            {userId ? (
              <RequestPromocodeButton
                group={group}
                projectId={project.id}
                projectName={project.name}
                className="project-offers__btn"
              />
            ) : (
              ''
            )}
          </>
        ) : (
          ''
        )}
        {<GroupItem projectId={project.id} projectName={name} group={group} />}
      </div>
    );
  };
  const renderLogo = () => {
    return (
      <>
        <img className="project-offers__logo" src={logo} alt={name} />
      </>
    );
  };
  const renderProductInfo = () => {
    const info = removeHtmlTags(product_info);
    const firstSentence = getFirstSentence(info);
    const maxSymbols = 100;

    if (firstSentence.length > maxSymbols) {
      return truncate(info, maxSymbols, true);
    }

    return firstSentence + '.';
  };

  const aboutLink = `/project/${id}/about`;
  const bonusesLink = `/project/${id}/bonuses`;

  // const groupBonus = project.groups.find(g => !!g.can_request);

  return (
    <div className="project-offers">
      <PageHeader title={name} icon={renderLogo()} />
      <PageContent>
        <Container>
          {product_info && (
            <div className="mb-4">
              <p className="project-offers__description">
                {renderProductInfo()}
              </p>
              <Link to={aboutLink}>{t('detail')}</Link>
            </div>
          )}

          <ul className="project-offers__list">
            {project.groups.map((group: Group) => renderGroup(group))}
          </ul>

          {Boolean(
            subscribers_bonus || allowed_regions || promocode_platforms.length
          ) && (
            <BtnBordered
              className="mb-3"
              link={bonusesLink}
              text={t('bonus_where_application')}
            />
          )}
        </Container>
      </PageContent>
    </div>
  );
};

export default ProjectOffers;
