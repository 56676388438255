import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  RequestPromoFromBloggerDataSchema,
  RequestSpecialPromoDataSchema,
  SendPromocodesToBotParams
} from '../../api/models/promocodes';
import { AppDispatch } from '../index';
import { addUserPromocode, updateProjects } from '../slices/projectsSlice';
import { resolveLoggerInstance } from '../../helpers/logger';
import {
  requestPromocode,
  requestSubscriberPromocode,
  sendPromocodes
} from '../../api/promocodes';

interface RequestPromoParams {
  userId?: number;
  landingId: number | null;
  projectId: number | string;
}

export const reqPromo = createAsyncThunk<
  RequestPromoFromBloggerDataSchema,
  RequestPromoParams,
  { rejectValue: Error; dispatch: AppDispatch }
>(
  'promocodes/reqPromo',
  async ({ userId, landingId, projectId }, { rejectWithValue, dispatch }) => {
    try {
      const response = await requestPromocode({ userId, landingId, projectId });
      const { success, data } = response;
      if (success && data && data.length) {
        dispatch(updateProjects(data));
      }

      return response;
    } catch (e) {
      return rejectWithValue(e as Error);
    }
  }
);

interface RequestSpecialPromoParams {
  userId?: number;
  landingId: number | null;
  projectId: number | string;
}

export const reqSubPromo = createAsyncThunk<
  RequestSpecialPromoDataSchema,
  RequestSpecialPromoParams,
  { rejectValue: Error; dispatch: AppDispatch }
>(
  'promocodes/reqSubPromo',
  async ({ userId, landingId, projectId }, { rejectWithValue, dispatch }) => {
    const logger = resolveLoggerInstance('reqSubPromo');

    try {
      const response = await requestSubscriberPromocode({
        userId,
        landingId,
        projectId
      });
      const { success, data: promocode } = response;
      if (success && promocode) {
        dispatch(addUserPromocode(promocode));
      }
      return { success };
    } catch (e) {
      logger.error(e);
      return rejectWithValue(e as Error);
    }
  }
);

export const sendPromos = createAsyncThunk<
  any,
  SendPromocodesToBotParams,
  { rejectValue: Error }
>('promocodes/sendPromos', async (data, { rejectWithValue }) => {
  try {
    await sendPromocodes(data);
  } catch (e) {
    return rejectWithValue(e as Error);
  }
});
