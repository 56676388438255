import React, { FC } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PATHS } from '../../const';
import { Category } from '../../models/state';
import { apiKey } from '../../helpers/api';

import '../categories-list/categories-list.scss';

interface CategoriesListItemProps {
  category: Category;
  onCategoryClick: (name: string, id: number | null) => void;
}

const CategoriesListItem: FC<CategoriesListItemProps> = ({ category, onCategoryClick }) => {
  return (
    <li className="categories-list__item">
      <Container>
        <Link
          to={`${PATHS.projects}?key=${apiKey}`}
          className="categories-list__button"
          onClick={() => onCategoryClick(category.name, category.id)}
        >
          {category.name}
        </Link>
      </Container>
      <div className="line"></div>
    </li>
  );
};

export default CategoriesListItem;
