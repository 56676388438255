import React from 'react';

interface Props {
  color?: string;
  className?: string;
}

const CopyIcon = ({ color = '#000000', className = '' }: Props) => {
  return (
    <>
      <svg
        className={className}
        width="18"
        height="22"
        viewBox="0 0 18 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ color: color }}
      >
        <path
          d="M0.508301 18.8345C0.508301 20.5693 1.36328 21.4409 3.08154 21.4409H10.8843C12.6025 21.4409 13.4575 20.561 13.4575 18.8345V17.2988H14.9102C16.6201 17.2988 17.4834 16.4189 17.4834 14.6924V7.95215C17.4834 6.93115 17.2759 6.28369 16.6533 5.64453L12.4614 1.38623C11.8721 0.780273 11.1748 0.556152 10.2866 0.556152H7.10742C5.39746 0.556152 4.53418 1.43604 4.53418 3.1626V4.69824H3.08154C1.37158 4.69824 0.508301 5.56982 0.508301 7.30469V18.8345ZM12.6689 10.1436L8.09521 5.48682C7.46436 4.83936 6.94141 4.69824 6.01172 4.69824H5.87061V3.1875C5.87061 2.36572 6.31055 1.89258 7.17383 1.89258H10.8428V6.15088C10.8428 7.11377 11.3076 7.57031 12.2622 7.57031H16.147V14.6675C16.147 15.4976 15.6987 15.9624 14.8354 15.9624H13.4575V12.3516C13.4575 11.2891 13.333 10.8242 12.6689 10.1436ZM12.0464 5.95166V2.54834L15.7983 6.3667H12.4531C12.1626 6.3667 12.0464 6.24219 12.0464 5.95166ZM1.84473 18.8096V7.32129C1.84473 6.50781 2.28467 6.03467 3.14795 6.03467H5.87061V10.8491C5.87061 11.895 6.40186 12.418 7.43115 12.418H12.1211V18.8096C12.1211 19.6396 11.6729 20.1045 10.8179 20.1045H3.13965C2.28467 20.1045 1.84473 19.6396 1.84473 18.8096ZM7.58887 11.1646C7.25684 11.1646 7.12402 11.0317 7.12402 10.6997V6.3418L11.8638 11.1646H7.58887Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default CopyIcon;
