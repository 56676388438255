import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ContestInfo,
  ContestList,
  ContestParticipantInfo
} from '../../api/models/contests';
import {
  banUnbanContestUserRequest,
  createContestRequest,
  fetchContestInfo,
  fetchContestList,
  fetchContestParticipantInfo,
  finishContestRequest,
  sendMessage,
  sendResults,
  updateContestRequest
} from '../thunk/ContestThunk';

interface ContestsState {
  contestsList: ContestList;
  contestsInfo: ContestInfo[];
  loadingContests: boolean;
  error?: string;
  bannedUsers: string[];
  contestIsEndedManually: boolean | null;
  contestParticipants: (ContestParticipantInfo & { contest_id: number })[];
  contestListIsRequested: boolean;
}

const initialState: ContestsState = {
  contestsList: [],
  contestsInfo: [],
  loadingContests: false,
  error: '',
  bannedUsers: [],
  contestIsEndedManually: null,
  contestParticipants: [],
  contestListIsRequested: false
};

export const contestsSlice = createSlice({
  name: 'contests',
  initialState,
  reducers: {
    changeUserBanStatus(
      state,
      action: PayloadAction<{ participant_chat_id: string; setBanned: boolean }>
    ) {
      const referralIndex = state.contestParticipants.findIndex(
        (participant) =>
          participant.chat_id === Number(action.payload.participant_chat_id)
      );
      state.contestParticipants[referralIndex].is_banned =
        action.payload.setBanned;
    },
    setIsContestEndedManually(state, action: PayloadAction<boolean | null>) {
      state.contestIsEndedManually = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContestList.pending, (state) => {
      state.loadingContests = true;
    });
    builder.addCase(fetchContestList.fulfilled, (state, action) => {
      state.contestsList = action.payload;
      state.contestListIsRequested = true;
      state.loadingContests = false;
    });
    builder.addCase(fetchContestList.rejected, (state, action) => {
      state.error = action.payload?.message;
      state.loadingContests = false;
    });
    builder.addCase(fetchContestInfo.pending, (state) => {
      state.loadingContests = true;
    });
    builder.addCase(fetchContestInfo.fulfilled, (state, action) => {
      state.contestsInfo.push(action.payload);
      state.loadingContests = false;
    });
    builder.addCase(fetchContestInfo.rejected, (state, action) => {
      state.error = action.payload?.message;
      state.loadingContests = false;
    });
    builder.addCase(fetchContestParticipantInfo.pending, (state) => {
      state.loadingContests = true;
    });
    builder.addCase(fetchContestParticipantInfo.fulfilled, (state, action) => {
      state.contestParticipants.push(action.payload);
      state.loadingContests = false;
    });
    builder.addCase(fetchContestParticipantInfo.rejected, (state, action) => {
      state.error = action.payload?.message;
      state.loadingContests = false;
    });
    builder.addCase(sendMessage.pending, (state) => {
      state.loadingContests = true;
    });
    builder.addCase(sendMessage.fulfilled, (state) => {
      state.loadingContests = false;
    });
    builder.addCase(sendMessage.rejected, (state, action) => {
      state.error = action.payload?.message;
      state.loadingContests = false;
    });
    builder.addCase(sendResults.pending, (state) => {
      state.loadingContests = true;
    });
    builder.addCase(sendResults.fulfilled, (state) => {
      state.loadingContests = false;
    });
    builder.addCase(sendResults.rejected, (state, action) => {
      state.error = action.payload?.message;
      state.loadingContests = false;
    });
    builder.addCase(createContestRequest.pending, (state) => {
      state.loadingContests = true;
    });
    builder.addCase(createContestRequest.fulfilled, (state, action) => {
      const { success, message, ...contest } = action.payload;
      state.contestsList.unshift(contest);
      state.loadingContests = false;
    });
    builder.addCase(createContestRequest.rejected, (state, action) => {
      state.error = action.payload?.message;
      state.loadingContests = false;
    });
    builder.addCase(updateContestRequest.pending, (state) => {
      state.loadingContests = true;
    });
    builder.addCase(updateContestRequest.fulfilled, (state, action) => {
      const { success, ...contest } = action.payload;
      const contestInfoIndex = state.contestsInfo.findIndex(
        (c) => c.id === contest.id
      );
      const contestListIndex = state.contestsList.findIndex(
        (c) => c.id === contest.id
      );
      state.contestsInfo[contestInfoIndex] = {
        ...state.contestsInfo[contestInfoIndex],
        ...contest
      };
      state.contestsList[contestListIndex] = {
        ...state.contestsList[contestListIndex],
        id: contest.id,
        started_at: contest.started_at,
        finished_at: contest.finished_at,
        is_finished: contest.is_finished
      };
      state.loadingContests = false;
    });
    builder.addCase(updateContestRequest.rejected, (state, action) => {
      state.error = action.payload?.message;
      state.loadingContests = false;
    });
    builder.addCase(banUnbanContestUserRequest.pending, (state) => {
      state.loadingContests = true;
    });
    builder.addCase(banUnbanContestUserRequest.fulfilled, (state, action) => {
      state.contestsInfo = state.contestsInfo
        .filter((contest) => contest.id === action.payload.contest_id)
        .map((contest) => ({
          ...contest,
          banned_users: action.payload.banned_users,
          results: action.payload.results
        }));
      state.loadingContests = false;
    });
    builder.addCase(banUnbanContestUserRequest.rejected, (state, action) => {
      state.loadingContests = false;
      state.error = action.payload?.message;
    });
    builder.addCase(finishContestRequest.pending, (state) => {
      state.loadingContests = true;
    });
    builder.addCase(finishContestRequest.fulfilled, (state, action) => {
      const { success, message, participant_count, ...contest } =
        action.payload;
      const contestInfoIndex = state.contestsInfo.findIndex(
        (c) => c.id === contest.id
      );
      const contestListIndex = state.contestsList.findIndex(
        (c) => c.id === contest.id
      );
      if (participant_count > 0) {
        state.contestsInfo[contestInfoIndex] = {
          ...state.contestsInfo[contestInfoIndex],
          is_finished: true,
          ...contest
        };
        state.contestsList[contestListIndex] = {
          ...state.contestsList[contestListIndex],
          is_finished: true,
          ...contest
        };
      } else {
        state.contestsInfo.splice(contestInfoIndex, 1);
        state.contestsList.splice(contestListIndex, 1);
      }
      state.loadingContests = false;
    });
    builder.addCase(finishContestRequest.rejected, (state, action) => {
      state.error = action.payload?.message;
      state.loadingContests = false;
    });
  }
});

export const { changeUserBanStatus, setIsContestEndedManually } =
  contestsSlice.actions;

export default contestsSlice.reducer;
