import axios, { AxiosError } from 'axios';
import { apiKey } from '../helpers/api';
import { config } from '../config';

declare module 'axios' {
  export interface AxiosRequestConfig {
    retry?: number;
    retryDelay?: number;
    isRetry?: boolean;
  }
}

export const api = axios.create({
  baseURL: config.apiHost
});

api.interceptors.request.use((config) => {
  config.params = { ...config.params, key: apiKey };
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (err: AxiosError) => {
    const { config, message } = err;

    if (!config || !config.retry) {
      return Promise.reject(err);
    }

    if (!(message.includes('timeout') || message.includes('Network Error'))) {
      return Promise.reject(err);
    }

    config.isRetry = true;
    config.retry -= 1;

    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, config.retryDelay || 1000);
    });

    await delayRetryRequest;
    return await api(config);
  }
);
