import React, { ReactNode } from 'react';
import './custom-card.scss';

interface Props {
  className?: string;
  children: ReactNode;
}

const CustomCard = ({ className = '', children }: Props) => {
  return <div className={`custom-card ${className}`}>{children}</div>;
};

export default CustomCard;
