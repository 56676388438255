import { z } from 'zod';

export const analyticSchema = z.object({
  date: z.string(),
  isDistribution: z.boolean(),
  uniqueUsers: z.number(),
  newUsers: z.number(),
  widgetLaunch: z.number(),
  projectView: z.number(),
  promoCopy: z.number(),
  redirect: z.number()
});

export const analyticDataSchema = z.object({
  success: z.boolean(),
  data: z.array(analyticSchema)
});

export type AnalyticData = z.infer<typeof analyticDataSchema>;
export type AnalyticDataItem = z.infer<typeof analyticSchema>;
