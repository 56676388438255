import cn from 'classnames';
import styles from './contest-end-date-picker.module.scss';
import format from 'date-fns/format';
import { IoCalendarOutline } from 'react-icons/io5';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Dispatch, SetStateAction, useState } from 'react';
import ru from 'date-fns/locale/ru';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('ru', ru);

interface ContestEndDatePickerProps {
  dateValue: Date | null;
  setDateValue: Dispatch<SetStateAction<Date | null>>;
  error?: string;
}

const ContestEndDatePicker = ({
  dateValue,
  setDateValue,
  error
}: ContestEndDatePickerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const onDateWrapperClick = () => {
    setIsOpen((s) => !s);
  };

  const onDateChange = (date: Date | null) => {
    setDateValue(date);
    setIsOpen(false);
  };

  const now = new Date();
  const minDate = new Date(now.setDate(now.getDate() + 1));
  const maxDate = new Date(now.setDate(now.getDate() + 14));

  return (
    <div>
      <div className={cn(styles.title, styles.titleDate)}>
        Завершение конкурса
      </div>
      <div
        className={cn(styles.dateWrapper, { [styles.errorWrapper]: error })}
        onClick={onDateWrapperClick}>
        <div className={cn(styles.dateValue, { [styles.active]: dateValue })}>
          {dateValue ? format(dateValue as Date, 'dd.MM.yy') : 'ДД.ММ.ГГ'}
        </div>
        <IoCalendarOutline />
      </div>
      {isOpen && (
        <DatePicker
          onChange={onDateChange}
          className={styles.datepicker}
          locale="ru"
          selected={dateValue}
          minDate={minDate}
          maxDate={maxDate}
          inline
        />
      )}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default ContestEndDatePicker;
