import { AnalyticDataItem } from '../../api/models/analytic';
import { createSlice } from '@reduxjs/toolkit';
import { fetchAnalyticData } from '../thunk/AnalyticThunk';

interface AnalyticState {
  analyticData: AnalyticDataItem[];
  loadingAnalytic: boolean;
  error?: string;
}

const initialState: AnalyticState = {
  analyticData: [],
  loadingAnalytic: false,
  error: ''
};

export const analyticSlice = createSlice({
  name: 'analytic',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAnalyticData.pending, (state) => {
      state.loadingAnalytic = true;
    });
    builder.addCase(fetchAnalyticData.fulfilled, (state, action) => {
      state.analyticData = action.payload.data;
      state.loadingAnalytic = false;
    });
    builder.addCase(fetchAnalyticData.rejected, (state, action) => {
      state.error = action.payload?.message;
      state.loadingAnalytic = false;
    });
  }
});

export default analyticSlice.reducer;
