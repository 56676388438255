import { isDev } from './common';
import { config } from '../config';

const Telegram = 'Telegram' in window ? window.Telegram : null;

class TelegramHelper {
  public WebApp = Telegram?.WebApp;
  public MainButton = Telegram?.WebApp.MainButton;
  public BackButton = Telegram?.WebApp.BackButton;
  public HapticFeedback = Telegram?.WebApp.HapticFeedback;
  public Chat = Telegram?.WebApp.initDataUnsafe.chat;
  public User = Telegram?.WebApp.initDataUnsafe.user;
  public Receiver = Telegram?.WebApp.initDataUnsafe.receiver;

  resolveUser() {
    if (isDev()) {
      if (!config.telegramUser) {
        throw new Error('определи REACT_APP_TELEGRAM_USER в .env');
      }
      return this.User?.id ?? Number(config.telegramUser);
    }

    return this.User?.id ?? this.Receiver?.id ?? this.Chat?.id;
  }

  getBotApiVersion() {
    return this.WebApp?.version
  }
}

export const TG = new Proxy(new TelegramHelper(), {
  get: (target, key: keyof typeof TelegramHelper) => {
    if (!Telegram) {
      console.error('Telegram is not defined!');
    }

    return key in target ? target[key] : console.error('Telegram error!');
  }
});
