import { Container } from 'react-bootstrap';
import ContestReferralStatBlock from '../../../components/contest-referral-stat-block/contest-referral-stat-block';
import { useGetAntiFraudStat } from '../../../hooks/useGetAntiFraudStat';
import { useEffect } from 'react';
import { TG } from '../../../helpers/telegram';
import PageHeader from '../../../components/page-header/page-header';
import { useLoadContest } from '../../../hooks/useLoadContest';
import Loading from '../../loading/loading';

const ContestStatPage = () => {
  const { contest, loading } = useLoadContest();
  const { subsCount, antiFraudInfo } = useGetAntiFraudStat();

  useEffect(() => {
    TG.BackButton?.show();

    return () => {
      TG.BackButton?.hide();
    };
  }, []);

  if (loading) return <Loading />;
  if (!contest) return <Container>Нет информации о данном конкурсе</Container>;

  return (
    <>
      <PageHeader title="Статистика" />
      <Container>
        <div className="contest-referral-stat-blocks">
          {antiFraudInfo.map((i, index) => (
            <ContestReferralStatBlock
              key={index}
              allUserReferrals={subsCount}
              {...i}
            />
          ))}
        </div>
      </Container>
    </>
  );
};

export default ContestStatPage;
