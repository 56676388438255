import { Project } from '../models/project';
import { Group } from '../models/group';

export const isPromoRequestByBonusesAllow = (project: Project) => {
  return !!(
    project.subscribers_bonuses?.length &&
    project.groups.length === 1 &&
    project.groups[0].can_request &&
    !project.groups[0].landing &&
    !project.groups[0].request_sent_at
  );
};

export const groupHasOffers = (group: Group) => {
  return !!(
    (group.promocodes && group.promocodes.length) ||
    (group.links_for_subscribers && group.links_for_subscribers.length)
  );
};

export const projectHasOffers = (project: Project) => {
  return !!project.groups.find((group) => {
    return groupHasOffers(group);
  });
};
