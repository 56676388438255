import { z } from 'zod';

export const promocodeSchema = z.object({
  id: z.union([z.string(), z.number()]),
  group: z.string().nullable(),
  project_id: z.union([z.string(), z.number()]),
  landing_id: z.number().nullable(),
  code: z.string(),
  is_barcode: z.boolean(),
  show_barcode_text: z.boolean(),
  image: z.string(),
  comment: z.string(),
  date: z.string(),
  ord: z.string().nullable(),
  is_hit: z.boolean(),
  is_universal: z.boolean().nullable().optional()
});

export type Promocode = z.infer<typeof promocodeSchema>;
