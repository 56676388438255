export enum colorSchemes {
  light = 'light',
  dark = 'dark'
}

export enum YMEvents {
  copy_promo = 'copy_promo',
  send_message = 'send_message',
  api_error = 'api_error',
  ext_link = 'extLink',
  set_user_id = 'setUserID',
  view_project = 'view_project',
  view_category = 'view_category',
  grab_offer = 'grab_offer'
}

export enum GrabEvents {
  copy = 'Copy',
  redirect = 'Redirect',
  copy_redirect = 'Copy and redirect',
  request = 'Request',
  request_user_promo = 'Request User Promocode'
}

export enum COOKIE_NAME {
  api_key = 'key',
  requested_promos = 'requested_promos'
}
