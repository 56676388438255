import { GrabEvents, YMEvents } from '../@types/enums';
import { GrabOfferParams } from '../@types/hepler-types';
import { isProd } from '../helpers/common';
import { TG } from '../helpers/telegram';
import { YM_KEY } from '../const';
import { useMemo } from 'react';
import { resolveLoggerInstance } from '../helpers/logger';

interface GrabOfferDataType {
  grab_offer: {
    project_id: number | string;
    project_name: string;
    event_type: Record<GrabEvents, string | null>;
    offer: {
      landing_id: string;
      promocode_group?: string;
    };
  };
}

interface ErrorLogParams {
  url: string | null;
  message: string;
  error_id: string | null;
}

interface ErrorLogDataType {
  error_log: Record<string, ErrorLogParams>;
}

export const useYM = () => {
  const logger = resolveLoggerInstance('YM');
  const ym = useMemo(() => {
    const originalYM =
      'ym' in window && typeof window.ym === 'function'
        ? window.ym
        : () => {
            console.error('ym not installed');
          };

    return (...params: unknown[]) => {
      logger.log(params);
      if (isProd()) {
        originalYM(...params);
      }
    };
  }, []);

  return {
    key: YM_KEY,
    init: () => {
      ym(YM_KEY, 'init', {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true
      });
    },
    sendParams: (params: object) => {
      ym(YM_KEY, 'params', params);
    },
    sendUserParams: (params: object) => {
      ym(YM_KEY, 'userParams', params);
    },
    copy: (projectId: number | string) => {
      const data = {
        project_id: projectId
      };

      ym(YM_KEY, 'reachGoal', YMEvents.copy_promo, data);
    },
    sendMessage: (projectId: number | string, projectName: string) => {
      const data = {
        send_message: {
          project_id: projectId,
          project_name: projectName
        }
      };

      ym(YM_KEY, 'reachGoal', YMEvents.send_message, data);
    },
    setUserId: (userId: number | null) => {
      ym(YM_KEY, YMEvents.set_user_id, String(userId));
    },
    viewProject: (projectId: number | string, projectName: string) => {
      const data = {
        view_project: {
          project_id: projectId,
          project_name: projectName
        }
      };

      ym(YM_KEY, 'reachGoal', YMEvents.view_project, data);
    },
    viewCategory: (categoryId: number | null, categoryName: string) => {
      const data = {
        view_category: {
          category_id: categoryId,
          category_name: categoryName
        }
      };

      ym(YM_KEY, 'reachGoal', YMEvents.view_category, data);
    },
    grabOffer: (params: GrabOfferParams) => {
      const { projectId, projectName, landingId, promoGroup, eventType } =
        params;

      const data: GrabOfferDataType = {
        grab_offer: {
          project_id: projectId,
          project_name: projectName,
          event_type: {} as Record<GrabEvents, string | null>,
          offer: {
            landing_id: `id${projectId}_${landingId}`
          }
        }
      };
      if (promoGroup) {
        data.grab_offer.offer[
          'promocode_group'
        ] = `id${projectId}_${promoGroup}`;
        data.grab_offer.event_type[
          `${eventType}`
        ] = `id${projectId}_${promoGroup}`;
      } else {
        data.grab_offer.event_type[`${eventType}`] = `id${projectId}`;
      }

      ym(YM_KEY, 'reachGoal', YMEvents.grab_offer, data);
    },
    errorLog: (
      url: string | undefined,
      user_id: number | undefined,
      message: string,
      error_id?: string
    ) => {
      const data: ErrorLogDataType = {
        error_log: {}
      };
      data.error_log[String(user_id ?? null)] = {
        url: url ?? null,
        message,
        error_id: error_id ?? null
      };

      ym(YM_KEY, 'reachGoal', YMEvents.api_error, data);
    },
    initYm(userId: number | undefined) {
      this.init();
      this.setUserId(userId ?? null);

      // send userParams to Yandex metric
      this.sendUserParams({
        UserID: userId ? String(userId) : null, // required
        user_id: userId ? String(userId) : null,
        user_language_code: TG.User?.language_code
      });

      // send visit params to Yandex Metric
      this.sendParams({
        user_id: userId ? String(userId) : null
      });
    }
  };
};
