import React from 'react';

interface Props {
  color?: string;
  className?: string;
}

const ScreenIcon = ({ color = '#000000', className = '' }: Props) => {
  return (
    <>
      <svg
        className={className}
        width="32"
        height="25"
        viewBox="0 0 32 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ color: color }}
      >
        <path
          d="M3.50781 20.5811H28.4922C30.4473 20.5811 31.4629 19.5654 31.4629 17.6104V2.98535C31.4629 1.03027 30.4473 0.0146484 28.4922 0.0146484H3.50781C1.55273 0.0146484 0.537109 1.03027 0.537109 2.98535V17.6104C0.537109 19.5654 1.55273 20.5811 3.50781 20.5811ZM3.5459 19.0068C2.59375 19.0068 2.11133 18.5371 2.11133 17.585V3.01074C2.11133 2.05859 2.59375 1.58887 3.5459 1.58887H28.4541C29.4062 1.58887 29.8887 2.05859 29.8887 3.01074V17.585C29.8887 18.5371 29.4062 19.0068 28.4541 19.0068H3.5459ZM16.6221 15.7061C16.8252 15.7061 16.9521 15.5664 17.0029 15.3633C17.6758 11.1865 18.0059 10.6787 22.2207 10.1201C22.4365 10.0947 22.5635 9.95508 22.5635 9.75195C22.5635 9.54883 22.4365 9.39648 22.2207 9.37109C18.0186 8.8125 17.6123 8.29199 17.0029 4.14062C16.9648 3.9375 16.8252 3.79785 16.6221 3.79785C16.4062 3.79785 16.2666 3.9248 16.2158 4.14062C15.5049 8.34277 15.2129 8.8125 11.0107 9.37109C10.7949 9.39648 10.6553 9.54883 10.6553 9.75195C10.6553 9.95508 10.7949 10.0947 11.0107 10.1201C15.2383 10.3867 15.6572 11.1865 16.2158 15.3506C16.2539 15.5664 16.3936 15.7061 16.6221 15.7061ZM11.5566 17.6865C11.6836 17.6865 11.7852 17.5977 11.8105 17.4707C12.1279 15.6172 12.0391 15.4141 14.1592 15.1221C14.2988 15.0967 14.3877 14.9951 14.3877 14.8555C14.3877 14.7158 14.2988 14.6016 14.1592 14.5889C12.0518 14.2842 12.1279 14.0684 11.8105 12.2656C11.7852 12.1133 11.6963 12.0117 11.5566 12.0117C11.417 12.0117 11.3154 12.1006 11.2773 12.2656C10.9727 14.0684 11.0742 14.2842 8.94141 14.5889C8.80176 14.6016 8.71289 14.7158 8.71289 14.8555C8.71289 15.0078 8.80176 15.0967 8.9541 15.1221C11.0742 15.4141 10.9727 15.6172 11.2773 17.4453C11.3154 17.5977 11.4043 17.6865 11.5566 17.6865ZM9.75391 24.4531H22.2461C22.6777 24.4531 23.0332 24.0977 23.0332 23.666C23.0332 23.2217 22.6777 22.8662 22.2461 22.8662H9.75391C9.30957 22.8662 8.9541 23.2217 8.9541 23.666C8.9541 24.0977 9.30957 24.4531 9.75391 24.4531Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default ScreenIcon;
