import {
  RequestPromoFromBloggerDataSchema,
  requestPromoFromBloggerDataSchema,
  requestPromoFromBloggerParamsSchema,
  requestSpecialPromoDataSchema,
  RequestSpecialPromoDataSchema,
  requestSpecialPromoParamsSchema,
  SendPromocodesToBotParams,
  sendPromocodesToBotParamsSchema
} from './models/promocodes';
import { api } from './api';

const BASE_URL = '/blogger/promocode-api';

export const sendPromocodes = (data: SendPromocodesToBotParams) => {
  const { projectId, userId } = sendPromocodesToBotParamsSchema.parse(data);

  return api.post(
    `${BASE_URL}/bot-send-promos`,
    {},
    {
      params: {
        chat_id: userId,
        project_id: projectId
      }
    }
  );
};

export const requestPromocode = async (data: {
  userId?: number;
  landingId: number | null;
  projectId: number | string;
}) => {
  const { userId, landingId, projectId } =
    requestPromoFromBloggerParamsSchema.parse(data);

  const response = await api.get<RequestPromoFromBloggerDataSchema>(
    `${BASE_URL}/bot-request-project`,
    {
      params: {
        chat_id: userId,
        project_id: projectId,
        landing_id: landingId
      }
    }
  );

  return requestPromoFromBloggerDataSchema.parse(response.data);
};

export const requestSubscriberPromocode = async (data: {
  userId?: number;
  projectId: number | string;
  landingId: number | null;
}) => {
  const { projectId, userId, landingId } =
    requestSpecialPromoParamsSchema.parse(data);

  const response = await api.get<RequestSpecialPromoDataSchema>(
    `${BASE_URL}/bot-request-subscriber-code`,
    {
      params: {
        chat_id: userId,
        project_id: projectId,
        landing_id: landingId
      }
    }
  );

  return requestSpecialPromoDataSchema.parse(response.data);
};

type SendErrorProps = {
  url: string | undefined;
  chat_id: number | null | undefined;
  message: string;
  error_id?: string;
  code?: string;
  status?: number;
  cause?: string;
  request?: string;
  additionalData?: unknown;
};
export const sendError = (sendErrorProps: SendErrorProps) => {
  // if (!sendErrorProps.url || !sendErrorProps.chat_id) {
  //   return;
  // }

  return api.post(`${BASE_URL}/bot-warning`, sendErrorProps);
};
