import React, { useEffect, useRef } from 'react';
import { PATHS, PATHS_REGEXP } from '../../const';
import { TG } from '../../helpers/telegram';
import {
  setSearchProjectValue,
  setShowProjectsNav
} from '../../store/slices/appStateSlice';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getIdFromPath, isTest } from '../../helpers/common';
import { apiKey } from '../../helpers/api';
import { setSelectedCategory } from '../../store/slices/appStateSlice';

const TelegramBackButton = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedCategory } = useAppSelector((state) => state.appState);
  const selectedCategoryRef = useRef(selectedCategory);

  const backButtonClickHandler = () => {
    const pathname = window.location.pathname;

    if (pathname === PATHS.projects) {
      if (selectedCategoryRef.current) {
        navigate(`${PATHS.categories}?key=${apiKey}`);
        TG.BackButton?.hide();
      }
      dispatch(setShowProjectsNav(true));
      dispatch(setSearchProjectValue(''));

      return;
    }
    if (pathname === PATHS.categories) {
      if (selectedCategoryRef.current) {
        dispatch(setSelectedCategory(null));
      }
      navigate(`${PATHS.projects}?key=${apiKey}`);
      TG.BackButton?.hide();

      return;
    }
    if (pathname?.includes('/offers')) {
      TG.BackButton?.hide();
      TG.MainButton?.hide();
      navigate(`${PATHS.projects}?key=${apiKey}`);

      return;
    }
    if (pathname?.includes('/promo/')) {
      navigate(-1);

      return;
    }

    if (PATHS_REGEXP.contestAdmin.test(pathname)) {
      navigate(`${PATHS.contests}?key=${apiKey}`);
      return;
    }

    if (PATHS_REGEXP.contestChangeEndDateAdmin.test(pathname)) {
      const id = getIdFromPath(pathname);
      id ? navigate(`/contest/${id}?key=${apiKey}`) : navigate(-1);

      return;
    }

    navigate(-1);
  };

  useEffect(() => {
    selectedCategoryRef.current = selectedCategory;
  }, [selectedCategory]);

  useEffect(() => {
    TG.BackButton?.onClick(backButtonClickHandler);
  }, []);

  return (
    <>
      {JSON.parse(isTest() || 'false') && (
        <>
          <button
            className="btn btn-sm btn-secondary shadow"
            type="button"
            onClick={backButtonClickHandler}
            style={{
              position: 'fixed',
              left: 0,
              top: 0,
              zIndex: 100
            }}>
            Назад
          </button>
        </>
      )}
    </>
  );
};

export default TelegramBackButton;
