import { type ProjectsState } from './models/state';

export const YM_KEY = 93455748;

export const PATHS = {
  main: '/',
  categories: '/categories',
  projects: '/projects',
  project: '/project/:id',
  projectOffers: '/project/:id/offers',
  projectAbout: '/project/:id/about',
  projectBonuses: '/project/:id/bonuses',
  promoDetail: '/promo/:id',
  error: '/error',
  contests: '/contests',
  contest: '/contest/:id?',
  contestPreview: '/contest/:id?/preview',
  contestConditions: '/contest/:id?/conditions',
  contestSendMessage: '/contest/:id?/referral-stat/:chat_id?/send-message',
  contestMyReferrals: '/contest/:id?/my-referrals',
  contestReferralStat: '/contest/:id?/referral-stat/:chat_id?',
  contestLaunch: '/contest-launch',
  contestChangeEndDate: '/contest/:id?/change-end-date',
  contestLaunchSuccessfully: '/contest/:id?/contest-launch-successfully',
  contestStatPage: '/contest/:id?/contest-stat',
  analytics: '/analytics',
  notFound: '*'
};

export const PATHS_REGEXP = {
  contestAdmin: new RegExp('^/contest/[0-9]+$'),
  contestChangeEndDateAdmin: new RegExp('^/contest/[0-9]+/change-end-date$'),
  contestClient: new RegExp('^/contest$')
};

export const API_PATHS = {
  projects: '/blogger/promocode-api/bot-json',
  send_to_tg: '/blogger/promocode-api/bot-send-promos',
  locales: '/blogger/promocode-api/bot-locales',
  error: '/blogger/promocode-api/bot-warning'
};

export const TG_MAIN_BUTTON = {
  color: '#19D160',
  text_color: '#FFFFFF',
  disabledColor: '#dddcdc',
  disabledTextColorDate: '#04BA71',
  disabledButtonColorDate: '#76768014'
};

export const defaultProjectsState: ProjectsState = {
  items: [],
  categories: []
};
