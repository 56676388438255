import { z } from 'zod';
import { landingSchema } from './landing';
import { linkForSubscribersSchema } from './link-for-subscribers';
import { promocodeSchema } from './promocode';

export const groupSchema = z.object({
  landing: landingSchema.nullable(),
  links_for_subscribers: z.array(linkForSubscribersSchema).optional(),
  promocodes: z.array(promocodeSchema).optional(),
  tg_subscriber_codes: z.array(promocodeSchema).optional(),
  has_tg_subscribers_promo: z.boolean().optional(),
  subscribers_codes_count: z.number().optional(),
  can_request: z.boolean().optional(),
  request_sent_at: z.string().optional().nullable(),
  is_custom: z.boolean().optional()
});

export type Group = z.infer<typeof groupSchema>;
