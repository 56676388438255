import { Project } from '../models/project';
import { useEffect } from 'react';
import {
  projectHasOffers
  // isPromoRequestByBonusesAllow
} from '../helpers/promo';
import { TG } from '../helpers/telegram';
import { useYM } from './useYM';
import { useTranslation } from 'react-i18next';
import { userId } from '../helpers/api';
import { TG_MAIN_BUTTON } from '../const';
import { sendBotLogs } from '../api/log';

export const useViewProject = (project: Project) => {
  const promoOrLinksExists = projectHasOffers(project);
  const { t } = useTranslation();
  const YM = useYM();

  useEffect(() => {
    TG.BackButton?.show();

    if (userId) {
      if (promoOrLinksExists) {
        TG.MainButton?.show();
        TG.MainButton?.enable();
        TG.MainButton?.setParams({
          color: TG_MAIN_BUTTON.color
        });
        TG.MainButton?.setText(t('send_to_tg'));
      }

      // Main button request promos
      // else if (
      //   project.subscribers_bonuses?.length &&
      //   project.groups.length === 1
      // ) {
      //   const group = project.groups[0];
      //
      //   if (group.request_sent_at && !group.landing) {
      //     TG.MainButton?.setText(t('already_requested_promo'));
      //     TG.MainButton?.disable();
      //     TG.MainButton?.show();
      //     TG.MainButton?.setParams({
      //       color: TG_MAIN_BUTTON.disabledColor
      //     });
      //   } else if (isPromoRequestByBonusesAllow(project)) {
      //     TG.MainButton?.setText(t('request_promo'));
      //     TG.MainButton?.enable();
      //     TG.MainButton?.setParams({
      //       color: TG_MAIN_BUTTON.color
      //     });
      //     TG.MainButton?.show();
      //   }
      // }
    }
  });

  useEffect(() => {
    try {
      sendBotLogs({
        chat_id: userId,
        project_id: project.id,
        event_type: 'view_project'
      })
        .then(() => YM.viewProject(project.id, project.name))
        .catch((e) => {
          console.error(e);
        });
    } catch (e) {
      console.error(e);
    }
  }, [project]);
};
