import { z } from 'zod';
import { promocodeSchema } from '../../models/promocode';
import { dataItemSchema } from './data';

export const requestPromoFromBloggerParamsSchema = z.object({
  userId: z.number(),
  projectId: z.union([z.string(), z.number()]),
  landingId: z.number().nullable()
});

export const requestSpecialPromoParamsSchema = z.object({
  userId: z.number(),
  projectId: z.union([z.string(), z.number()]),
  landingId: z.number()
});

export const sendPromocodesToBotParamsSchema = z.object({
  projectId: z.union([z.string(), z.number()]),
  userId: z.number()
});

export const requestPromoFromBloggerDataSchema = z.object({
  success: z.boolean(),
  data: z.array(dataItemSchema).optional()
});

export const requestSpecialPromoDataSchema = z.object({
  success: z.boolean(),
  data: promocodeSchema.optional()
});

export type RequestSpecialPromoDataSchema = z.infer<
  typeof requestSpecialPromoDataSchema
>;
export type RequestPromoFromBloggerDataSchema = z.infer<
  typeof requestPromoFromBloggerDataSchema
>;
export type RequestPromoFromBloggerParams = z.infer<
  typeof requestPromoFromBloggerParamsSchema
>;
export type RequestSpecialPromoParams = z.infer<
  typeof requestSpecialPromoParamsSchema
>;
export type SendPromocodesToBotParams = z.infer<
  typeof sendPromocodesToBotParamsSchema
>;
