import styles from './analytics-filter-carousel.module.scss';
import { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { Filter } from '../../pages/analytics/analytics';
import cn from 'classnames';

interface AnalyticsFilterCarouselProps {
  carouselOptions?: EmblaOptionsType;
  filters: Filter[];
  carouselTitle: string;
  setFilterActive: (title: string) => void;
}

const AnalyticsFilterCarousel = ({
  filters,
  carouselOptions,
  carouselTitle,
  setFilterActive
}: AnalyticsFilterCarouselProps) => {
  const [emblaRef] = useEmblaCarousel(carouselOptions);

  return (
    <div className={styles.filter}>
      <div className={styles.title}>{carouselTitle}</div>
      <div className={styles.options} ref={emblaRef}>
        <div className={styles.optionsContainer}>
          {filters.map((filter) => (
            <div
              key={filter.title}
              className={cn(styles.option, {
                [styles.activeOption]: filter.active
              })}
              onClick={() => setFilterActive(filter.title as string)}>
              {filter.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnalyticsFilterCarousel;
