import PageHeader from '../page-header/page-header';
import { Container } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../hooks/redux';
import { apiKey } from '../../helpers/api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useState } from 'react';
import { TG } from '../../helpers/telegram';
import { localizeDate } from '../../helpers/date';

interface ContestInfoProps {
  referralLink: string | null;
  endDate: string;
  referralsCount: number;
  userPlace: number;
  isContestFinished?: boolean;
  isContestEndedManually?: boolean | null;
}

const ContestInfo = ({
  endDate,
  referralLink,
  referralsCount,
  userPlace,
  isContestFinished,
  isContestEndedManually
}: ContestInfoProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [copied, setCopied] = useState<boolean>(false);

  const onCopyButtonClick = () => {
    setCopied(true);
    TG.HapticFeedback?.notificationOccurred('success');
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const conditionsLink = `${pathname}/conditions?key=${apiKey}`;
  const myReferralsLink = `${pathname}/my-referrals?key=${apiKey}`;
  const changeEndDateLink = `${pathname}/change-end-date?key=${apiKey}`;

  const [localizedEndDate, localizedEndTime] = localizeDate(endDate);

  const isAdmin = useAppSelector((s) => s.projects.additionalData.isAdmin);

  const shouldNavigateToChangeEndDataPage =
    isAdmin &&
    (isContestFinished
      ? false
      : isContestEndedManually !== null
      ? !isContestEndedManually
      : true);

  const navigateToChangeEndDataPage = () => {
    navigate(changeEndDateLink);
  };

  return (
    <div className="contest-info-wrapper">
      <PageHeader title="Конкурс" />
      <Container>
        <div className="contest-info">
          <Link to={conditionsLink} className="contest-info__item">
            Условия
            <FontAwesomeIcon
              icon={faAngleRight}
              size="sm"
              className="contest-info__item__icon"
            />
          </Link>
          {!isAdmin && referralLink && (
            <div className="contest-info__item">
              <span>Пригласительная ссылка</span>

              <div className="copy-button-wrapper">
                <CopyToClipboard text={referralLink} onCopy={onCopyButtonClick}>
                  <button
                    className="copy-button"
                    disabled={copied}
                    style={{
                      pointerEvents: copied ? 'none' : 'auto'
                    }}>
                    {copied ? 'Скопировано' : 'Копировать'}
                  </button>
                </CopyToClipboard>
              </div>
            </div>
          )}
          <div
            className="contest-info__item"
            onClick={
              shouldNavigateToChangeEndDataPage
                ? navigateToChangeEndDataPage
                : undefined
            }>
            <span>Завершение</span>
            <span className="contest-info__item__value">
              {localizedEndDate} {localizedEndTime}
              {isAdmin && !isContestFinished && (
                <FontAwesomeIcon
                  icon={faAngleRight}
                  size="sm"
                  className="contest-info__item__icon"
                />
              )}
            </span>
          </div>
          {!isAdmin && !!referralsCount && (
            <>
              <Link to={myReferralsLink} className="contest-info__item">
                Приведенные мной
                <div className="referrals-count">
                  {referralsCount}
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    size="sm"
                    className="contest-info__item__icon"
                  />
                </div>
              </Link>
              {userPlace > 0 && (
                <div className="contest-info__item">
                  <span>Место в рейтинге</span>
                  <span className="contest-info__item__value">{userPlace}</span>
                </div>
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ContestInfo;
