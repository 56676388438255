import { TG } from '../helpers/telegram';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from './redux';
import { Landing } from '../models/landing';
import { reqSubPromo } from '../store/thunk/PromocodeThunk';
import { userId } from '../helpers/api';
import { resolveLoggerInstance } from '../helpers/logger';
import { useYM } from './useYM';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { ErrorDataResponse } from '../api/models/data';

export const useRequestSubscriberPromo = (
  projectId: number | string,
  landing: Landing | null
) => {
  const { t } = useTranslation();
  const isRequestingPromo = useAppSelector(
    (s) => s.promocode.isRequestingPromo
  );
  const dispatch = useAppDispatch();
  const YM = useYM();

  const logger = resolveLoggerInstance('useRequestSubscriberPromo');

  const requestUserPromoBtnClickHandler = async () => {
    const promoData = {
      project_id: projectId,
      landing_id: landing?.id ?? null
    };

    try {
      await dispatch(
        reqSubPromo({ userId, projectId, landingId: promoData.landing_id })
      ).unwrap();
      // await sendBotLogs({
      //   chat_id: userId,
      //   project_id: projectId,
      //   landing_id: landing ? landing.id : null,
      //   promo_group_id: null,
      //   event_type: 'request_user_promo'
      // });
    } catch (e) {
      if (e instanceof AxiosError) {
        logger.error(e);
        const xRequestId = e.response?.headers['x-request-id'];
        const responseDataError: ErrorDataResponse = e.response
          ?.data as ErrorDataResponse;
        if (e.response?.status === 500) {
          TG.WebApp?.showAlert(
            'Ой, что-то пошло не так\n\n' + t('error_id') + ': ' + xRequestId
          );
        } else {
          TG.WebApp?.showAlert(responseDataError.message);
        }
        YM.errorLog(e.config?.url, userId, e.message, xRequestId);
        Sentry.captureException(e);
      }
    }
  };

  return {
    isRequestingPromo,
    handleRequestUserPromo: requestUserPromoBtnClickHandler
  };
};
