import styles from './contest-launch-successfully.module.scss';
import { Container } from 'react-bootstrap';
import { useLoadContest } from '../../../hooks/useLoadContest';
import Loading from '../../loading/loading';
import cn from 'classnames';
import { calculateContestProgress, localizeDate } from '../../../helpers/date';
import { apiKey } from '../../../helpers/api';
import { PATHS } from '../../../const';

const ContestLaunchSuccessfully = () => {
  const { contest, loading, navigate } = useLoadContest();

  if (loading) return <Loading />;
  if (!contest) return <Container>Нет информации о данном конкурсе</Container>;

  const { remainingContestDuration, remainingContestNoun } =
    calculateContestProgress(
      new Date(contest.started_at),
      new Date(contest.finished_at)
    );

  const [localizedEndDate, localizedEndTime] = localizeDate(
    contest.finished_at
  );

  const redirectToContestsList = () => {
    navigate(`${PATHS.contests}?key=${apiKey}`, { replace: true });
  };

  return (
    <Container className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.title}>Конкурс запущен!</div>
        <div className={styles.textWrapper}>
          <div className={cn(styles.subtitle, styles.bold)}>
            Он продлится {remainingContestDuration} {remainingContestNoun} и
            завершится {localizedEndDate} {localizedEndTime}
          </div>
          <div className={styles.subtitle}>
            Вкладка с конкурсом уже доступна в боте всем подписчикам. Не забудь
            сделать рассылку с анонсом, чтобы подписчики поняли, что им нужно
            делать.
          </div>
        </div>
      </div>
      <div className={styles.button} onClick={redirectToContestsList}>
        К списку конкурсов
      </div>
    </Container>
  );
};

export default ContestLaunchSuccessfully;
