import React, { useEffect, useRef } from 'react';
import { TG } from '../../helpers/telegram';
import { projectHasOffers } from '../../helpers/promo';
import { sendError } from '../../api/promocodes';
import { useYM } from '../../hooks/useYM';
import { TG_MAIN_BUTTON } from '../../const';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useTranslation } from 'react-i18next';
import { isTest } from '../../helpers/common';
import { reqPromo, sendPromos } from '../../store/thunk/PromocodeThunk';
import { setLoading } from '../../store/slices/promocodeSlice';
import { userId } from '../../helpers/api';
import { setPromoAsRequested } from '../../store/slices/projectsSlice';
import { resolveLoggerInstance } from '../../helpers/logger';
import { Project } from '../../models/project';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { ErrorDataResponse } from '../../api/models/data';

const TelegramMainButton = (): JSX.Element => {
  const { currentProject } = useAppSelector((state) => state.projects);
  const isContestSendMessagePage = useAppSelector(
    (s) => s.appState.isContestSendMessagePage
  );
  const currentProjectRef = useRef(currentProject);
  const { t } = useTranslation();
  const YM = useYM();
  const dispatch = useAppDispatch();

  const sendPromoLogger = resolveLoggerInstance('sendPromos');
  const reqPromoLogger = resolveLoggerInstance('reqPromo');

  useEffect(() => {
    currentProjectRef.current = currentProject;
  }, [currentProject]);

  const catchError = (e: AxiosError) => {
    const xRequestId = e.response?.headers['x-request-id'];
    const responseDataError: ErrorDataResponse = e.response
      ?.data as ErrorDataResponse;
    if (e.response?.status === 500) {
      TG.WebApp?.showAlert(
        'Ой, что-то пошло не так\n\n' + t('error_id') + ': ' + xRequestId
      );
    } else {
      TG.WebApp?.showAlert(responseDataError.message);
    }
    sendError({
      url: e.config?.url,
      chat_id: userId,
      message: e.message,
      error_id: xRequestId
    });
    YM.errorLog(e.config?.url, userId, e.message, xRequestId);
    Sentry.captureException(e);
  };

  const sendPromosToTg = async (project: Project, userId: number) => {
    TG.MainButton?.hide();
    try {
      await dispatch(sendPromos({ projectId: project.id, userId })).unwrap();
      TG.WebApp?.close();
    } catch (e) {
      if (e instanceof AxiosError) {
        sendPromoLogger.error(e);
        catchError(e);
      }
    }
    YM.sendMessage(project.id, project.name);
  };

  const requestPromo = async (project: Project, userId: number) => {
    const promoData = {
      project_id: project.id,
      landing_id: null
    };

    TG.MainButton?.disable();
    TG.MainButton?.setText(t('requesting'));
    dispatch(setLoading(true));
    try {
      await dispatch(
        reqPromo({ userId, projectId: project.id, landingId: null })
      ).unwrap();
      dispatch(setLoading(false));
      dispatch(setPromoAsRequested(promoData));
      TG.WebApp?.showAlert(t('request_promo_success'));
      TG.MainButton?.setText(t('already_requested_promo'));
      TG.MainButton?.setParams({
        color: TG_MAIN_BUTTON.disabledColor
      });
      TG.MainButton?.disable();
    } catch (e) {
      if (e instanceof AxiosError) {
        reqPromoLogger.error(e);
        dispatch(setLoading(false));
        TG.MainButton?.setText(t('request_promo'));
        catchError(e);
      }
    }
  };

  const mainButtonClickHandler = async () => {
    const project = currentProjectRef.current;
    if (project && userId && !isContestSendMessagePage) {
      if (projectHasOffers(project)) {
        // Promos or links exists
        await sendPromosToTg(project, userId);
      }
      // else if (isPromoRequestByBonusesAllow(project)) {
      //   // Empty promos and links and bonuses available
      //   await requestPromo(project, userId);
      // }
    }
  };

  useEffect(() => {
    TG.MainButton?.setParams({
      color: TG_MAIN_BUTTON.color,
      text_color: TG_MAIN_BUTTON.text_color
    });
  }, []);

  useEffect(() => {
    TG.MainButton?.onClick(mainButtonClickHandler);
    return () => {
      TG.MainButton?.offClick(mainButtonClickHandler);
    };
  }, [isContestSendMessagePage]);

  return (
    <>
      {JSON.parse(isTest() || 'false') && (
        <>
          <button
            className="btn btn-md btn-success shadow"
            type="button"
            onClick={mainButtonClickHandler}
            style={{
              position: 'fixed',
              right: 0,
              bottom: 0,
              width: '100%',
              zIndex: 100
            }}>
            Main
          </button>
        </>
      )}
    </>
  );
};

export default TelegramMainButton;
