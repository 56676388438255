import { groupSchema } from './group';
import { z } from 'zod';

export const projectDescriptionSchema = z.object({
  id: z.union([z.string(), z.number()]),
  name: z.string(),
  activeBloggers: z.number(),
  category_id: z.number(),
  category_name: z.string(),
  logo: z.string(),
  subscribers_bonuses: z.array(z.string()).optional(),
  subscribers_bonus: z.string(),
  promocode_platforms: z.array(z.string()),
  product_info: z.string(),
  adv_info: z.string(),
  allowed_regions: z.string()
});

export const projectSchema = projectDescriptionSchema.extend({
  offersCount: z.number(),
  groups: z.array(groupSchema)
});

export type Project = z.infer<typeof projectSchema>;
