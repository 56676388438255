import { useEffect, useState } from 'react';
import { TG } from '../helpers/telegram';
import { useAppDispatch } from './redux';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import { createContestRequest } from '../store/thunk/ContestThunk';
import { apiKey, userId } from '../helpers/api';
import { updateContestsExistence } from '../store/slices/projectsSlice';
import { AxiosError } from 'axios';
import { ErrorDataResponse } from '../api/models/data';
import * as Sentry from '@sentry/react';
import { resolveLoggerInstance } from '../helpers/logger';
import { useYM } from './useYM';
import { useTranslation } from 'react-i18next';

interface Errors {
  conditions: string;
  expirationDate: string;
}

export const useCreateContest = () => {
  useEffect(() => {
    TG.MainButton?.show();
    TG.MainButton?.setText('Запустить конкурс');
    TG.BackButton?.show();

    return () => {
      TG.MainButton?.hide();
      TG.BackButton?.hide();
    };
  }, []);

  const [dateValue, setDateValue] = useState<null | Date>(null);
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const [errors, setErrors] = useState<Errors>({
    conditions: '',
    expirationDate: ''
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    TG.MainButton?.onClick(launchContestHandler);

    return () => {
      TG.MainButton?.offClick(launchContestHandler);
    };
  }, [dateValue, textAreaValue, errors]);

  const logger = resolveLoggerInstance('useCreateContest');
  const YM = useYM();
  const { t } = useTranslation();

  const launchContestHandler = async () => {
    const newErrors: Errors = {
      conditions: '',
      expirationDate: ''
    };

    if (!dateValue) {
      newErrors.expirationDate = 'Укажите дату окончания конкурса';
    }

    if (!textAreaValue) {
      newErrors.conditions = 'Укажите механику награждения';
    }

    setErrors(newErrors);

    if (!newErrors.conditions && !newErrors.expirationDate) {
      const formattedDate = format(dateValue as Date, 'yyyy-MM-dd');

      try {
        const { id } = await dispatch(
          createContestRequest({
            chat_id: userId,
            conditions: textAreaValue,
            expiration_date: formattedDate
          })
        ).unwrap();
        navigate(`/contest/${id}/contest-launch-successfully?key=${apiKey}`);
        dispatch(updateContestsExistence(true));
      } catch (e) {
        if (e instanceof AxiosError) {
          logger.error(e);
          const xRequestId = e.response?.headers['x-request-id'];
          const responseDataError: ErrorDataResponse = e.response
            ?.data as ErrorDataResponse;
          if (e.response?.status === 500) {
            TG.WebApp?.showAlert(
              'Ой, что-то пошло не так\n\n' + t('error_id') + ': ' + xRequestId
            );
          } else {
            TG.WebApp?.showAlert(responseDataError.message);
          }
          YM.errorLog(e.config?.url, userId, e.message, xRequestId);
          Sentry.captureException(e);
        }
      }
    }
  };

  return { dateValue, setDateValue, textAreaValue, setTextAreaValue, errors };
};
