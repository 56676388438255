import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface AppState {
  showProjectsNav: boolean;
  searchProjectValue: string;
  selectedCategory: string | null;
  isContestSendMessagePage: boolean;
}

const initialState: AppState = {
  showProjectsNav: true,
  searchProjectValue: '',
  selectedCategory: null,
  isContestSendMessagePage: false
};

export const appStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    setShowProjectsNav(state, action: PayloadAction<boolean>) {
      state.showProjectsNav = action.payload;
    },
    setSearchProjectValue(state, action: PayloadAction<string>) {
      state.searchProjectValue = action.payload;
    },
    setSelectedCategory(state, action: PayloadAction<string | null>) {
      state.selectedCategory = action.payload;
    },
    setIsContestSendMessagePage(state, action: PayloadAction<boolean>) {
      state.isContestSendMessagePage = action.payload;
    }
  }
});

export const {
  setShowProjectsNav,
  setSearchProjectValue,
  setSelectedCategory,
  setIsContestSendMessagePage
} = appStateSlice.actions;

export default appStateSlice.reducer;
