import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Project } from '../../models/project';
import { ProjectsState } from '../../models/state';
import { defaultProjectsState } from '../../const';
import { Promocode } from '../../models/promocode';
import { fetchProjects } from '../thunk/ProjectThunk';
import { RequestedPromo } from '../../@types/hepler-types';
import { DataItem, ErrorDataResponse } from '../../api/models/data';

interface IProjectsState {
  loadingProjects: boolean;
  error?: string;
  error_id?: string;
  projects: ProjectsState;
  currentProject: Project | null;
  additionalData: {
    hasContests: boolean;
    isAdmin: boolean;
    hasFinishedContests: boolean;
    botIsActive: boolean;
  };
}

const initialState: IProjectsState = {
  loadingProjects: false,
  error: '',
  error_id: '',
  projects: defaultProjectsState,
  currentProject: null,
  additionalData: {
    hasContests: false,
    isAdmin: false,
    hasFinishedContests: false,
    botIsActive: true
  }
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    updateProjects(state, action: PayloadAction<DataItem[]>) {
      if (action.payload.length) {
        state.projects.items = state.projects.items.map((p) => {
          const updatedProject = action.payload.find(
            (project) => project.project.id === p.id
          );

          if (updatedProject) {
            return {
              ...p,
              ...updatedProject.project,
              groups: updatedProject.groups,
              offersCount: updatedProject.groups.length
            };
          }

          return p;
        });
      }
    },
    addUserPromocode(state, action: PayloadAction<Promocode>) {
      const projectForUpdate = state.projects.items.find(
        (p) => p.id === action.payload.project_id
      );
      const userPromoCodeGroup = projectForUpdate?.groups.find(
        (g) => g.landing?.id === action.payload.landing_id
      );
      userPromoCodeGroup?.tg_subscriber_codes?.push(action.payload);
    },
    setPromoAsRequested(state, action: PayloadAction<RequestedPromo>) {
      const projectForUpdate = state.projects.items.find(
        (p) => p.id === action.payload.project_id
      );
      const landingId = action.payload.landing_id;
      let userPromoCodeGroup =
        landingId &&
        projectForUpdate?.groups.find(
          (group) => landingId === group.landing?.id
        );

      if (userPromoCodeGroup) {
        userPromoCodeGroup.request_sent_at = new Date().toLocaleString();
      } else if (projectForUpdate?.groups.length === 1) {
        userPromoCodeGroup = projectForUpdate?.groups[0];
        userPromoCodeGroup.request_sent_at = new Date().toLocaleString();
      }
    },
    setCurrentProject(state, action: PayloadAction<Project | null>) {
      state.currentProject = action.payload;
    },
    updateContestsExistence(state, action: PayloadAction<boolean>) {
      state.additionalData.hasContests = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProjects.pending, (state) => {
      state.loadingProjects = true;
    });
    builder.addCase(fetchProjects.fulfilled, (state, action) => {
      const data = action.payload.data;
      const categories: string[] = Array.from(
        new Set(data.map((item) => item.project.category_name))
      );
      const projects: Project[] = data.map((dataItem) => ({
        ...dataItem.project,
        groups: dataItem.groups,
        offersCount: dataItem.groups.length
      }));

      const categoriesWithProjects = categories
        .map((category) => {
          let id = null;
          data.find((project) => {
            if (project.project.category_name === category) {
              id = project.project.category_id;

              return true;
            }
          });

          return {
            id: id,
            name: category,
            projects_count: data.filter(
              (project) => project.project.category_name === category
            ).length
          };
        })
        .filter((category) => category.name);

      state.projects = {
        items: projects,
        categories: categoriesWithProjects
      };

      state.additionalData = {
        hasContests: action.payload.additionalData.has_contest,
        isAdmin: action.payload.additionalData.is_admin,
        hasFinishedContests: action.payload.additionalData.has_finished_contest,
        botIsActive: action.payload.additionalData.bot?.is_active ?? true
      };
      state.loadingProjects = false;
    });
    builder.addCase(fetchProjects.rejected, (state, action) => {
      const responseDataError: ErrorDataResponse = action.payload?.response
        ?.data as ErrorDataResponse;
      if (action.payload?.response?.status === 500) {
        state.error = 'Ой, что-то пошло не так';
        state.error_id = action.payload?.response?.headers['x-request-id'];
      } else {
        state.error = responseDataError.message;
      }
      state.loadingProjects = false;
    });
  }
});

export const {
  updateProjects,
  addUserPromocode,
  setPromoAsRequested,
  setCurrentProject,
  updateContestsExistence
} = projectsSlice.actions;

export default projectsSlice.reducer;
