import React from 'react';
import CustomCard from '../cards/custom-card';
import { nanoid } from 'nanoid';
import GiftIcon from '../icons/gift';
import './subscriber-bonuses.scss';
import { Group } from '../../models/group';

interface Params {
  bonuses: string[];
  group: Group;
  projectId: number | string;
  projectName: string;
}

export const SubscriberBonuses = (params: Params) => {
  const { bonuses } = params;

  if (!bonuses || !bonuses.length) {
    return <></>;
  }

  return (
    <div className="subscriber-bonuses">
      {bonuses.map((bonus) => {
        return (
          <CustomCard key={nanoid()}>
            <div style={{ fontSize: '16px' }}>
              <GiftIcon
                color="var(--tg-theme-link-color)"
                style={{ marginBottom: '4px' }}
              />
              &nbsp;{bonus}
            </div>
          </CustomCard>
        );
      })}
    </div>
  );
};
