import { useEffect } from 'react';
import ContestsListItem from '../contests-list-item/contests-list-item';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useNavigate } from 'react-router-dom';
import { apiKey, userId } from '../../helpers/api';
import { PATHS } from '../../const';
import Loading from '../../pages/loading/loading';
import { fetchContestList } from '../../store/thunk/ContestThunk';
import { TG } from '../../helpers/telegram';
import ContestIntroInfo from '../contest-intro-info/contest-intro-info';
import * as Sentry from '@sentry/react';

import './contests-list.scss';

const ContestsList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const contests = useAppSelector((s) => s.contests.contestsList);
  const loadingContests = useAppSelector((s) => s.contests.loadingContests);
  const hasContests = useAppSelector(
    (s) => s.projects.additionalData.hasContests
  );
  const contestListIsRequested = useAppSelector(
    (s) => s.contests.contestListIsRequested
  );

  const navigateToLaunchContestPage = () => {
    navigate(`${PATHS.contestLaunch}?key=${apiKey}`);
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (contests.length || contestListIsRequested) return;
    dispatch(fetchContestList({ chat_id: userId }))
      .unwrap()
      .catch((e) => {
        TG.WebApp?.showAlert(e.message, () => {
          navigate(`${PATHS.projects}?key=${apiKey}`);
        });
        Sentry.captureException(e);
      });
  }, [contests, contestListIsRequested]);

  useEffect(() => {
    if (!hasContests) {
      TG.MainButton?.show();
      TG.MainButton?.setText('Создать конкурс');
      TG.MainButton?.onClick(navigateToLaunchContestPage);
    }

    return () => {
      TG.MainButton?.hide();
      TG.MainButton?.offClick(navigateToLaunchContestPage);
    };
  }, [hasContests]);

  if (loadingContests) return <Loading />;
  if (!contests.length) return <ContestIntroInfo />;

  return (
    <div
      style={{
        position: 'relative',
        width: `${window.innerWidth}px`,
        overflow: 'hidden'
      }}>
      <ul className="contests-list">
        {contests.map((contest) => (
          <ContestsListItem
            key={contest.id}
            contest={contest}
            isActive={!contest.is_finished}
          />
        ))}
      </ul>
    </div>
  );
};

export default ContestsList;
