import { Container } from 'react-bootstrap';
import styles from './contest-launch.module.scss';
import ContestEndDatePicker from '../../../components/contest-end-date-picker/contest-end-date-picker';
import ContestConditionInfo from '../../../components/contest-condition-info/contest-condition-info';
import { useCreateContest } from '../../../hooks/useCreateContest';
import { useEffect, useRef } from 'react';

const ContestLaunch = () => {
  const { dateValue, setDateValue, textAreaValue, setTextAreaValue, errors } =
    useCreateContest();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    if (dateValue) {
      textAreaRef.current?.focus();
    }
  }, [dateValue]);

  return (
    <Container className={styles.container}>
      <ContestEndDatePicker
        dateValue={dateValue}
        setDateValue={setDateValue}
        error={errors.expirationDate}
      />
      <ContestConditionInfo
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        error={errors.conditions}
        textAreaRef={textAreaRef}
      />
    </Container>
  );
};

export default ContestLaunch;
