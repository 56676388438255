import React from 'react';
import { Link } from 'react-router-dom';
import './projects-list-item.scss';
import { Container } from 'react-bootstrap';
import { useAppSelector } from '../../hooks/redux';
import { useTranslation } from 'react-i18next';
import { Project } from '../../models/project';
import { saveScrollPosition } from '../../helpers/common';
import { apiKey } from '../../helpers/api';

interface Props {
  project: Project;
}

const ProjectsListItem = ({ project }: Props): JSX.Element => {
  const { id, name, logo, category_name, offersCount } = project;

  const { selectedCategory } = useAppSelector((state) => state.appState);
  const { t } = useTranslation();
  const projectLink = `/project/${id}/offers?key=${apiKey}`;

  const projectClickHandler = () => {
    saveScrollPosition();

    setTimeout(() => {
      window.scroll(0, 0);
    });
  };

  return (
    <>
      <li className="project">
        <Container>
          <Link className="project__link" to={projectLink} onClick={projectClickHandler}>
            <img className="project__image" src={logo} alt={name} />
            <div className="project__info">
              <span className="project__name">{name}</span>
              <span className="project__category">
                {selectedCategory ? t('offers') + ': ' + offersCount : category_name}
              </span>
            </div>
          </Link>
        </Container>
        <div className="line"></div>
      </li>
    </>
  );
};

export default ProjectsListItem;
