import { useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from './redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { TG } from '../helpers/telegram';
import { updateContestRequest } from '../store/thunk/ContestThunk';
import { userId } from '../helpers/api';
import format from 'date-fns/format';
import { TG_MAIN_BUTTON } from '../const';
import { AxiosError } from 'axios';
import { ErrorDataResponse } from '../api/models/data';
import * as Sentry from '@sentry/react';
import { resolveLoggerInstance } from '../helpers/logger';
import { useYM } from './useYM';
import { useTranslation } from 'react-i18next';

interface Errors {
  conditions: string;
  expirationDate: string;
}

export const useUpdateContest = () => {
  const { id } = useParams();
  const contest = useAppSelector((s) =>
    s.contests.contestsInfo.find((c) => c.id === Number(id))
  );
  const [dateValue, setDateValue] = useState<null | Date>(
    new Date(contest?.finished_at ?? new Date())
  );
  const [textAreaValue, setTextAreaValue] = useState<string>('');
  const [errors, setErrors] = useState<Errors>({
    conditions: '',
    expirationDate: ''
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAdmin = useAppSelector((s) => s.projects.additionalData.isAdmin);
  const { pathname } = useLocation();

  const logger = resolveLoggerInstance('useUpdateContest');
  const YM = useYM();
  const { t } = useTranslation();

  const isContestFinished = useMemo(
    () => new Date() >= new Date(contest?.finished_at || new Date()),
    [contest]
  );

  const dataValueRef = useRef(dateValue);
  const textAreaValueRef = useRef<string | null>(textAreaValue);

  useEffect(() => {
    if (contest) {
      if (pathname.includes('change-end-date')) {
        setDateValue(new Date(contest.finished_at));
      }
      dataValueRef.current = new Date(contest.finished_at);
      textAreaValueRef.current = contest.conditions;
    }
  }, [contest]);

  useEffect(() => {
    TG.BackButton?.show();
    TG.MainButton?.enable();
    TG.MainButton?.setText('Сохранить');
    TG.MainButton?.setParams({
      text_color: TG_MAIN_BUTTON.text_color,
      color: TG_MAIN_BUTTON.color
    });

    return () => {
      TG.MainButton?.hide();
    };
  }, []);

  useEffect(() => {
    if (dataValueRef.current?.getTime() === dateValue?.getTime()) {
      TG.MainButton?.hide();
    } else {
      if (isAdmin && !isContestFinished && dataValueRef.current) {
        TG.MainButton?.show();
      }
    }
  }, [dateValue, isAdmin, isContestFinished]);

  useEffect(() => {
    if (isAdmin && !isContestFinished && pathname.includes('conditions')) {
      TG.MainButton?.show();
    }
  }, [isAdmin, isContestFinished, textAreaValue]);

  const updateContestHandler = async () => {
    const newErrors: Errors = {
      conditions: '',
      expirationDate: ''
    };

    if (!dateValue) {
      newErrors.expirationDate = 'Укажите дату окончания конкурса';
    }

    if (!textAreaValue) {
      newErrors.conditions = 'Укажите механику награждения';
    }

    if (dateValue) {
      newErrors.conditions = '';
    }

    if (textAreaValue) {
      newErrors.expirationDate = '';
    }

    setErrors(newErrors);

    if (!newErrors.conditions && !newErrors.expirationDate) {
      const formattedDate =
        dateValue && format(dateValue as Date, 'yyyy-MM-dd');

      try {
        await dispatch(
          updateContestRequest({
            chat_id: userId,
            conditions: textAreaValue ? textAreaValue : undefined,
            expiration_date: formattedDate ?? undefined,
            contest_id: Number(id)
          })
        ).unwrap();

        textAreaValue
          ? TG.WebApp?.showAlert('Условия конкурса успешно изменены', () => {
              navigate(-1);
            })
          : TG.WebApp?.showAlert('Дата завершения конкурса изменена', () => {
              navigate(-1);
            });
      } catch (e) {
        if (e instanceof AxiosError) {
          logger.error(e);
          const xRequestId = e.response?.headers['x-request-id'];
          const responseDataError: ErrorDataResponse = e.response
            ?.data as ErrorDataResponse;
          if (e.response?.status === 500) {
            TG.WebApp?.showAlert(
              'Ой, что-то пошло не так\n\n' + t('error_id') + ': ' + xRequestId
            );
          } else {
            TG.WebApp?.showAlert(responseDataError.message);
          }
          YM.errorLog(e.config?.url, userId, e.message, xRequestId);
          Sentry.captureException(e);
        }
      }
    }
  };

  useEffect(() => {
    TG.MainButton?.onClick(updateContestHandler);

    return () => {
      TG.MainButton?.offClick(updateContestHandler);
    };
  }, [dateValue, textAreaValue, errors]);

  return {
    dateValue,
    setDateValue,
    textAreaValue,
    setTextAreaValue,
    errors
  };
};
