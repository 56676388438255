import { useAppDispatch, useAppSelector } from './redux';
import { useTranslation } from 'react-i18next';
import { TG } from '../helpers/telegram';
import { Group } from '../models/group';
import { reqPromo } from '../store/thunk/PromocodeThunk';
import { userId } from '../helpers/api';
import { setPromoAsRequested } from '../store/slices/projectsSlice';
import { useYM } from './useYM';
import { resolveLoggerInstance } from '../helpers/logger';
import { AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import { ErrorDataResponse } from '../api/models/data';

export const useRequestPromo = (group: Group, projectId: number | string) => {
  const isRequestingPromo = useAppSelector(
    (s) => s.promocode.isRequestingPromo
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const alreadyRequested = group.request_sent_at;
  const { landing } = group;
  const YM = useYM();

  const logger = resolveLoggerInstance('useRequestPromo');

  const requestPromoBtnClickHandler = async () => {
    if (!alreadyRequested) {
      const promoData = {
        project_id: projectId,
        landing_id: landing ? landing.id : null
      };

      try {
        await dispatch(
          reqPromo({ userId, projectId, landingId: promoData.landing_id })
        ).unwrap();
        dispatch(setPromoAsRequested(promoData));
        // await sendBotLogs({
        //   chat_id: userId,
        //   project_id: projectId,
        //   landing_id: landing ? landing.id : null,
        //   promo_group_id: null,
        //   event_type: 'request_promo'
        // });
      } catch (e) {
        if (e instanceof AxiosError) {
          logger.error(e);
          const xRequestId = e.response?.headers['x-request-id'];
          const responseDataError: ErrorDataResponse = e.response
            ?.data as ErrorDataResponse;
          if (e.response?.status === 500) {
            TG.WebApp?.showAlert(
              'Ой, что-то пошло не так\n\n' + t('error_id') + ': ' + xRequestId
            );
          } else {
            TG.WebApp?.showAlert(responseDataError.message);
          }
          YM.errorLog(e.config?.url, userId, e.message, xRequestId);
          Sentry.captureException(e);
        }
      }
    }
  };

  return {
    isRequestingPromo,
    alreadyRequested,
    handleRequestPromo: requestPromoBtnClickHandler
  };
};
