import React from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import { TG } from '../../helpers/telegram';
import { useTranslation } from 'react-i18next';

interface Props {
  alert?: string;
  errorMessage?: string;
}

const ErrorPage = ({ alert, errorMessage }: Props): JSX.Element => {
  const { t } = useTranslation();
  const closeBtnClickHandler = () => {
    TG.WebApp?.close();
  };

  const reloadBtnClickHandler = () => {
    window.location.reload();
  };

  return (
    <>
      <Container>
        <div className="error py-4">
          <Alert variant="danger">
            <div style={{ whiteSpace: 'pre-wrap' }}>{alert ?? 'Ошибка'}</div>
          </Alert>
          {errorMessage === 'Network Error' ? (
            <Button className="w-100" onClick={reloadBtnClickHandler}>
              {t('try_again')}
            </Button>
          ) : (
            <Button className="w-100" onClick={closeBtnClickHandler}>
              {t('close')}
            </Button>
          )}
        </div>
      </Container>
    </>
  );
};

export default ErrorPage;
