import React from 'react';
import CSS from 'csstype';

interface Props {
  color?: string;
  className?: string;
  style?: CSS.Properties;
}

const HitIcon = ({ color = '#000000', className = '', style = {} }: Props) => {
  const colorCss = { color: color };
  const css = { ...style, ...colorCss };

  return (
    <>
      <svg
        className={className}
        style={css}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.85107 8.06104C2.87256 6.74512 3.22168 5.93408 3.93604 4.9834C4.7793 3.86621 5.88574 2.56104 6.70752 0.949707C6.92236 0.530762 7.12109 0.256836 7.51855 0.256836C7.95361 0.256836 8.28662 0.562988 8.28662 1.07861C8.28662 2.06152 7.35742 3.42578 7.35742 4.17773C7.35742 4.58594 7.68506 4.84375 8.18457 4.84375H10.145C10.6929 4.84375 11.1118 5.27881 11.1118 5.81592C11.1118 6.12744 11.0044 6.38525 10.8271 6.53027C10.7358 6.61084 10.7197 6.66455 10.8057 6.74512C10.9561 6.87939 11.0366 7.15869 11.0366 7.41113C11.0366 7.77637 10.8647 8.08789 10.6123 8.23291C10.5156 8.29736 10.5049 8.36719 10.5693 8.44775C10.6875 8.60352 10.7627 8.8291 10.7627 9.07617C10.7627 9.52197 10.4727 9.84424 10.1289 9.98389C10.0591 10.0107 10.0537 10.0698 10.0967 10.1396C10.1719 10.2632 10.2256 10.4136 10.2256 10.6284C10.2256 11.1548 9.80127 11.418 9.36621 11.5308C9.06543 11.6113 8.53369 11.665 7.81396 11.6597L7.05127 11.6543C4.46777 11.6328 2.81885 10.0913 2.85107 8.06104ZM0.144043 8.09863C0.144043 6.39062 1.1001 5.04785 2.34619 5.04785H3.15186C2.66309 5.65479 2.12598 6.64844 2.10449 8.04492C2.08301 9.30176 2.53418 10.4028 3.5332 11.1279H2.55029C1.17529 11.1279 0.144043 9.74756 0.144043 8.09863Z" />
      </svg>
    </>
  );
};

export default HitIcon;
