import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Brush
} from 'recharts';
import { GraphData } from '../../api/models/contests';
import { transformDateLabelForGraph } from '../../helpers/date';
import { transformGraphData } from '../../helpers/graph';

interface GraphProps {
  data: GraphData;
  startDate: string;
  endDate: string;
}

const BarGraph = ({ data, startDate, endDate }: GraphProps) => {
  const graphData = transformGraphData(data, startDate, endDate);

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart width={150} height={40} data={graphData}>
        <XAxis
          dataKey="hour"
          interval={graphData.length - 2}
          padding={{ left: 12, right: 12 }}
          tickFormatter={(date) => transformDateLabelForGraph(date)}
          tick={{
            stroke: 'var(--tg-theme-text-color)',
            strokeWidth: 0.5,
            fill: 'var(--tg-theme-text-color)'
          }}
          tickLine={{ stroke: 'var(--tg-theme-text-color)', strokeWidth: 0.5 }}
          axisLine={{ stroke: 'var(--tg-theme-text-color)' }}
        />
        <Bar dataKey="count" fill="var(--tg-theme-link-color)" />
        <Tooltip formatter={(value) => [value, `подписалось`]} />
        <Brush
          dataKey="hour"
          height={30}
          stroke="var(--tg-theme-link-color)"
          travellerWidth={15}
          tickFormatter={(date) => transformDateLabelForGraph(date)}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarGraph;
