import { config } from '../config';
import { SCROLL } from './scroll';
import { KeyboardEvent } from 'react';

export const getQueryParam = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const truncate = (
  str: string,
  n = 10,
  useWordBoundary = false
): string => {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1); // the original check

  return `${
    useWordBoundary ? subString.slice(0, subString.lastIndexOf(' ')) : subString
  }...`;
};

export const getFirstSentence = (text: string) => {
  return text.split('. ')[0];
};

export const calculateWindowWidth = () => {
  const width = window.outerWidth > 0 ? window.outerWidth : window.innerWidth;
  return width > 0 ? `${width}px` : '100%';

  // return window.outerWidth < window.innerWidth && window.outerWidth > 0
  //   ? window.outerWidth
  //   : window.innerWidth;
};

export const isDev = () => {
  return config.dev;
};

export const isProd = () => {
  return config.prod;
};

export const isTest = () => {
  return config.isTest;
};

export const saveScrollPosition = () => {
  try {
    SCROLL.savePosition();
  } catch (e) {
    SCROLL.savePositionAlternative();
  }
};

export const restoreScrollPosition = () => {
  try {
    SCROLL.restorePosition();
  } catch (e) {
    SCROLL.restorePositionAlternative();
  }
};

export const getIdFromPath = (path: string) => {
  const regExp = new RegExp('/[0-9]+');
  const match = path.match(regExp);

  return match?.length ? Number(match[0].substring(1)) : null;
};

export const isEnterKey = (evt: KeyboardEvent) => {
  const { key } = evt;

  return key === '13' || key === 'Enter';
};

export const removeHtmlTags = (html: string) => {
  const div = document.createElement('div');
  div.innerHTML = html;

  return div.textContent || div.innerText || '';
};
