import { useEffect, useState } from 'react';
import { useYM } from './useYM';
import { PATHS } from '../const';
import { sendError } from '../api/promocodes';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from './redux';
import { fetchProjects } from '../store/thunk/ProjectThunk';
import { apiKey, userId } from '../helpers/api';
import { AxiosError } from 'axios';
import { resolveLoggerInstance } from '../helpers/logger';
import { TG } from '../helpers/telegram';
import { useTranslation } from 'react-i18next';
import { sendBotLogs } from '../api/log';
import * as Sentry from '@sentry/react';

export const useLoadProjects = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const YM = useYM();
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState<boolean>(false);

  const botApiVersion = TG.getBotApiVersion();

  const logger = resolveLoggerInstance('fetchProjects');

  const sendKeyMissingError = () => {
    sendError({
      url: window.location.href,
      chat_id: userId,
      message: 'Key is missing'
    });
    YM.errorLog(window.location.href, userId, 'Key is missing');
  };

  const loadProjects = async () => {
    await sendBotLogs({ chat_id: userId, event_type: 'web_app_launch' });

    try {
      const response = await dispatch(
        fetchProjects({ user_id: userId, isPremium: TG.User?.is_premium })
      ).unwrap();

      YM.sendParams({
        blogger_id: response.additionalData.user_id ?? null
      });

      // Redirect from '/' to '/projects'
      if (pathname === PATHS.main) {
        navigate(`${PATHS.projects}?key=${apiKey}`);
      }
    } catch (e) {
      if (!apiKey) {
        sendKeyMissingError();
        Sentry.captureException('Key is missing');
      }
      if (e instanceof AxiosError) {
        logger.error(e);
        const xRequestId = e.response?.headers['x-request-id'];
        sendError({
          url: e.config?.url,
          chat_id: userId,
          message: e.message,
          error_id: xRequestId,
          code: e.code,
          cause: e.cause?.message,
          status: e.status,
          request: JSON.stringify(e.request)
        });
        YM.errorLog(e.config?.url, userId, e.message, xRequestId);
        Sentry.captureException(e);
      }
    }
  };

  useEffect(() => {
    // init Yandex Metric
    YM.initYm(userId);

    if (pathname === PATHS.analytics) {
      setLoaded(true);
      return;
    }

    loadProjects().then(() => {
      setLoaded(true);
      if (botApiVersion === '6.0') {
        TG.WebApp?.showAlert(t('updateTgVersion'));
      }
    });
  }, []);

  return loaded;
};
