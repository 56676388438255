import React, { FC, lazy, ReactNode, Suspense } from 'react';
import { isDev } from '../../../helpers/common';

const DevStyles = lazy(() => import('../../theme/dev-styles/dev-styles'));

interface ThemeSelectorProps {
  children: ReactNode;
}

const ThemeSelector: FC<ThemeSelectorProps> = ({ children }) => {
  return (
    <>
      <Suspense fallback={null}>{isDev() && <DevStyles />}</Suspense>
      {children}
    </>
  );
};

export default ThemeSelector;
