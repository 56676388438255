import { Container } from 'react-bootstrap';
import PageHeader from '../../../components/page-header/page-header';
import { useEffect } from 'react';
import { TG } from '../../../helpers/telegram';
import Loading from '../../loading/loading';
import { useLoadContest } from '../../../hooks/useLoadContest';

const ContestMyReferrals = () => {
  useEffect(() => {
    TG.BackButton?.show();
  }, []);

  const { contest, loading } = useLoadContest();

  if (loading) return <Loading />;
  if (!contest) return <Container>Нет информации о данном конкурсе</Container>;

  const { referrals } = contest;

  return (
    <div className="contest-my-referrals">
      <PageHeader title={'Приведенные мной'} />
      <Container>
        <div className="contest-table">
          <div className="contest-table-row">
            <div className="contest-table-row__item title">Имя</div>
            <div className="contest-table-row__item title">Логин</div>
          </div>
          {referrals.map((referral) => (
            <div key={referral.username} className="contest-table-row">
              <div className="contest-table-row__item">
                {referral.full_name}
              </div>
              <div className="contest-table-row__item">
                {referral.username && `@${referral.username}`}
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default ContestMyReferrals;
