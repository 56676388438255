import { IconType } from 'react-icons';
import ContestReferralStatBlockInfo from '../contest-referral-stat-block-info/contest-referral-stat-block-info';

interface ContestReferralStatBlockProps {
  header: {
    headerIcon: IconType;
    headerTitle: string;
  };
  info: {
    infoTitle: string;
    infoTitleValue?: number;
    infoTitlePercentValue?: number;
  }[];
  allUserReferrals?: number;
}

const ContestReferralStatBlock = (props: ContestReferralStatBlockProps) => {
  const { header, info, allUserReferrals } = props;
  const HeaderIcon = header.headerIcon;

  return (
    <div className="contest-referral-stat-block">
      <div className="stat-block-header">
        <div className="stat-block-header-title">
          <div className="stat-block-header-title-icon">
            <HeaderIcon />
          </div>
          <div className="stat-block-header-title-text">
            {header.headerTitle}
          </div>
        </div>
      </div>
      <div className="stat-block-info">
        {info.map((i, index) => (
          <ContestReferralStatBlockInfo
            key={index}
            allUserReferrals={allUserReferrals}
            {...i}
          />
        ))}
      </div>
    </div>
  );
};

export default ContestReferralStatBlock;
