import { z } from 'zod';
import { groupSchema } from '../../models/group';
import { projectDescriptionSchema } from '../../models/project';

const additionalDataSchema = z.object({
  user_id: z.number(),
  blogger_id: z.number().nullable(),
  has_contest: z.boolean(),
  is_admin: z.boolean(),
  has_finished_contest: z.boolean(),
  bot: z
    .object({
      id: z.number().optional(),
      is_active: z.boolean().optional()
    })
    .optional()
});

export const dataItemSchema = z.object({
  project: projectDescriptionSchema,
  groups: z.array(groupSchema)
});

export const dataSchema = z.object({
  success: z.boolean(),
  additionalData: additionalDataSchema,
  data: z.array(dataItemSchema)
});

const errorSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  name: z.string(),
  status: z.number()
});

export type AdditionalData = z.infer<typeof additionalDataSchema>;
export type Data = z.infer<typeof dataSchema>;
export type DataItem = z.infer<typeof dataItemSchema>;
export type ErrorDataResponse = z.infer<typeof errorSchema>;
