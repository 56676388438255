import { Container } from 'react-bootstrap';
import { Fragment, useEffect } from 'react';
import { TG } from '../../helpers/telegram';
import PageHeader from '../../components/page-header/page-header';
import ContestInfo from '../../components/contest-info/contest-info';
import { useLocation } from 'react-router-dom';
import { apiKey, userId } from '../../helpers/api';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import Loading from '../loading/loading';
import { useLoadContest } from '../../hooks/useLoadContest';
import { setShowProjectsNav } from '../../store/slices/appStateSlice';
import ContestStat from '../../components/contest-stat/contest-stat';
import { localizeDate, calculateContestProgress } from '../../helpers/date';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSendContestResultsInit } from '../../hooks/useSendContestResultsInit';
import cn from 'classnames';
import UserBanned from '../../components/user-banned/user-banned';
import { setIsContestEndedManually } from '../../store/slices/contestsSlice';

import './contest.scss';

const Contest = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    TG.BackButton?.show();
    return () => {
      TG.BackButton?.hide();
      dispatch(setIsContestEndedManually(null));
    };
  }, []);

  useEffect(() => {
    if (isAdmin) {
      dispatch(setShowProjectsNav(false));
    }
    return () => {
      dispatch(setShowProjectsNav(true));
    };
  }, []);

  const { contest, loading, navigate } = useLoadContest();
  useSendContestResultsInit();

  const location = useLocation();
  const isAdmin = useAppSelector((s) => s.projects.additionalData.isAdmin);
  const isContestEndedManually = useAppSelector(
    (s) => s.contests.contestIsEndedManually
  );

  if (loading) return <Loading />;
  if (!contest) return <Container>Нет информации о данном конкурсе</Container>;

  const userPlace = contest.results.findIndex(
    (user) => user.chat_id === userId
  );

  const bannedUsers = contest.banned_users;

  const isUserBanned =
    bannedUsers.findIndex((user) => user.chat_id === userId) !== -1;

  const totalSubs = contest.contest_referrals_count;
  const isContestFinished = contest.is_finished;

  const [localizedStartDate] = localizeDate(contest.started_at);
  const [localizedEndDate] = localizeDate(contest.finished_at);
  const { progressBarWidth, remainingContestDuration, remainingContestNoun } =
    calculateContestProgress(
      new Date(contest.started_at),
      new Date(contest.finished_at)
    );

  const referralStatNavigate = (chat_id: number) => {
    return navigate(
      `${location.pathname}/referral-stat/${chat_id}?key=${apiKey}`
    );
  };

  if (!isAdmin && isUserBanned) return <UserBanned />;

  return (
    <div className="contest">
      <ContestInfo
        endDate={contest.finished_at}
        referralLink={contest.referral_link}
        referralsCount={contest.referrals.length}
        userPlace={userPlace + 1}
        isContestFinished={isContestFinished}
        isContestEndedManually={isContestEndedManually}
      />
      {isAdmin && (
        <ContestStat
          totalSubs={totalSubs}
          startDate={localizedStartDate}
          endDate={localizedEndDate}
          remainingTime={remainingContestDuration}
          progressBarWidth={progressBarWidth}
          timeNoun={remainingContestNoun}
          isContestFinished={isContestFinished}
          isContestEndedManually={isContestEndedManually}
        />
      )}
      {isAdmin && bannedUsers.length > 0 && (
        <Fragment>
          <PageHeader title="Забанены" />
          <Container>
            <div className="contest-banned-users">
              {bannedUsers.map((user) => (
                <div
                  key={user.chat_id}
                  className="banned-user"
                  onClick={() => referralStatNavigate(user.chat_id)}>
                  {user.full_name ?? user.chat_id}
                </div>
              ))}
            </div>
          </Container>
        </Fragment>
      )}
      <PageHeader title="Рейтинг" />
      <Container>
        <div className="contest-table">
          <div className={cn('contest-table-row', { users: isAdmin })}>
            <div className="contest-table-row__item title">Место</div>
            <div className="contest-table-row__item title">Подписчиков</div>
            {isAdmin && (
              <div
                className="contest-table-row__item title"
                style={{ visibility: 'hidden' }}>
                Имя
              </div>
            )}
          </div>
          {!contest.results.length && (
            <div style={{ padding: 10 }}>
              Пока никто не привел друзей в бот.
              <br />
              Отправь пригласительную ссылку другу и стань первым!
            </div>
          )}
          {contest.results.slice(0, 100).map((userResult) => (
            <div
              key={userResult.chat_id}
              className={cn(
                'contest-table-row',
                { users: isAdmin },
                { 'user-rating': userResult.chat_id === userId && !isAdmin }
              )}
              onClick={
                isAdmin
                  ? () => referralStatNavigate(userResult.chat_id)
                  : undefined
              }>
              <div className="contest-table-row__item">
                {userResult.i}{' '}
                {!isAdmin && userResult.chat_id === userId && '(мой рейтинг)'}
              </div>
              <div className="contest-table-row__item">
                {userResult.referrals_count}
              </div>
              {isAdmin && (
                <div className="contest-table-row__item">
                  <span>{userResult.full_name ?? userResult.chat_id}</span>{' '}
                  <FontAwesomeIcon
                    className="send-message-warning__icon"
                    icon={faAngleRight}
                  />
                </div>
              )}
            </div>
          ))}
          {userPlace > 99 && (
            <div
              key={contest.results[userPlace].chat_id}
              className={cn('contest-table-row', { 'user-rating': !isAdmin })}>
              <div className="contest-table-row__item">
                {contest.results[userPlace].i} {!isAdmin && '(мой рейтинг)'}
              </div>
              <div className="contest-table-row__item">
                {contest.results[userPlace].referrals_count}
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Contest;
