import { Container } from 'react-bootstrap';
import PageHeader from '../../../components/page-header/page-header';
import { Fragment } from 'react';
import { useLoadContest } from '../../../hooks/useLoadContest';
import Loading from '../../loading/loading';
import { useAppSelector } from '../../../hooks/redux';
import ContestConditionInfo from '../../../components/contest-condition-info/contest-condition-info';
import styles from './contest-conditions.module.scss';
import { useUpdateContest } from '../../../hooks/useUpdateContest';

const ContestConditions = () => {
  const { contest, loading } = useLoadContest();
  const isAdmin = useAppSelector((s) => s.projects.additionalData.isAdmin);

  const { textAreaValue, setTextAreaValue, errors } = useUpdateContest();

  if (loading) return <Loading />;
  if (!contest) return <Container>Нет информации о данном конкурсе</Container>;

  const isContestFinished = contest.is_finished;

  const conditionPageContent =
    isAdmin && !isContestFinished ? (
      <Container className={styles.container}>
        <ContestConditionInfo
          textAreaValue={textAreaValue}
          setTextAreaValue={setTextAreaValue}
          error={errors.conditions}
        />
      </Container>
    ) : (
      <Fragment>
        <PageHeader title="Условия конкурса" />
        <Container className={styles.conditionsText}>
          1. Отправь пригласительную ссылку своим друзьям и знакомым, которые
          ранее не пользовались моим ботом. Расскажи им, какие скидки они могут
          там найти.
          <br />
          <br />
          <div style={{ whiteSpace: 'pre-line' }}>2. {contest.conditions}</div>
          <br />
          3. Запрещены любые способы искусственного увеличения числа
          подписчиков. При выявлении случаев, когда люди подписываются на бота
          не с целью получения скидок или экономии, это будет считаться
          мотивированным трафиком и признаётся нарушением условий конкурса.
        </Container>
      </Fragment>
    );

  return <div className="contest-conditions">{conditionPageContent}</div>;
};

export default ContestConditions;
