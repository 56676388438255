import React, { useEffect } from 'react';
import ProjectItem from './projects-list-item';
import { Container } from 'react-bootstrap';
import './projects-list.scss';
import { TG } from '../../helpers/telegram';
import { useAppSelector } from '../../hooks/redux';
import { useTranslation } from 'react-i18next';
import { Project } from '../../models/project';
import SearchPanel from '../search-panel/search-panel';
import { restoreScrollPosition } from '../../helpers/common';
import { userId } from '../../helpers/api';
import { filterProjectByCategory, filterProjectsWithOffers } from '../../helpers/projects';

const ProjectsList = (): JSX.Element => {
  const { t } = useTranslation();
  const { searchProjectValue, selectedCategory } = useAppSelector((s) => s.appState);
  const projects = useAppSelector((s) => s.projects.projects.items);

  const filterProjects = (projects: Project[], category: string | null) => {
    if (userId) {
      return category ? filterProjectByCategory(projects, category) : projects;
    }

    return category
      ? filterProjectByCategory(filterProjectsWithOffers(projects), category)
      : filterProjectsWithOffers(projects);
  };

  const searchProjects = (projects: Project[], search: string) => {
    if (search.length === 0) {
      return projects;
    }

    const modifiedSearch = search.toLowerCase().replace(/ё/g, 'е');

    return projects.filter((p) => {
      const projectName = p.name.toLowerCase().replace(/ё/g, 'е');
      return projectName.includes(modifiedSearch);
    });
  };

  const visibleProjects = searchProjects(
    filterProjects(projects, selectedCategory),
    searchProjectValue
  );

  useEffect(() => {
    if (selectedCategory) {
      TG.BackButton?.show();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => restoreScrollPosition());
  });

  return (
    <>
      <Container>
        {selectedCategory ? (
          <div className="projects-list__title">{selectedCategory}</div>
        ) : (
          <SearchPanel />
        )}
      </Container>
      {visibleProjects.length > 0 ? (
        <ul className="projects-list">
          {visibleProjects.map((project) => (
            <ProjectItem project={project} key={project.id} />
          ))}
        </ul>
      ) : (
        <Container>{t('empty_request')}</Container>
      )}
    </>
  );
};

export default ProjectsList;
