import { TG } from './telegram';
import {config} from "../config";

const searchParams = new URLSearchParams(window.location.search);

export const apiKey = searchParams.get('key');
// eslint-disable-next-line no-debugger

const chatId = searchParams.get('chat_id');

export const userId = config.isTest && chatId ? parseInt(chatId) ?? TG.resolveUser() : TG.resolveUser();
