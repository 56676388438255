import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { PATHS } from '../../const';
import { useAppSelector } from '../../hooks/redux';
import MainNav from './main-nav';

const Main = (): JSX.Element => {
  const { showProjectsNav, searchProjectValue, selectedCategory } = useAppSelector(
    (state) => state.appState
  );
  const { pathname } = useLocation();
  const needToShowNav =
    (showProjectsNav && selectedCategory === null && !searchProjectValue) ||
    pathname === PATHS.categories;

  return (
    <>
      {needToShowNav && <MainNav />}
      <div style={needToShowNav ? { marginTop: '50px' } : {}}>
        <Outlet />
      </div>
    </>
  );
};

export default Main;
