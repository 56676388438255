import { Project } from '../models/project';
import { projectHasOffers } from './promo';

export const filterProjectByCategory = (projects: Project[], category: string | null) => {
  return projects
    .filter((p) => p.category_name === category)
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const filterProjectsWithOffers = (projects: Project[]) => {
  return projects.filter((p) => {
    return projectHasOffers(p);
  });
};
