import { Category } from '../models/state';
import { Project } from '../models/project';
import { filterProjectsWithOffers } from './projects';

export const filterCategories = (categories: Category[], projects: Project[]) => {
  return categories.filter((category) => {
    return filterProjectsWithOffers(projects).filter((project) => {
      return project.category_id === category.id;
    }).length;
  });
};
