import React from 'react';
import NotFound from '../not-found/not-found';
import { Container } from 'react-bootstrap';
import './promo-detail.scss';
import { useFindPromoAndProject } from '../../hooks/useFindPromoAndProject';
import { useTranslation } from 'react-i18next';

const PromoDetail = (): JSX.Element => {
  const { t } = useTranslation();
  const { project, promo } = useFindPromoAndProject();

  if (!promo || !project) {
    return <NotFound />;
  }

  return (
    <>
      <div className="promo-detail">
        <div className="promo-detail__header">
          <Container>
            <div className="promo-detail__title">{t('show_barcode_alert')}</div>
          </Container>
        </div>
      </div>
      <div className="promo-detail__body">
        <Container>
          <div className="promo-detail__project-name">{project.name}</div>
          <div className="promo-detail__comment">{promo.comment}</div>
          <div className="promo-detail__image">
            <img src={promo.image} alt={promo.code} />
          </div>
        </Container>
      </div>
    </>
  );
};

export default PromoDetail;
