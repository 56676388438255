import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import CopyIcon from '../icons/copy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { TG } from '../../helpers/telegram';

interface Props {
  label?: string;
  text: string;
  successText?: string;
  className?: string;
  callback?: () => void;
}

const CopyButton = ({
  label,
  text,
  successText,
  className,
  callback = () => {
    ('');
  }
}: Props) => {
  const SHOW_SUCCESS_TEXT_TIMEOUT = 2000;
  const [hasCopied, setHasCopied] = useState<boolean>(false);
  // const [copyError, setCopyError] = useState<boolean>(false);

  const successCallback = () => {
    setHasCopied(true);
    TG.HapticFeedback?.notificationOccurred('success');
    setTimeout(() => {
      setHasCopied(false);
    }, SHOW_SUCCESS_TEXT_TIMEOUT);
  };

  // const errorCallback = () => {
  //   setCopyError(true);
  //   setTimeout(() => {
  //     setCopyError(false);
  //   }, SHOW_SUCCESS_TEXT_TIMEOUT);
  // };

  return (
    <>
      <CopyToClipboard text={text} onCopy={successCallback}>
        <button
          className={className ?? 'btn-copy'}
          type="button"
          disabled={hasCopied}
          onClick={() => {
            callback();
          }}
        >
          {hasCopied &&
            (successText ?? (
              <FontAwesomeIcon
                icon={faCheck}
                size="xl"
                style={{ color: 'var(--tg-theme-link-color)' }}
              />
            ))}
          {!hasCopied ? label ? label : <CopyIcon color="var(--tg-theme-link-color)" /> : ''}
        </button>
      </CopyToClipboard>
      {/*<button*/}
      {/*  className={className ?? 'btn-copy'}*/}
      {/*  type="button"*/}
      {/*  disabled={hasCopied || copyError}*/}
      {/*  onClick={() => {copyText(text, successCallback, errorCallback, callback);}}*/}
      {/*>*/}
      {/*  {*/}
      {/*    hasCopied && !copyError ?*/}
      {/*      (successText ?? <FontAwesomeIcon icon={faCheck} size="xl" style={{color: 'var(--tg-theme-link-color)'}} />) :*/}
      {/*      ''*/}
      {/*  }*/}
      {/*  { copyError && !hasCopied ? <FontAwesomeIcon icon={faTimes} /> : '' }*/}
      {/*  { !hasCopied && !copyError ? (label ? label : <CopyIcon color="var(--tg-theme-link-color)" />) : '' }*/}
      {/*</button>*/}
    </>
  );
};

export default CopyButton;
