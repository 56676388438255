import styles from './analytics.module.scss';
import PageHeader from '../../components/page-header/page-header';
import { Container } from 'react-bootstrap';
import { EmblaOptionsType } from 'embla-carousel';
import AnalyticsFilterCarousel from '../../components/analytics-filter-carousel/analytics-filter-carousel';
import {
  FunctionComponent,
  SVGProps,
  useEffect,
  useRef,
  useState
} from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import UniqueUsers from '../../assets/details/unique-users.svg?react';
import NewUsers from '../../assets/details/new-users.svg?react';
import Redirect from '../../assets/details/redirect.svg?react';
import WidgetLaunch from '../../assets/details/widget-launch.svg?react';
import ProjectView from '../../assets/details/project-view.svg?react';
import PromoCopy from '../../assets/details/promo-copy.svg?react';
import TgPlane from '../../assets/tg-plane.svg?react';
import cn from 'classnames';
import LineGraph from '../../components/line-graph/line-graph';
import { v4 as uuidv4 } from 'uuid';
import useEmblaCarousel from 'embla-carousel-react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { fetchAnalyticData } from '../../store/thunk/AnalyticThunk';
import { userId } from '../../helpers/api';
import { TG } from '../../helpers/telegram';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import { ErrorDataResponse } from '../../api/models/data';
import { t } from 'i18next';
import { resolveLoggerInstance } from '../../helpers/logger';
import { useYM } from '../../hooks/useYM';
import { AnalyticDataItem, analyticSchema } from '../../api/models/analytic';
import Loading from '../loading/loading';
import { Tooltip } from 'react-tooltip';

export interface Filter {
  title: string;
  active: boolean;
  id: number;
  key: string;
}

const UPPER_FILTER_OPTIONS: EmblaOptionsType = {
  slidesToScroll: 'auto',
  containScroll: 'trimSnaps',
  dragFree: true
};

const LOWER_FILTER_OPTIONS: EmblaOptionsType = {
  containScroll: 'trimSnaps',
  dragFree: true,
  active: false
};

const OPTIONS2: EmblaOptionsType = {
  active: false
};

interface Details {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  title: string;
  description: string;
}

const DETAILS: Details[] = [
  {
    icon: UniqueUsers,
    title: 'Уникальные пользователи',
    description:
      'Пользователи Telegram, которые открыли виджет с промокодами в определенный период времени'
  },
  {
    icon: NewUsers,
    title: 'Новые пользователи',
    description:
      'Пользователи Telegram, которые впервые открыли виджет с промокодами'
  },
  {
    icon: WidgetLaunch,
    title: 'Запуск виджета',
    description: 'Cуммарное количество раз, когда пользователи открыли виджет'
  },
  {
    icon: ProjectView,
    title: 'Просмотр проекта',
    description:
      'Засчитывается каждый раз, когда пользователь заходит в карточку проекта'
  },
  {
    icon: PromoCopy,
    title: 'Скопировано промокодов',
    description:
      'Считается количество раз, когда подписчики нажали на одну из кнопок "Скопировать промокод", "Скопировать и перейти на сайт'
  },
  {
    icon: Redirect,
    title: 'Количество переходов',
    description:
      'Считается количество раз, когда подписчики нажали на одну из кнопок "Скопировать и перейти на сайт", "Перейти на сайт" или "Воспользоваться предложением"'
  }
  // {
  //   icon: Income,
  //   title: 'Условный доход',
  //   description:
  //     'Теоретический доход, рассчитанный на основе предположения, что каждый подписчик воспользуется всеми скопированными промокодами'
  // }
];

const TABLE_HEADERS = [
  'Дата',
  'Уникальные',
  'Новые',
  'Запуски',
  'Просмотры',
  'Скопировано',
  'Переходы'
];

export interface MockData {
  date: string;
  isDistribution: boolean;
  uniqueUsers: number;
  newUsers: number;
  widgetLaunch: number;
  projectView: number;
  promoCopy: number;
  income: number;
}

export const transformObjectKeysLabel = (key: keyof AnalyticDataItem) => {
  if (key === 'date') return 'Дата';
  if (key === 'isDistribution') return;
  if (key === 'uniqueUsers') return 'Уникальные';
  if (key === 'newUsers') return 'Новые';
  if (key === 'widgetLaunch') return 'Запуски';
  if (key === 'projectView') return 'Просмотры';
  if (key === 'promoCopy') return 'Скопировано';
  if (key === 'redirect') return 'Переходы';
};

const MOCK_DATA: MockData[] = [
  {
    date: '29.02.2024',
    isDistribution: true,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '28.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '27.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '26.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '25.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '24.02.2024',
    isDistribution: true,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '23.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '22.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '21.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '20.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '19.02.2024',
    isDistribution: true,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '18.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '17.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '16.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '15.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '14.02.2024',
    isDistribution: true,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '13.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '12.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '11.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '10.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '09.02.2024',
    isDistribution: true,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '08.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '07.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '06.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '05.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '04.02.2024',
    isDistribution: true,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '03.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '02.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  },
  {
    date: '01.02.2024',
    isDistribution: false,
    uniqueUsers: Math.floor(Math.random() * 91) + 10,
    newUsers: Math.floor(Math.random() * 91) + 10,
    widgetLaunch: Math.floor(Math.random() * 91) + 10,
    projectView: Math.floor(Math.random() * 91) + 10,
    promoCopy: Math.floor(Math.random() * 91) + 10,
    income: Math.floor(Math.random() * 91) + 10
  }
];

type TransformedData = Array<
  { date: string; isDistribution: boolean }[] | number[]
>;

const transformData = (data: AnalyticDataItem[]): TransformedData => {
  return Object.keys(data[0]).reduce((acc, key) => {
    if (key !== 'date' && key !== 'isDistribution') {
      acc.push(
        data.map((item) => item[key as keyof AnalyticDataItem]) as number[]
      );
    } else if (key === 'date') {
      acc.unshift(
        data.map(({ date, isDistribution }) => ({ date, isDistribution }))
      );
    }
    return acc;
  }, [] as TransformedData);
};

// const transformedData: TransformedData = Object.keys(MOCK_DATA[0]).reduce(
//   (acc, key) => {
//     if (key !== 'date' && key !== 'isDistribution') {
//       acc.push(
//         MOCK_DATA.map((item) => item[key as keyof MockData]) as number[]
//       );
//     } else if (key === 'date') {
//       acc.unshift(
//         MOCK_DATA.map(({ date, isDistribution }) => ({ date, isDistribution }))
//       );
//     }
//     return acc;
//   },
//   [] as TransformedData
// );

const calculateSum = (
  propertyKey: keyof AnalyticDataItem,
  data: AnalyticDataItem[]
) => {
  return data.reduce((accumulator, currentValue) => {
    if (
      currentValue[propertyKey] &&
      typeof currentValue[propertyKey] === 'number'
    ) {
      return accumulator + Number(currentValue[propertyKey]);
    }
    return accumulator;
  }, 0);
};

const Analytics = () => {
  const [filters, setFilters] = useState<Filter[]>([
    {
      title: 'Test',
      active: false,
      id: 1000,
      key: 'test'
    },
    {
      title: 'Уникальные пользователи',
      active: false,
      id: 1,
      key: 'uniqueUsers'
    },
    {
      title: 'Новые пользователи',
      active: false,
      id: 2,
      key: 'newUsers'
    },
    {
      title: 'Запуск виджета',
      active: true,
      id: 3,
      key: 'widgetLaunch'
    },
    {
      title: 'Просмотр проекта',
      active: false,
      id: 4,
      key: 'projectView'
    },
    {
      title: 'Скопировано промокодов',
      active: false,
      id: 5,
      key: 'promoCopy'
    },
    {
      title: 'Количество переходов',
      active: false,
      id: 6,
      key: 'redirect'
    }
  ]);

  useEffect(() => {
    const savedFilters = localStorage.getItem('filters');
    if (savedFilters) {
      const parsedFilters: Filter[] = JSON.parse(savedFilters);
      const schemaKeys = Object.keys(analyticSchema.shape);
      const filtersKeys = new Set(parsedFilters.map((filter) => filter.key));
      if (
        schemaKeys.every(
          (key, index) => index === 0 || index === 1 || filtersKeys.has(key)
        )
      ) {
        setFilters(parsedFilters);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('filters', JSON.stringify(filters));
  }, [filters]);

  const [detailsContentOpened, setDetailsContentOpened] =
    useState<boolean>(false);

  const handleFilterClick = (title: string): void => {
    setFilters((prev) => {
      const filterIndex = prev.findIndex((filter) => filter.title == title);
      return prev.map((filter) => {
        if (filter.title === title) {
          if (
            prev.filter((filter) => filter.active).length === 1 &&
            filter.active
          ) {
            return { ...filter };
          }
          return {
            ...filter,
            active: !filter.active
          };
        }
        if (filterIndex >= 1 && filterIndex < 3 && filter.id >= 3) {
          return { ...filter, active: false };
        }
        if (filterIndex >= 3 && filter.id >= 1 && filter.id < 3) {
          return { ...filter, active: false };
        }
        return filter;
      });
    });
  };

  const handleDetailsTitleClick = () => {
    setDetailsContentOpened(!detailsContentOpened);
  };

  // const renderDataSums = () => {
  //   return Object.keys(MOCK_DATA[0]).map((key) => {
  //     if (key === 'date' || key === 'isDistribution') {
  //       return;
  //     }
  //     if (key === 'income') {
  //       return (
  //         <div key={key} className={cn(styles.tableData, styles.tableBottom)}>
  //           {calculateSum(key as keyof MockData)} {key === 'income' && '₽'}
  //         </div>
  //       );
  //     } else {
  //       return (
  //         filters.find((filter) => filter.key === key)?.active && (
  //           <div key={key} className={cn(styles.tableData, styles.tableBottom)}>
  //             {calculateSum(key as keyof MockData)}
  //           </div>
  //         )
  //       );
  //     }
  //   });
  // };

  const countActiveFilters = filters.filter((filter) => filter.active).length;

  const scrollRef = useRef<HTMLDivElement>(null);
  const offsetRef = useRef<HTMLDivElement[]>([]);

  const [emblaRef, emblaApi] = useEmblaCarousel(UPPER_FILTER_OPTIONS);
  const [emblaRef2, emblaApi2] = useEmblaCarousel(OPTIONS2);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on('scroll', (emblaApi) => {
        const {
          limit,
          target,
          location,
          offsetLocation,
          scrollTo,
          translate,
          scrollBody
        } = emblaApi.internalEngine();

        let edge: number | null = null;

        if (limit.reachedMax(location.get())) edge = limit.max;
        if (limit.reachedMin(location.get())) edge = limit.min;

        if (edge !== null) {
          offsetLocation.set(edge);
          location.set(edge);
          target.set(edge);
          translate.to(edge);
          translate.toggleActive(false);
          scrollBody.useDuration(0).useFriction(0);
          scrollTo.distance(0, false);
        } else {
          translate.toggleActive(true);
        }
      });
    }
  }, [emblaApi]);

  useEffect(() => {
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'style'
        ) {
          if (offsetRef.current && scrollRef.current) {
            offsetRef.current.map((elem) => {
              if (elem) {
                elem.style.transform = scrollRef.current?.style
                  .transform as string;
              }
            });
          }
        }
      }
    });

    if (scrollRef.current) {
      observer.observe(scrollRef.current, { attributes: true });
    }

    return () => {
      observer.disconnect();
    };
  }, [scrollRef, scrollRef.current]);

  useEffect(() => {
    if (emblaApi) {
      countActiveFilters < 2
        ? emblaApi.reInit({
            active: false
          })
        : emblaApi.reInit({
            active: true
          });
    }
  }, [emblaApi, countActiveFilters]);

  const analyticData = useAppSelector((s) => s.analytic.analyticData);
  const loading = useAppSelector((s) => s.analytic.loadingAnalytic);
  const dispatch = useAppDispatch();
  const logger = resolveLoggerInstance('useLoadAnalytics');
  const YM = useYM();

  useEffect(() => {
    if (analyticData.length !== 0) return;
    dispatch(fetchAnalyticData())
      .unwrap()
      .catch((e: AxiosError) => {
        logger.error(e);
        const xRequestId = e.response?.headers['x-request-id'];
        const responseDataError: ErrorDataResponse = e.response
          ?.data as ErrorDataResponse;
        if (e.response?.status === 500) {
          TG.WebApp?.showAlert(
            'Ой, что-то пошло не так\n\n' + t('error_id') + ': ' + xRequestId,
            () => {
              TG.WebApp?.close();
            }
          );
        } else {
          TG.WebApp?.showAlert(responseDataError.message, () => {
            TG.WebApp?.close();
          });
        }
        YM.errorLog(e.config?.url, userId, e.message, xRequestId);
        Sentry.captureException(e);
      });
  }, []);

  if (loading) return <Loading />;
  if (!analyticData.length)
    return (
      <Container
        style={{
          padding: '16px 0'
        }}>
        Нет данных
      </Container>
    );

  const transformedData = transformData([...analyticData].reverse());
  const tableHeight = 40 * analyticData.length;

  return (
    <div className={styles.container}>
      <PageHeader title={'Аналитика'} />
      <Container style={{ overflowX: 'hidden' }}>
        <div className={styles.upper}>
          <AnalyticsFilterCarousel
            carouselOptions={UPPER_FILTER_OPTIONS}
            carouselTitle={'Активность'}
            filters={filters.slice(3)}
            setFilterActive={handleFilterClick}
          />
          <AnalyticsFilterCarousel
            carouselOptions={LOWER_FILTER_OPTIONS}
            carouselTitle={'Аудитория'}
            filters={filters.slice(1, 3)}
            setFilterActive={handleFilterClick}
          />
          <div className={styles.graph}>
            {countActiveFilters > 0 ? (
              <LineGraph data={analyticData} filters={filters} />
            ) : (
              'Выберите фильтры для отображения кривых на графике'
            )}
          </div>
          <div className={styles.details}>
            <div
              className={styles.detailsTitle}
              onClick={handleDetailsTitleClick}>
              Подробнее
              <IoIosArrowDown
                className={cn(styles.arrow, {
                  [styles.rotatedArrow]: detailsContentOpened
                })}
              />
            </div>
            {detailsContentOpened && (
              <div className={styles.detailsContent}>
                {DETAILS.map((detail) => (
                  <div key={detail.title} className={styles.detail}>
                    <div className={styles.detailContent}>
                      <detail.icon className={styles.detailIcon} />
                      <div className={styles.detailName}>{detail.title}</div>
                    </div>
                    <div className={styles.detailDescription}>
                      {detail.description}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </Container>
      <div
        className={styles.tableH}
        ref={emblaRef2}
        style={{
          height: tableHeight + 'px'
        }}>
        <div className={cn(styles.tableRow)}>
          {TABLE_HEADERS.map((header, index) => {
            if (index === 0) {
              return (
                <div
                  className={cn(styles.tableData, styles.tableHeader)}
                  key={header}>
                  {header}
                </div>
              );
            } else {
              return (
                filters.find((filter) => filter.id === index)?.active && (
                  <div
                    className={cn(styles.tableData, styles.tableHeader)}
                    key={header}
                    ref={(el) =>
                      (offsetRef.current[index] = el as HTMLDivElement)
                    }>
                    {header}
                  </div>
                )
              );
            }
          })}
        </div>
      </div>
      <div className={cn('container', styles.table)}>
        {transformedData.map((col, index) => {
          if (index === 0) {
            return (
              <div className={styles.tableCol1} key={uuidv4()}>
                {col.map((cell) => (
                  <div className={styles.tableData} key={uuidv4()}>
                    {(cell as { date: string; isDistribution: boolean }).date}
                    {(cell as { date: string; isDistribution: boolean })
                      .isDistribution && (
                      <>
                        <a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="В этот день была рассылка">
                          <TgPlane className={styles.tableIcon} />
                        </a>
                        <Tooltip id="my-tooltip" place={'bottom'} />
                      </>
                    )}
                  </div>
                ))}
                <div className={cn(styles.tableData, styles.fontBold)}>
                  Всего:
                </div>
              </div>
            );
          }
        })}
        <div className={styles.wrapper} ref={emblaRef}>
          <div className={styles.tableContainer} ref={scrollRef}>
            {transformedData.map((col, index) => {
              if (index !== 0) {
                return (
                  filters[index].active && (
                    <div className={styles.tableCol} key={uuidv4()}>
                      {col.map((cell) => (
                        <div className={cn(styles.tableData)} key={uuidv4()}>
                          {cell as number}
                        </div>
                      ))}
                      <div className={cn(styles.tableData, styles.fontBold)}>
                        {calculateSum(
                          filters[index].key as keyof AnalyticDataItem,
                          analyticData
                        )}
                      </div>
                    </div>
                  )
                );

                // } else if (index === transformedData.length - 1) {
                //   return (
                //     <div className={styles.tableCol} key={uuidv4()}>
                //       {col.map((cell) => (
                //         <div className={cn(styles.tableData)} key={uuidv4()}>
                //           {cell as number} ₽
                //         </div>
                //       ))}
                //       <div className={styles.tableData}>
                //         {calculateSum('income', analyticData)} ₽
                //       </div>
                //     </div>
                //   );
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
