import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { AnalyticData } from '../../api/models/analytic';
import { getAnalyticData } from '../../api/analytic';

export const fetchAnalyticData = createAsyncThunk<
  AnalyticData,
  undefined,
  { rejectValue: AxiosError }
>('analytic/fetchAnalyticData', async (_, { rejectWithValue }) => {
  try {
    return await getAnalyticData();
  } catch (e) {
    return rejectWithValue(e as AxiosError);
  }
});
