import React from 'react';
import CSS from 'csstype';

interface Props {
  color?: string;
  className?: string;
  style?: CSS.Properties;
}

const GiftIcon = ({ color = '#000000', className = '', style = {} }: Props) => {
  const colorCss = { color: color };
  const css = { ...style, ...colorCss };

  return (
    <>
      <svg
        className={className}
        style={css}
        width="17"
        height="19"
        viewBox="0 0 17 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.116699 8.66211C0.116699 9.55029 0.614746 9.89893 1.51123 9.89893H7.40479V5.4082H6.10986C4.80664 5.4082 4.04297 4.58643 4.04297 3.67334C4.04297 2.76025 4.70703 2.229 5.62012 2.229C6.60791 2.229 7.40479 2.97607 7.40479 4.20459V5.4082H8.76611V9.89893H14.668C15.5645 9.89893 16.0625 9.55029 16.0625 8.66211V6.64502C16.0625 5.75684 15.5645 5.4082 14.668 5.4082H12.7422C13.2236 4.95166 13.5142 4.3291 13.5142 3.59863C13.5142 1.98828 12.2441 0.842773 10.6338 0.842773C9.47168 0.842773 8.50879 1.48193 8.08545 2.59424C7.66211 1.48193 6.70752 0.842773 5.53711 0.842773C3.93506 0.842773 2.65674 1.98828 2.65674 3.59863C2.65674 4.3291 2.94727 4.95166 3.43701 5.4082H1.51123C0.65625 5.4082 0.116699 5.75684 0.116699 6.64502V8.66211ZM10.5508 2.229C11.4639 2.229 12.1279 2.76025 12.1279 3.67334C12.1279 4.58643 11.3726 5.4082 10.061 5.4082H8.76611V4.20459C8.76611 2.97607 9.56299 2.229 10.5508 2.229ZM1.30371 16.1328C1.30371 17.519 2.11719 18.2412 3.50342 18.2412H7.40479V10.7871H1.30371V16.1328ZM8.76611 18.2412H12.6758C14.0537 18.2412 14.8755 17.519 14.8755 16.1328V10.7871H8.76611V18.2412Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default GiftIcon;
