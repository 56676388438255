import PageHeader from '../../../components/page-header/page-header';
import { Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { TG } from '../../../helpers/telegram';
import Loading from '../../loading/loading';
import { useAppDispatch } from '../../../hooks/redux';
import { useSendContestResultsInit } from '../../../hooks/useSendContestResultsInit';
import ContestReferralStatBlock from '../../../components/contest-referral-stat-block/contest-referral-stat-block';
import { apiKey } from '../../../helpers/api';
import { useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BiLogoTelegram } from 'react-icons/bi';
import UserBanLogo from '../../../assets/user-ban.svg?react';
import UserUnbanLogo from '../../../assets/user-unban.svg?react';
import { banUnbanContestUserRequest } from '../../../store/thunk/ContestThunk';
import { changeUserBanStatus } from '../../../store/slices/contestsSlice';
import { useGetAntiFraudStat } from '../../../hooks/useGetAntiFraudStat';
import { useLoadContestParticipantInfo } from '../../../hooks/useLoadContestParticipantInfo';
import BarGraph from '../../../components/bar-graph/bar-graph';

import './referral-stat.scss';

const ContestReferralStat = () => {
  useSendContestResultsInit();
  useEffect(() => {
    TG.BackButton?.show();
    TG.MainButton?.setText(t('send_referred_subs'));
  }, []);
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { referral, loading, navigate, chat_id } =
    useLoadContestParticipantInfo();
  const { antiFraudInfo, subsCount } = useGetAntiFraudStat(chat_id);

  const [localIsBanned, setLocalIsBanned] = useState<boolean>(
    referral?.is_banned || false
  );

  useEffect(() => {
    if (referral) {
      setLocalIsBanned(referral.is_banned);
    }
  }, [referral?.is_banned]);

  const sendMessageToUserNavigate = (username?: string | null) => {
    if (!username) {
      return navigate(`${location.pathname}/send-message?key=${apiKey}`);
    }

    return TG.WebApp?.openTelegramLink(`https://t.me/${username}`);
  };

  if (loading) return <Loading />;

  if (!referral)
    return <Container>Информация о данном подписчике отсутствует</Container>;

  const banUnbanReferral = async () => {
    try {
      const { is_banned_on_contests } = await dispatch(
        banUnbanContestUserRequest({
          user_chat_id: Number(chat_id),
          contest_id: Number(id)
        })
      ).unwrap();
      setLocalIsBanned(is_banned_on_contests);
      if (is_banned_on_contests) {
        TG.WebApp?.showAlert('Пользователь забанен');
        dispatch(
          changeUserBanStatus({
            participant_chat_id: chat_id as string,
            setBanned: true
          })
        );
      } else {
        TG.WebApp?.showAlert('Пользователь разбанен');
        dispatch(
          changeUserBanStatus({
            participant_chat_id: chat_id as string,
            setBanned: false
          })
        );
      }
    } catch (e) {
      TG.WebApp?.showAlert('Произошла ошибка');
    }
  };

  const banButton = localIsBanned ? (
    <div
      className="contest-referral-stat-header-button-unban"
      onClick={banUnbanReferral}>
      <UserUnbanLogo />
    </div>
  ) : (
    <div
      className="contest-referral-stat-header-button-ban"
      onClick={banUnbanReferral}>
      <UserBanLogo />
    </div>
  );

  return (
    <div className="contest-referral-stat">
      <Container>
        <div className="contest-referral-stat-header">
          <div className="contest-referral-stat-header-info">
            <div className="referral-fullname">{referral.full_name ?? '*'}</div>
            <div className="referral-tginfo">
              {referral.username && (
                <div className="referral-tginfo-username">
                  @{referral.username}
                </div>
              )}
              <div className="referral-tginfo-id">
                {!referral.username && 'TG ID: '}
                {referral.chat_id}
              </div>
            </div>
            {localIsBanned && <div className="referral-isbanned">Забанен</div>}
          </div>
          <div className="contest-referral-stat-header-button-wrapper">
            <div
              className="contest-referral-stat-header-button-contact"
              onClick={() => sendMessageToUserNavigate(referral.username)}>
              <BiLogoTelegram />
            </div>
            {banButton}
          </div>
        </div>
      </Container>
      <Container>
        <div className="contest-referral-stat-graph">
          <BarGraph
            data={referral.contest_stat.subscribe_points}
            startDate={referral.contest_started_at}
            endDate={referral.contest_finished_at}
          />
        </div>
      </Container>
      <PageHeader title="Статистика" />
      <Container>
        <div className="contest-referral-stat-blocks">
          {antiFraudInfo.map((i, index) => (
            <ContestReferralStatBlock
              key={index}
              allUserReferrals={subsCount}
              {...i}
            />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default ContestReferralStat;
