import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  banUnbanContestUser,
  createContest,
  finishContest,
  getContestInfo,
  getContestList,
  getContestParticipantInfo,
  sendContestMessageToUser,
  sendContestResults,
  updateContest
} from '../../api/contests';
import {
  ContestList,
  ContestInfo,
  ContestSendMessageDataSchema,
  CreateContestDataSchema,
  BanUnbanContestUserDataSchema,
  FinishContestDataSchema,
  ContestParticipantInfo,
  UpdateContestDataSchema
} from '../../api/models/contests';
import { setIsContestEndedManually } from '../slices/contestsSlice';
import { updateContestsExistence } from '../slices/projectsSlice';

export const fetchContestList = createAsyncThunk<
  ContestList,
  { chat_id?: number },
  {
    rejectValue: Error;
  }
>('contests/fetchContestList', async ({ chat_id }, { rejectWithValue }) => {
  try {
    return await getContestList({ chat_id });
  } catch (e) {
    return rejectWithValue(e as Error);
  }
});

export const fetchContestInfo = createAsyncThunk<
  ContestInfo,
  {
    contest_id?: string;
    chat_id?: number;
  },
  { rejectValue: Error }
>(
  'contests/fetchContestInfo',
  async ({ contest_id, chat_id }, { rejectWithValue }) => {
    try {
      return await getContestInfo(contest_id, chat_id);
    } catch (e) {
      return rejectWithValue(e as Error);
    }
  }
);

export const fetchContestParticipantInfo = createAsyncThunk<
  ContestParticipantInfo & { contest_id: number },
  {
    contest_id: number;
    participant_chat_id?: number;
  },
  { rejectValue: Error }
>(
  'contests/fetchContestParticipantInfo',
  async ({ contest_id, participant_chat_id }, { rejectWithValue }) => {
    try {
      const response = await getContestParticipantInfo({
        contest_id,
        participant_chat_id
      });

      return { ...response, contest_id };
    } catch (e) {
      return rejectWithValue(e as Error);
    }
  }
);

export const sendMessage = createAsyncThunk<
  ContestSendMessageDataSchema,
  { text: string; chat_id?: number; to_chat_id?: number },
  { rejectValue: Error }
>(
  'contests/sendMessage',
  async ({ text, chat_id, to_chat_id }, { rejectWithValue }) => {
    try {
      return await sendContestMessageToUser({ text, chat_id, to_chat_id });
    } catch (e) {
      return rejectWithValue(e as Error);
    }
  }
);

export const sendResults = createAsyncThunk<
  ContestSendMessageDataSchema,
  { chat_id?: number; contest_id?: number; subscriber_chat_id?: number },
  { rejectValue: Error }
>(
  'contests/sendResults',
  async ({ chat_id, subscriber_chat_id, contest_id }, { rejectWithValue }) => {
    try {
      return await sendContestResults({
        chat_id,
        subscriber_chat_id,
        contest_id
      });
    } catch (e) {
      return rejectWithValue(e as Error);
    }
  }
);

export const createContestRequest = createAsyncThunk<
  CreateContestDataSchema,
  { chat_id?: number; conditions: string; expiration_date: string },
  { rejectValue: Error }
>(
  'contests/createContestRequest',
  async ({ chat_id, conditions, expiration_date }, { rejectWithValue }) => {
    try {
      return await createContest({ chat_id, conditions, expiration_date });
    } catch (e) {
      return rejectWithValue(e as Error);
    }
  }
);

export const updateContestRequest = createAsyncThunk<
  UpdateContestDataSchema,
  {
    chat_id?: number;
    conditions?: string;
    expiration_date?: string;
    contest_id?: number;
  },
  { rejectValue: Error }
>(
  'contests/updateContestRequest',
  async (
    { chat_id, conditions, expiration_date, contest_id },
    { rejectWithValue }
  ) => {
    try {
      return await updateContest({
        chat_id,
        conditions,
        expiration_date,
        contest_id
      });
    } catch (e) {
      return rejectWithValue(e as Error);
    }
  }
);

export const banUnbanContestUserRequest = createAsyncThunk<
  BanUnbanContestUserDataSchema,
  { contest_id: number; user_chat_id?: number },
  { rejectValue: Error }
>(
  'contests/banUnbanContestUserRequest',
  async ({ user_chat_id, contest_id }, { rejectWithValue }) => {
    try {
      return await banUnbanContestUser({ user_chat_id, contest_id });
    } catch (e) {
      return rejectWithValue(e as Error);
    }
  }
);

export const finishContestRequest = createAsyncThunk<
  FinishContestDataSchema & { participant_count: number },
  {
    chat_id?: number;
    contest_id?: number;
    participant_count: number;
  },
  { rejectValue: Error }
>(
  'contests/finishContestRequest',
  async (
    { chat_id, contest_id, participant_count },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await finishContest({ chat_id, contest_id });
      dispatch(setIsContestEndedManually(true));
      dispatch(updateContestsExistence(false));

      return { ...response, participant_count };
    } catch (e) {
      return rejectWithValue(e as Error);
    }
  }
);
