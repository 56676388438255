import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Loading from './pages/loading/loading';
import { PATHS } from './const';
import ErrorPage from './pages/error/error';
import Main from './pages/main/main';
import NotFound from './pages/not-found/not-found';
import { TG } from './helpers/telegram';
import PromoDetail from './pages/promo-detail/promo-detail';
import CategoriesList from './components/categories-list/categories-list';
import ProjectsList from './components/projects-list/projects-list';
import { useAppSelector } from './hooks/redux';
import ProjectAbout from './pages/project/about/about';
import Project from './pages/project/project';
import ProjectOffers from './pages/project/offers/offers';
import ProjectBonuses from './pages/project/bonuses/bonuses';
import BackButton from './components/telegram/BackButton';
import MainButton from './components/telegram/MainButton';
import { calculateWindowWidth } from './helpers/common';
import { useTranslation } from 'react-i18next';
import { useLoadProjects } from './hooks/useLoadProjects';
import { colorSchemes } from './@types/enums';
import { apiKey } from './helpers/api';
import ContestsList from './components/contests-list/contests-list';
import Contest from './pages/contest/contest';
import ContestPreview from './pages/contest/preview/contest-preview';
import ContestConditions from './pages/contest/conditions/contest-conditions';
import ContestSendMessage from './pages/contest/send-message/contest-send-message';
import ContestMyReferrals from './pages/contest/my-referrals/contest-my-referrals';
import ContestReferralStat from './pages/contest/referral-stat/referral-stat';
import ContestLaunch from './pages/contest/contest-launch/contest-launch';
import ContestChangeEndDate from './pages/contest/change-end-date/contest-change-end-date';
import ContestLaunchSuccessfully from './pages/contest/contest-launched-successfully/contest-launch-successfully';
import ScrollToTop from './components/scroll-to-top/ScrollToTop';
import ContestStatPage from './pages/contest/contest-stat/contest-stat';
import Analytics from './pages/analytics/analytics';

const App = (): JSX.Element => {
  const { projects, loadingProjects, error, error_id } = useAppSelector(
    (state) => state.projects
  );
  const botIsActive = useAppSelector(
    (s) => s.projects.additionalData.botIsActive
  );
  const loadingPromo = useAppSelector((s) => s.promocode.loadingPromo);
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState(calculateWindowWidth());
  const { pathname } = useLocation();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(calculateWindowWidth());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  const loaded = useLoadProjects();

  const appClass = `app scheme-${TG.WebApp?.colorScheme ?? colorSchemes.light}`;
  const appStyle = { width: windowWidth };

  const getAppContent = () => {
    if (!apiKey) {
      return <ErrorPage alert={t('telegram_error')} />;
    }

    if (loadingPromo || loadingProjects) {
      return <Loading />;
    }

    if (error) {
      const errorIdAddition = error_id
        ? `\n\n${t('error_id')}: ${error_id}`
        : '';

      return (
        <ErrorPage alert={`${error}${errorIdAddition}`} errorMessage={error} />
      );
    }

    if (!botIsActive) {
      return <ErrorPage alert={t('bot_is_not_active')} />;
    }

    if (!projects.items.length && loaded && pathname !== PATHS.analytics) {
      return <ErrorPage alert={t('projects_empty')} />;
    }

    return (
      loaded && (
        <>
          <ScrollToTop>
            <Routes>
              <Route path={PATHS.main} element={<Main />}>
                <Route path={PATHS.projects} element={<ProjectsList />} />
                <Route path={PATHS.categories} element={<CategoriesList />} />
                <Route path={PATHS.contests} element={<ContestsList />} />
                <Route path={PATHS.contest} element={<Contest />} />
                <Route path={PATHS.notFound} element={<NotFound />} />
              </Route>

              <Route path={PATHS.analytics} element={<Analytics />} />

              <Route path={PATHS.project} element={<Project />}>
                <Route path={PATHS.projectOffers} element={<ProjectOffers />} />
                <Route path={PATHS.projectAbout} element={<ProjectAbout />} />
                <Route
                  path={PATHS.projectBonuses}
                  element={<ProjectBonuses />}
                />
                <Route path={PATHS.notFound} element={<NotFound />} />
              </Route>

              <Route path={PATHS.contestPreview} element={<ContestPreview />} />
              <Route
                path={PATHS.contestConditions}
                element={<ContestConditions />}
              />
              <Route
                path={PATHS.contestSendMessage}
                element={<ContestSendMessage />}
              />
              <Route
                path={PATHS.contestMyReferrals}
                element={<ContestMyReferrals />}
              />
              <Route
                path={PATHS.contestReferralStat}
                element={<ContestReferralStat />}
              />
              <Route path={PATHS.contestLaunch} element={<ContestLaunch />} />
              <Route
                path={PATHS.contestChangeEndDate}
                element={<ContestChangeEndDate />}
              />
              <Route
                path={PATHS.contestLaunchSuccessfully}
                element={<ContestLaunchSuccessfully />}
              />
              <Route
                path={PATHS.contestStatPage}
                element={<ContestStatPage />}
              />

              <Route path={PATHS.promoDetail} element={<PromoDetail />} />
              <Route path={PATHS.error} element={<ErrorPage />} />
              <Route path={PATHS.notFound} element={<NotFound />} />
            </Routes>
          </ScrollToTop>
        </>
      )
    );
  };

  return (
    <div className={appClass} style={appStyle}>
      <BackButton />
      <MainButton />

      {getAppContent()}
    </div>
  );
};

export default App;
