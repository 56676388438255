import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { reqPromo, reqSubPromo, sendPromos } from '../thunk/PromocodeThunk';

interface PromocodeState {
  isRequestingPromo: boolean;
  loadingPromo: boolean;
  error?: string;
}

const initialState: PromocodeState = {
  isRequestingPromo: false,
  loadingPromo: false,
  error: ''
};

export const promocodeSlice = createSlice({
  name: 'promocode',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loadingPromo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(reqPromo.pending, (state) => {
      state.isRequestingPromo = true;
    });
    builder.addCase(reqPromo.fulfilled, (state) => {
      state.isRequestingPromo = false;
    });
    builder.addCase(reqPromo.rejected, (state, action) => {
      state.isRequestingPromo = false;
      state.error = action.payload?.message;
    });
    builder.addCase(reqSubPromo.pending, (state) => {
      state.isRequestingPromo = true;
    });
    builder.addCase(reqSubPromo.fulfilled, (state) => {
      state.isRequestingPromo = false;
    });
    builder.addCase(reqSubPromo.rejected, (state, action) => {
      state.isRequestingPromo = false;
      state.error = action.payload?.message;
    });
    builder.addCase(sendPromos.pending, (state) => {
      state.loadingPromo = true;
    });
    builder.addCase(sendPromos.fulfilled, (state) => {
      state.loadingPromo = false;
    });
    builder.addCase(sendPromos.rejected, (state, action) => {
      state.loadingPromo = false;
      state.error = action.payload?.message;
    });
  }
});

export const { setLoading } = promocodeSlice.actions;

export default promocodeSlice.reducer;
