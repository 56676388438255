import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { apiKey, userId } from '../../helpers/api';
import { ContestListItem } from '../../api/models/contests';
import { localizeDate } from '../../helpers/date';
import cn from 'classnames';
import { sendBotLogs } from '../../api/log';
import styles from './contests-list-item.module.scss';
import { SlArrowRight } from 'react-icons/sl';

type ContestsListItemProps = {
  contest: ContestListItem;
  className?: string;
  isActive?: boolean;
};

const ContestsListItem = ({
  contest,
  className,
  isActive
}: ContestsListItemProps) => {
  const { id, started_at, finished_at } = contest;

  const [localizedStartDate] = localizeDate(started_at);
  const [localizedEndDate] = localizeDate(finished_at);

  const sendContestViewLogs = async () => {
    await sendBotLogs({
      chat_id: userId,
      contest_id: id,
      event_type: 'view_contest'
    });
  };

  return (
    <li key={id} className={cn('contests-list__item', className)}>
      <Container>
        <Link
          to={`/contest/${id}?key=${apiKey}`}
          className="contests-list__button"
          onClick={sendContestViewLogs}>
          <div className={styles.dates}>
            {localizedStartDate} - {localizedEndDate}
          </div>
          <div className={cn(styles.activeInfo)}>
            <div
              className={cn(styles.activeText, { [styles.active]: isActive })}>
              {isActive ? 'Активный' : 'Завершен'}
            </div>
            <SlArrowRight className={styles.arrow} />
          </div>
        </Link>
      </Container>
      <div className="line"></div>
    </li>
  );
};

export default ContestsListItem;
