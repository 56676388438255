import React from 'react';
import ReactDOM from 'react-dom/client';
import './i18n';
import App from './app';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { setupStore } from './store';
import ThemeSelector from './components/theme/theme-selector/theme-selector';
import * as Sentry from '@sentry/react';
import './scss/index.scss';
import { config } from './config';
import { isProd } from './helpers/common';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const store = setupStore();

if (config.sentryKey && isProd()) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_KEY,
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()]
  });
}

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeSelector>
        <App />
      </ThemeSelector>
    </BrowserRouter>
  </Provider>
);
