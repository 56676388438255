import {
  Dispatch,
  Fragment,
  RefObject,
  SetStateAction,
  useEffect
} from 'react';
import styles from './contest-condition-info.module.scss';
import cn from 'classnames';
import { useAppSelector } from '../../hooks/redux';
import { useParams } from 'react-router-dom';

interface ContestConditionInfoProps {
  textAreaValue: string;
  setTextAreaValue: Dispatch<SetStateAction<string>>;
  error?: string;
  textAreaRef?: RefObject<HTMLTextAreaElement>;
}

const ContestConditionInfo = ({
  textAreaValue,
  setTextAreaValue,
  error,
  textAreaRef
}: ContestConditionInfoProps) => {
  const { id } = useParams();
  const contestInfo = useAppSelector((s) =>
    s.contests.contestsInfo.find((c) => c.id === Number(id))
  );

  const textAreaPlaceholder =
    'Например, участники из Топ-3 рейтинга получат от меня по коробке манго, ещё 3 приза по 2000 рублей я разыграю рандомом среди мест с 4 по 25.';

  const onTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(e.target.value);
  };

  useEffect(() => {
    if (contestInfo?.conditions) {
      setTextAreaValue(contestInfo.conditions);
    }
  }, []);

  return (
    <Fragment>
      <div className={styles.contestInfoWrapper}>
        <div className={styles.title}>Механика награждения</div>
        <textarea
          ref={textAreaRef}
          className={cn(styles.textarea, { [styles.errorTextArea]: error })}
          placeholder={textAreaPlaceholder}
          value={textAreaValue}
          onChange={onTextAreaChange}
        />
        {error && <div className={styles.error}>{error}</div>}
      </div>
      <div className={styles.contestInfoWrapper}>
        <div className={styles.title}>Что нужно сделать</div>
        <div className={styles.subtitle}>
          Отправь пригласительную ссылку своим друзьям и знакомым, которые ранее
          не пользовались моим ботом. Расскажи им, какие скидки они могут там
          найти.
        </div>
      </div>
      <div className={styles.contestInfoWrapper}>
        <div className={styles.title}>Запрещено</div>
        <div className={styles.subtitle}>
          Запрещены любые способы искусственного увеличения числа подписчиков.
          При выявлении случаев, когда люди подписываются на бота не с целью
          получения скидок или экономии, это будет считаться мотивированным
          трафиком и признаётся нарушением условий конкурса.
        </div>
      </div>
    </Fragment>
  );
};

export default ContestConditionInfo;
