import React from 'react';
import CopyButton from '../../buttons/copy-button';
import './promocode-item.scss';
import GiftIcon from '../../icons/gift';
import { TG } from '../../../helpers/telegram';
import { useYM } from '../../../hooks/useYM';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';
import { GrabEvents } from '../../../@types/enums';
import HitBadge from '../../badges/hit/hit';
import { useTranslation } from 'react-i18next';
import { Promocode } from '../../../models/promocode';
import { apiKey, userId } from '../../../helpers/api';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { sendBotLogs } from '../../../api/log';
import { EventTypes } from '../../../api/models/log';

interface Props {
  projectName: string;
  promo: Promocode;
  link: string;
}

const PromocodeItem = ({ projectName, promo, link }: Props) => {
  const {
    id,
    group,
    project_id,
    landing_id,
    is_barcode,
    show_barcode_text,
    image,
    code,
    comment,
    is_universal
  } = promo;
  const { t } = useTranslation();
  const YM = useYM();

  const promoLink = `/promo/${id}?key=${apiKey}`;

  const viewPromoClickHandler = () => {
    window.scroll(0, 0);
    TG.MainButton?.hide();
  };

  const sendLogsOnCopy = async (
    ymEventType: GrabEvents,
    botLogEventType: EventTypes
  ) => {
    await sendBotLogs({
      chat_id: userId,
      project_id,
      landing_id,
      promo_group_id: group,
      event_type: botLogEventType
    });

    YM.grabOffer({
      projectId: project_id,
      projectName: projectName,
      landingId: landing_id,
      promoGroup: group,
      eventType: ymEventType
    });
  };

  return (
    <>
      <div className="promo">
        <div className="promo__description text-md">
          {promo.is_hit ? (
            <HitBadge className="me-2" />
          ) : (
            <GiftIcon
              color="var(--tg-theme-link-color)"
              style={{ marginBottom: '4px', marginRight: '10px' }}
            />
          )}
          {comment}
        </div>
        <div className="control">
          <div className="control__content">
            {is_barcode ? (
              <>
                <Link
                  to={promoLink}
                  className="control__image"
                  onClick={viewPromoClickHandler}>
                  <img src={image} alt={code} />
                </Link>
                <span className="control__text--right">
                  {t('before')}&nbsp;{promo.date}
                </span>
              </>
            ) : (
              <>
                <span>
                  {t('promo')}: <span className="control__text">{code}</span>
                </span>
                <span className="control__text--right">
                  {t('before')}&nbsp;{promo.date}
                </span>
              </>
            )}
          </div>
          {is_barcode ? (
            <Link
              to={promoLink}
              className="control__button"
              onClick={viewPromoClickHandler}>
              <FontAwesomeIcon
                icon={faMagnifyingGlassPlus}
                size="xl"
                style={{ color: 'var(--tg-theme-link-color)' }}
              />
            </Link>
          ) : (
            !is_universal && (
              <CopyButton
                text={code}
                className="control__button"
                callback={() => sendLogsOnCopy(GrabEvents.copy, 'copy')}
              />
            )
          )}
        </div>
        {is_barcode && show_barcode_text ? (
          <div className="control">
            <div className="control__content">
              <>
                <span>
                  {t('promo')}: <span className="control__text">{code}</span>
                </span>
                <span className="control__text--right">
                  {t('before')}&nbsp;{promo.date}
                </span>
              </>
            </div>
            <CopyButton
              text={code}
              className="control__button"
              callback={() => sendLogsOnCopy(GrabEvents.copy, 'copy')}
            />
          </div>
        ) : (
          <></>
        )}

        <CopyToClipboard
          text={code}
          onCopy={async () => {
            await sendLogsOnCopy(GrabEvents.copy_redirect, 'copy_and_redirect');
            setTimeout(() => TG.WebApp?.openLink(link));
          }}>
          <button className="btn-main">
            {is_barcode && !show_barcode_text
              ? t('redirect')
              : t('copy_and_redirect')}
          </button>
        </CopyToClipboard>
      </div>
    </>
  );
};

export default PromocodeItem;
