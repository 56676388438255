import styles from './user-banned.module.scss';
import { Container } from 'react-bootstrap';
import { TG } from '../../helpers/telegram';
import { useAppSelector } from '../../hooks/redux';

const UserBanned = () => {
  const adminUserName = useAppSelector(
    (s) => s.contests.contestsInfo[0]?.admin?.username
  );
  const contactAdminHandler = () => {
    if (!adminUserName) {
      TG.WebApp?.showAlert('Бот не активирован. Свяжитесь с блогером');
      return;
    }
    return TG.WebApp?.openTelegramLink(`https://t.me/${adminUserName}`);
  };

  return (
    <Container className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.title}>
          Участие
          <br />
          приостановлено
        </div>
        <div className={styles.subtitle}>
          Ваше участие в конкурсе приостановлено в связи с нарушением условий.
          Если у вас есть вопросы, пожалуйста, свяжитесь с организатором
        </div>
      </div>
      <div className={styles.button} onClick={contactAdminHandler}>
        Связаться
      </div>
    </Container>
  );
};

export default UserBanned;
