import { useParams } from 'react-router';
import { useAppSelector } from './redux';
import { useEffect } from 'react';
import { TG } from '../helpers/telegram';
import { getPromoById } from '../helpers/getPromoById';

export const useFindPromoAndProject = () => {
  const { id } = useParams<string>();
  const projects = useAppSelector((state) => state.projects.projects.items);

  const promo = getPromoById(projects, id);
  const project = promo ? projects.find((project) => project.id === promo.project_id) : undefined;

  useEffect(() => {
    if (promo) {
      TG.BackButton?.show();
    }
  }, []);

  return { promo, project };
};
