import { z } from 'zod';

export const landingSchema = z.object({
  id: z.number(),
  name: z.string(),
  link: z.string(),
  // logo: z.string(),
  project_id: z.union([z.string(), z.number()])
});

export type Landing = z.infer<typeof landingSchema>;
