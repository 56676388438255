import { api } from './api';
import { AnalyticData, analyticDataSchema } from './models/analytic';

const BASE_URL = '/blogger/promocode-api';

export const getAnalyticData = async () => {
  const response = await api.get<AnalyticData>(`${BASE_URL}/get-stats`);

  return analyticDataSchema.parse(response.data);
};
