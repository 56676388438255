import PageHeader from '../page-header/page-header';
import { Container } from 'react-bootstrap';
import {
  faUserFriends,
  faClock,
  faAngleRight
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './contest-stat.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { apiKey } from '../../helpers/api';

interface ContestStatProps {
  totalSubs?: number;
  startDate: string;
  endDate: string;
  remainingTime: number;
  progressBarWidth: number;
  timeNoun: string;
  isContestFinished?: boolean;
  isContestEndedManually?: boolean | null;
}

const ContestStat = ({
  totalSubs,
  startDate,
  endDate,
  remainingTime,
  timeNoun,
  progressBarWidth,
  isContestFinished,
  isContestEndedManually
}: ContestStatProps) => {
  const shouldRenderProgressBar = isContestFinished
    ? false
    : isContestEndedManually !== null
    ? !isContestEndedManually
    : true;

  const navigate = useNavigate();
  const { id } = useParams();

  const totalSubsIsNotZero = totalSubs !== undefined && totalSubs > 0;

  const navigateToContestStat = () => {
    if (totalSubsIsNotZero)
      navigate(`/contest/${id}/contest-stat?key=${apiKey}`);
  };

  return (
    <div className="contest-stat-wrapper">
      <PageHeader title="Статистика" />
      <Container>
        <div className="contest-stat">
          <div
            className="contest-stat__subs"
            onClick={navigateToContestStat}
            style={{
              cursor: totalSubsIsNotZero ? 'pointer' : 'default'
            }}>
            <div className="contest-stat__subs__title">
              <FontAwesomeIcon
                icon={faUserFriends}
                size="sm"
                className="contest-stat__subs__icon"
              />
              Подписчиков
            </div>
            {totalSubs !== undefined && (
              <div className="contest-stat__subs__count">
                {totalSubs}
                {totalSubs > 0 && (
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    size="sm"
                    className="contest-info__item__icon"
                  />
                )}
              </div>
            )}
          </div>
          {shouldRenderProgressBar && (
            <div className="contest-stat-remaining">
              <div className="contest-stat-remaining__graph">
                <div
                  style={{
                    width: `${progressBarWidth}%`,
                    maxWidth: '100%'
                  }}></div>
              </div>
              <div className="contest-stat-remaining__info">
                <div className="contest-stat-remaining__dates">
                  <FontAwesomeIcon
                    icon={faClock}
                    size="sm"
                    className="contest-stat-remaining__icon"
                  />
                  {startDate} - {endDate}
                </div>
                <div className="contest-stat-remaining__days">
                  осталось {remainingTime} {timeNoun}
                </div>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default ContestStat;
